import React from "react";
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import { Image } from "expo-image";
import { MaterialCommunityIcons } from "@expo/vector-icons";

//ヘッダー関係のコンポーネントを定義

export function ChangeGenre(props) {
  return (
    <TouchableOpacity
      onPress={() => props.navigation.navigate("ジャンル選択")}
      style={genre_styles.genrechange_area}
    >
      {/* <MaterialCommunityIcons
            name="sync"
            size={25}
            style={genre_styles.icon_box}
            /> */}
      <MaterialCommunityIcons
        name="sync"
        size={25}
        style={genre_styles.icon_box}
      />
      <Text style={genre_styles.genrechange}>ジャンル切替</Text>
    </TouchableOpacity>
  );
}

export function AppName() {
  return (
    <Image
      source={require("../assets/app_logos/mycalinks_logo_yoko.png")}
      style={app_name_styles.logo}
    />
  );
}

const genre_styles = StyleSheet.create({
  genrechange_area: {
    marginRight: 10,
  },
  icon_box: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  genrechange: {
    textAlign: "center",
    fontSize: 8,
  },
});

const app_name_styles = StyleSheet.create({
  logo: {
    width: 135,
    height: 35,
    resizeMode: "contain",
    paddingBottom: 0,
  },
});
