import { useState, useCallback, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  TextInput,
} from "react-native";
import { Image } from "expo-image";

import { useFocusEffect } from "@react-navigation/native";

import { MycaAPI } from "../lib/function";
import { BottomMenu, TopMenu } from "../components/Menu";

import { SortButton } from "../components/Sort";

export function PackListScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  //ここではパック一覧を表示する
  //リファクタVer
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [PageNo, setPageNo] = useState(1); //現在のページ数
  const [Props, setProps] = useState({
    genre: 0,
  }); //条件パラメータ
  const [UserData, setUserData] = useState(); //ユーザーデータ
  const [PackOwnedRatio, setPackOwnedRatio] = useState([]); //パックのコンプリート率

  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "order_num",
    mode: "DESC",
  });

  const FlatRef = useRef(null);

  //パラメータを確認する部分　ここでユーザーデータの取得も行う
  useEffect(() => {
    (async () => {
      const ThisGenre = CurrentGenre[0] || 1;

      setUserData({ ...API.user });

      setItems([]);

      setProps({
        genre: ThisGenre,
        is_pack: 1, //パックだから
      });

      setPageNo(1);
    })();
  }, [CurrentGenre[0]]);

  useEffect(() => {
    (async () => {
      setPageNo(0);
    })();
  }, [Props, OrderSetting]);

  //ユーザー情報が変更されたときとフォーカスされたときに所有データを更新してMyItemsに登録する　また、資産系についても計算しなおす　また、お気に入りも計算する
  useFocusEffect(
    useCallback(() => {
      if (UserData) {
        //パックのコンプリート率データを取得する
        API.getItem(
          {
            user: UserData.id,
            genre: CurrentGenre[0],
          },
          {
            pack_owned_ratio: 1,
          },
        ).then((data) => setPackOwnedRatio(data));
      }
    }, [UserData]),
  );

  //ユーザー情報が更新されたときやページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    (async () => {
      if (PageNo == 0) {
        setPageNo(1);
        return false;
      }

      if (Props.genre) {
        //Propsの中のnameは部分一致対応化
        const _Props = { ...Props };
        if (_Props.name) {
          _Props.name = `%${_Props.name}%`;
        } else {
          delete _Props.name;
        }

        const NewItems = await API.getItem(
          {
            ..._Props,
          },
          { detail: 1 },
          {
            cur: PageNo,
            per: 18,
          },
          {
            column: OrderSetting.column,
            mode: OrderSetting.mode,
          },
        );

        //現在のItemsにそのまま結合していいのか判断
        if (PageNo == 1 && Items.length) {
          setItems([...NewItems]);

          FlatRef.current?.scrollToIndex({
            index: 0,
            animated: true,
          });
        } else {
          //ページ番号がかわったらカードデータを再取得する

          setItems([...Items.concat(NewItems)]);
        }

        console.log(NewItems);
      }
    })();
  }, [PageNo]);

  //パックコンプリート率データとアイテムデータが更新されたらリンクさせる
  // useEffect(() => {
  //   setItems(
  //     Items.map((item) => {
  //       //パックコンプリート率データ
  //       const ThisPack = PackOwnedRatio.find((e) => e.pack_id == item.id);
  //       item = Object.assign(item, {
  //         owned_ratio: [
  //           parseInt(ThisPack ? ThisPack.pack_sum : 0),
  //           parseInt(ThisPack ? ThisPack.owned_sum : 0),
  //         ],
  //       });

  //       return item;
  //     }),
  //   );
  // }, [PackOwnedRatio, Items.length]);

  const PackOwnedRatioComponent = ({ id }) => {
    const thisPack = PackOwnedRatio.find((e) => e.pack_id == id);
    let ratio = [0, 0];

    if (thisPack) {
      ratio = [
        parseInt(thisPack ? thisPack.pack_sum : 0),
        parseInt(thisPack ? thisPack.owned_sum : 0),
      ];
    }

    return (
      <>
        {ratio[0] == ratio[1] && ratio[0] != 0 ? (
          <Text style={styles.complete}>Complete!</Text>
        ) : (
          <Text style={[styles.カード名, styles.pack_owned_ratio]}>
            {ratio[1]}/{ratio[0]}
          </Text>
        )}
      </>
    );
  };

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  const getCondition = (item) => {
    const condition = { pack: item.id };

    const middleMenu = CurrentMenu[0].middle; //現在のボトムメニューを取得する

    if (CurrentGenre[0] == 1) {
      condition.displaytype1 = middleMenu; //ポケモンの場合だけ、PSAの場合を考慮してdisplaytype1をセットする
    } else {
      condition.displaytype1 = `%カード%`;
    }

    return condition;
  };

  const renderItem = useCallback(({ item, index }) => (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate({
          name: "アイテム一覧", //アイテムリストスクリーン
          params: {
            condition: getCondition(item),
          }, //PSAの場合を含めないといけないため
        })
      }
      style={styles.imageStack}
    >
      <Image
        source={{ uri: API.getImageUrl(item.genre_id, "pack", item.packimage) }}
        resizeMode="contain"
        style={styles.image}
      ></Image>
      <Text style={styles.パック名}>{item.packname}</Text>
      <Text style={styles.カード名}> {item.packexpansion}</Text>
      <Text style={styles.カード名}>
        発売日：{MycaAPI.DateFormat(item.packdate)}
      </Text>
      <PackOwnedRatioComponent id={item.id} />
    </TouchableOpacity>
  ));

  const uniqueStyles = StyleSheet.create({
    inputBox: {
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderWidth: 0.5,
      borderRadius: 8,
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginRight: 5,
      marginBottom: 15,
      height: 30,
    },
  });

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <SortButton {...{ OrderSetting, setOrderSetting }} page="PackList" />

      <View style={styles.flexbox}>
        <TextInput
          placeholder="検索"
          placeholderTextColor="rgb(132, 132, 132)"
          onChangeText={(newValue) => {
            const newProps = { ...Props };

            newProps.name = newValue;

            setProps({
              ...newProps,
            });
          }}
          style={uniqueStyles.inputBox}
          value={Props.name?.value}
        ></TextInput>

        <FlatList
          data={Items}
          numColumns={3}
          ref={FlatRef}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={() => setPageNo(PageNo + 1)}
          onEndReachedThreshold={1}
          contentContainerStyle={styles.items_wrapper}
        />
        {!Items.length && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text>パックがありません</Text>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  items_wrapper: {
    marginBottom: 150,
  },
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
  },
  pack_owned_ratio: {
    fontWeight: "bold",
    textAlign: "center",
    color: "black",
    marginTop: 2,
  },
  complete: {
    color: "rgba(184,42,42,1)",
    fontWeight: "bold",
    textAlign: "center",
  },
  group1: {
    width: "100%",
    height: 127,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  flexbox: {
    flex: 1,
    display: "wrap",
    flexDirection: "column",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 21,
    paddingBottom: "22%",
  },
  cupertinoHeaderWithLargeTitle1: {
    height: 96,
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
  },
  cat_area: {
    width: "90%",
    display: "wrap",
    flexDirection: "row",
    flexWrap: "flex-wrap",
    height: 25,
    flexDirection: "row",
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
  },
  cat_box: {
    width: "25%",
    borderBottomColor: "#e4e4e4",
    borderBottomWidth: 1,
  },
  すべて1: {
    color: "rgba(132,132,132,1)",
    fontSize: 12,
  },
  cat_box_active: {
    width: "25%",
    borderBottomColor: "rgba(184,42,42,1)",
    borderBottomWidth: 3,
  },
  cat: {
    textAlign: "center",
    color: "rgba(132,132,132,1)",
    fontSize: 12,
  },
  cat_active: {
    textAlign: "center",
    color: "rgba(184,42,42,1)",
    fontSize: 12,
  },
  image: {
    width: 100,
    aspectRatio: 1,
  },
  すべて1Row: {
    height: 18,
    flexDirection: "row",
    marginTop: 27,
    marginLeft: 29,
    marginRight: 29,
    marginBottom: 20,
  },
  imageStack: {
    width: "30%",
    marginLeft: "1.5%",
    marginRight: "1.5%",
    marginBottom: 20,
  },
  パック名: {
    color: "rgba(76,76,76,1)",
    fontSize: 12,
    textAlign: "left",
  },
  カード名: {
    color: "rgba(132,132,132,1)",
    fontSize: 10,
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
});
