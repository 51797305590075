import { useState, useEffect } from "react";
import { ScrollView, Text, TouchableOpacity } from "react-native";
import { account_style as styles } from "../lib/style/Account.style.js";

export function DeveloperScreen({
  navigation,
  CurrentMenu,
  CurrentGenre,
  API,
}) {
  const GenreSelect = (genre_id) => {
    CurrentGenre[1](genre_id);
    API.NavigateMenu([genre_id, null], CurrentMenu, "アイテム");
  };

  const [image, setImage] = useState(null);

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <ScrollView>
      <TouchableOpacity
        onPress={() => API.navigation.navigate("NeedSignUpScreen")}
      >
        <Text style={styles.title}>会員登録が必要画面</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => GenreSelect(11)}>
        <Text style={styles.title}>シャドバ</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}
