import { useState, useCallback, useEffect, useRef } from "react";
import {
  ActivityIndicator,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import { Image } from "expo-image";

import { useFocusEffect } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";

import { E1_style as styles } from "../lib/style/E.style";
import { BottomMenu } from "../components/Menu";
import { ItemListComponent } from "../components/Item";
import { Graph } from "../components/Graph";
import { MyItemDetail } from "../components/MyItemDetail";
import { PieChartGraph } from "../components/PieChart";
import moment from "moment";
import { SortButton } from "../components/Sort";
import {
  DetailModal,
  DetailRegisterModal,
  SellModal,
} from "../components/Register";
import { ColorPrice } from "../components/Common";

export function MyItemListScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  //リファクタVer
  const [Items, setItems] = useState([]); //表示されているアイテムの情報を格納する変数
  const [PageNo, setPageNo] = useState(1); //現在のページ数
  const [UserData, setUserData] = useState(); //ユーザーデータ
  const [ShowAsset, setShowAsset] = useState(true); //資産を表示するかしないか
  const [TypeInfo, setTypeInfo] = useState({}); //このタイプの情報
  const [IsLoading, setIsLoading] = useState(false); //ローディング中かどうか
  const [GraphData, setGraphData] = useState([]); //グラフ用のデータ
  const [graphTerm, setGraphTerm] = useState(30); //グラフの表示期間
  const [profit, setProfit] = useState(0); //昨日からの利益の差
  const [ThisConditionTotalAsset, setThisConditionTotalAsset] = useState(0);
  const [TotalAsset, setTotalAsset] = useState(0);
  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  const [detailGraphData, setDetailGraphData] = useState([]);

  const [detailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報

  const [detailRegisterInitValue, setDetailRegisterInitValue] = useState({});

  const [detailRegisterInfo, setDetailRegisterInfo] = useState({});

  //売却モーダル
  const [sellModalInfo, setSellModalInfo] = useState({
    isShow: false,
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
    ended: false, //売却が完了しましたの画面にするかどうか
  });

  //取引履歴詳細モーダル
  const [detailModalInfo, setDetailModalInfo] = useState({
    isShow: false,
    data: {},
    item: {}, //アイテムの情報
    record: {}, //登録レコードの情報,
  });

  const FlatRef = useRef(null);
  const flatListScrollRef = useRef(0);

  const { ItemTypeList } = API.constant;

  //所有数を変える関数
  const setNumber = (i, number) => {
    if (UserData) {
      let NewItems = [...Items];
      NewItems[i].number = parseInt(NewItems[i].number) + number;

      setItems([...NewItems]);

      //データベースの方も変える
      API.registerItem(
        {
          user: UserData.id,
          item: NewItems[i].id,
        },
        {
          is_detail: 0, //簡易登録であるため
          number,
        },
      ).then((response) => {
        //念のため個数を反映させ、そのあとに資産系を計算しなおす
        NewItems[i].total_number = response.total_count;
        NewItems[i].number = response.easy_count;

        setItems([...NewItems]);

        getTotalAsset(UserData);
        getThisConditionTotalAsset(UserData);
      });
    } else {
      API.NavigateToNeedSignUp();
    }
  };

  //パラメータを確認する部分
  useEffect(() => {
    (async () => {
      const ThisLabel = route.params?.type || "一覧";
      setTypeInfo(ItemTypeList.find((item) => item.label == ThisLabel));

      setIsLoading(true);
    })();
  }, [route.params?.type]);

  //ユーザー情報が変更されたときとフォーカスされたときに所有データを更新してMyItemsに登録する　また、資産系についても計算しなおす　また、お気に入りも計算する

  //スクロールさせたいかどうか
  const wannaScroll = useRef(false);

  useEffect(() => {
    wannaScroll.current = true;
  }, [OrderSetting, TypeInfo]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setPageNo(0);

        const _UserData = API.user;
        setUserData(_UserData);

        if (_UserData) {
          // 資産系のデータを取得
          getTotalAsset(_UserData);
          getThisConditionTotalAsset(_UserData);
          getAllMyItems(_UserData);
        } else {
          API.NavigateToNeedSignUp();
        }
      })();
    }, [TypeInfo, OrderSetting, graphTerm, detailRegisterModalInfo]), // 依存配列
  );

  //詳細グラフデータ
  useEffect(() => {
    if (Items.length) {
      setItems([
        ...Items.map((item) => {
          //グラフデータ
          item = Object.assign(item, {
            detail_graph_data: detailGraphData[item.id] || [],
          });
          return item;
        }),
      ]);
    }
  }, [detailGraphData]);

  //ユーザー情報が更新されたときやページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    (async () => {
      if (UserData) {
        if (PageNo == 0) {
          setPageNo(1);
          return false;
        }

        const NewItems = await API.getItem(
          {
            ...TypeInfo.condition,
            user: UserData.id,
          },
          { detail: 1 },
          {
            cur: PageNo,
            per: 18,
          },
          {
            column: OrderSetting.column,
            mode: OrderSetting.mode,
          },
        );

        console.log(NewItems);

        //現在のItemsにそのまま結合していいのか判断
        if (PageNo == 1 && Items.length) {
          setItems([...NewItems]);

          if (wannaScroll.current) {
            FlatRef.current?.scrollToIndex({
              index: 0,
              animated: true,
            });

            wannaScroll.current = false;
          }
        } else {
          //ページ番号がかわったらカードデータを再取得する
          setItems([...Items.concat(NewItems)]);
        }

        setIsLoading(false);

        const term = 30;

        if (NewItems.length) {
          const detail_graph_data =
            (await API.getItemGraph({ id: NewItems.map((e) => e.id), term })) ||
            {};
          setDetailGraphData({
            ...Object.assign(detailGraphData, detail_graph_data),
          });
        }

        //詳細登録レコード等を取得する
        if (NewItems.length) {
          const data = await API.getDetailRegisterInfo({
            user: UserData.id,
            ...TypeInfo.condition,
          });

          setDetailRegisterInfo(data);
        }
      }
    })();
  }, [UserData, PageNo]);

  //総資産計算関数と個別総資産計算関数は独立して宣言する

  const getTotalAsset = (_UserData) => {
    if (_UserData) {
      API.getAsset({ user: _UserData.id }).then((total_asset) => {
        total_asset = total_asset || 0;
        setTotalAsset(total_asset);
      });
    }
  };

  const getThisConditionTotalAsset = (_UserData) => {
    if (_UserData && TypeInfo.condition) {
      API.getAsset({
        user: _UserData.id,
        ...TypeInfo.condition,
      }).then(async (total_asset) => {
        total_asset = total_asset || 0;

        // グラフのデータ取得
        let graph_data = await API.getAssetGraph({
          ...TypeInfo.condition,
          user: _UserData.id,
          term: graphTerm,
        });

        const today = moment().format("YYYY-MM-DD");
        graph_data.push({
          date: today, // YYYY-MM-DD
          value: total_asset, // 整数
        });

        setGraphData([...graph_data]);
        setThisConditionTotalAsset(total_asset);

        // 利益の計算と設定
        if (graph_data.length >= 2) {
          const lastData = graph_data[graph_data.length - 1].value;
          const secondLastData = graph_data[graph_data.length - 2].value;
          const profit = lastData - secondLastData;
          setProfit(profit);
        }
      });
    }
  };

  //すべての所有アイテムを取得し、その個数も取得する（ここのデータ通信量をゆくゆくはおさえたい）
  const getAllMyItems = async (_UserData) => {
    if (_UserData) {
      const AllItems = await API.getItem(
        { user: _UserData.id },
        { detail: 1 },
        {},
      );

      setEachNumber([
        ...EachNumber.map((Each) => {
          const { condition } = Each;
          let ThisSum = AllItems.reduce((sum, item) => {
            for (const prop in condition) {
              if (condition[prop] != item[prop]) return sum;
            }

            return sum + parseInt(item.total_number);
          }, 0);

          Each.sum = ThisSum;

          return Each;
        }),
      ]);
    } else {
    }
  };

  //個別の枚数計算関数を作っていく
  const [EachNumber, setEachNumber] = useState([...ItemTypeList]);

  //売却の初期値を定義するためのステート変数
  const [sellInitValue, setSellInitValue] = useState({});
  const showSellEditModalForDetailModal = (data, item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });
    setSellInitValue({
      id: data.id,
      item_count: data.item_count,
      price: data.price,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  const showSellModal = (item, record) => {
    setSellModalInfo({
      isShow: true,
      item,
      record,
      ended: false,
    });

    setSellInitValue({});
  };

  //取引履歴の詳細モーダルを表示する
  const showDetailModal = async (record, item) => {
    let data = await API.getTransactions({
      user: UserData.id,
      item_registration_id: record.id,
      transaction_kind: "buy",
    });

    data = data[0];

    setDetailModalInfo({
      isShow: true,
      data,
      item,
      record,
    });
  };

  //編集用モーダルを表示
  const showRegisterModalForDetailModal = (data) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item: data.item,
    });
    setDetailRegisterInitValue({
      id: data.id,
      number: data.item_count,
      price: data.price,
      state: data.state,
      flg: data.flg,
      store: data.store,
      memo: data.memo,
      created: data.created,
    });
  };

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) => {
      let detasil_price = 0;
      if (
        detailRegisterInfo[item.id] &&
        Array.isArray(detailRegisterInfo[item.id])
      ) {
        detailRegisterInfo[item.id].forEach((data) => {
          data.records.forEach((record) => {
            detasil_price +=
              data.state * 0.01 * (record.current_count * item.price);
          });
        });
      }

      return (
        <>
          {index == 0 && (
            <View key={index} style={styles.graphbox}>
              <Graph
                data={GraphData}
                term={graphTerm}
                setTerm={setGraphTerm}
                type={"マイカード一覧"}
                API={API}
              />
            </View>
          )}
          <View style={styles.listbox}>
            <View key={index + 1} style={styles.imagebox}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate({
                    name: "アイテム詳細",
                    params: { item },
                  })
                }
                style={styles.card_box}
              >
                <Image
                  source={{
                    uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
                  }}
                  resizeMode="contain"
                  style={styles.image4}
                />
                <View style={styles.card_text_box}>
                  <ItemListComponent item={item} mode="myitem" />

                  <View style={styles.price_area}>
                    <Text
                      style={[styles.zero, { fontSize: 10, marginLeft: 0 }]}
                    >
                      {item.pack} {item.packexpansion}
                    </Text>
                  </View>

                  <View style={styles.price_area}>
                    <Text style={styles.mycard_price}>
                      ￥
                      {(item.price || 0)
                        .toLocaleString("en-US", { maximumFractionDigits: 0 })
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Text>

                    <Text
                      style={
                        item.pricegap === 0
                          ? styles.zero
                          : item.pricegap < 0
                            ? styles.minus
                            : styles.plus
                      }
                    >
                      {item.pricegap !== 0 &&
                        (item.pricegap < 0 ? <>&darr;</> : <>&uarr;</>)}
                      ￥{Math.abs(item.pricegap).toLocaleString()}
                    </Text>
                  </View>
                </View>
                <Text style={styles.カード名8}>
                  ￥
                  {(item.price * item.number + detasil_price).toLocaleString()}
                </Text>
              </TouchableOpacity>

              <View style={styles.column_div}>
                <View style={styles.graph_div}>
                  <Graph
                    data={item.detail_graph_data}
                    type={"お気に入り一覧"}
                    API={API}
                  />
                </View>

                <View style={styles.rect16}>
                  <TouchableOpacity
                    onPress={() => setNumber(index, -1)}
                    style={styles.del_box}
                  >
                    <Text style={styles.del_box_text}>-</Text>
                  </TouchableOpacity>
                  <View style={styles.pos_box}>
                    <Text style={styles.pos_box_text}>
                      {item.number == item.total_number
                        ? item.total_number
                        : `${item.number}(${parseInt(item.total_number) - parseInt(item.number)})`}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => setNumber(index, 1)}
                    style={styles.add_box}
                  >
                    <Text style={styles.add_box_text}>+</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            {/* この下から詳細登録の表示部分 */}
            <MyItemDetail
              flatListScrollRef={flatListScrollRef}
              flatRef={FlatRef}
              itemData={item}
              detailItems={detailRegisterInfo[item.id]}
              showSellModal={showSellModal}
              setDetailRegisterInitValue={setDetailRegisterInitValue}
              setDetailRegisterModalInfo={setDetailRegisterModalInfo}
              showDetailModal={showDetailModal}
            />
          </View>
        </>
      );
    },
    [Items, GraphData, detailRegisterInfo, detailGraphData],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <SortButton {...{ OrderSetting, setOrderSetting }} page="MyItem" />

      <DetailRegisterModal
        API={API}
        Info={detailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
        initValue={detailRegisterInitValue}
      />
      <SellModal
        API={API}
        Info={sellModalInfo}
        setInfo={setSellModalInfo}
        navigation={navigation}
        initValue={sellInitValue}
      />
      <DetailModal
        API={API}
        Info={detailModalInfo}
        setInfo={setDetailModalInfo}
        navigation={navigation}
        showSellModal={showSellModal}
        showDetailRegisterModal={showRegisterModalForDetailModal}
        showSellEditModal={showSellEditModalForDetailModal}
      />

      <View style={styles.portfolio_area}>
        <View style={styles.pie_chart_box}>
          <Text style={styles.category_title}>{TypeInfo.label}</Text>
          <PieChartGraph
            totalAsset={TotalAsset}
            thisConditionTotalAsset={ThisConditionTotalAsset}
          />
        </View>
        <View style={styles.portfolio_box}>
          <View style={styles.portfolio_box_title_area}>
            <Text style={styles.portfolio_box_title}>資産</Text>
          </View>
          <View style={styles.portfolio_box_text_area}>
            <View
              style={[
                styles.portfolio_box_text_area,
                { flexDirection: "row" },
                styles.priceField,
              ]}
            >
              <Text style={styles.portfolio_box_price}>
                {ShowAsset ? (
                  ThisConditionTotalAsset.toLocaleString()
                ) : (
                  <Text style={{ color: "#c7c7c7" }}>*******</Text>
                )}
              </Text>
              <Text style={styles.portfolio_box_yen}>円</Text>
            </View>
            {ShowAsset && <ColorPrice fontSize={13}>{profit}</ColorPrice>}
          </View>

          <View style={[styles.portfolio_box_title_area, { marginTop: 5 }]}>
            <Text style={styles.portfolio_box_title}>カード枚数</Text>
          </View>
          <View
            style={[
              styles.portfolio_box_text_area,
              { flexDirection: "row" },
              styles.priceField,
            ]}
          >
            <Text style={styles.portfolio_box_price}>
              {ShowAsset ? (
                TypeInfo.sum
              ) : (
                <Text style={{ color: "#c7c7c7" }}>*******</Text>
              )}
            </Text>
            <Text style={styles.portfolio_box_yen}>枚</Text>
          </View>
        </View>

        <TouchableOpacity
          onPress={() => navigation.navigate("資産一覧")}
          style={styles.mypage_link_area}
        >
          <Text style={styles.mypage_link}>マイページへ</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setShowAsset(!ShowAsset)}
          style={styles.hide_values_button}
        >
          {ShowAsset ? (
            <AntDesign
              name="eye"
              width={32}
              height={32}
              style={{ fontSize: 20, color: "#c7c7c7" }}
            />
          ) : (
            <AntDesign
              name="eye-slash"
              width={32}
              height={32}
              style={{ fontSize: 20, color: "#c7c7c7" }}
            />
          )}
        </TouchableOpacity>
      </View>
      <View
        style={{ height: 35, justifyContent: "center", alignItems: "center" }}
      >
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={styles.scrollView}
        >
          {EachNumber.map((Each, i) => (
            <>
              {Each.sum > 0 && (
                <TouchableOpacity
                  key={i}
                  onPress={() =>
                    navigation.navigate({
                      name: "マイカード一覧",
                      params: { type: Each.label },
                    })
                  }
                  style={
                    TypeInfo.label == Each.label
                      ? styles.cat_box_active
                      : styles.cat_box
                  }
                >
                  <Text
                    style={
                      TypeInfo.label == Each.label
                        ? styles.cat_active
                        : styles.cat
                    }
                  >
                    {Each.label}
                  </Text>
                </TouchableOpacity>
              )}
            </>
          ))}
        </ScrollView>
      </View>

      <View style={styles.image4ColumnRow}>
        {IsLoading && (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#b82a2a" />
          </View>
        )}

        {!Items.length && !IsLoading && (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text>カードが登録されていません。カードを登録してください。</Text>
          </View>
        )}

        {Items.length > 0 && GraphData.length > 0 && (
          <FlatList
            onScroll={(event) => {
              flatListScrollRef.current = event.nativeEvent.contentOffset.y / 1;
            }}
            numColumns={1}
            data={Items}
            ref={FlatRef}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={() => setPageNo(PageNo + 1)}
            onEndReachedThreshold={1}
            contentContainerStyle={styles.items_wrapper}
          />
        )}
      </View>
    </View>
  );
}
