import React, { useEffect, useRef, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { View, Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

//スクリーン
import { Init } from "./screens/Init";
import {
  LoginScreen,
  SignUpScreen,
  ChangePasswordScreen,
  ForgetPasswordScreen,
  DeleteAccountScreen,
  NeedSignUpScreen,
} from "./screens/Account";
import { MyItemListScreen } from "./screens/MyItem";
import { MyFavoriteListScreen } from "./screens/MyFavorite";
import { MyAssetScreen } from "./screens/MyAsset";
import { ItemDetailScreen } from "./screens/ItemDetail";
import {
  IntroductionAssetConfirmScreen,
  IntroductionUnknownPriceScreen,
  IntroductionItemRegisterScreen,
  IntroductionBoxRegisterScreen,
  IntroductionInitScreen,
} from "./screens/Introduction";
import { TopPageScreen } from "./screens/TopPage";
import { GenreSelectScreen } from "./screens/GenreSelect";
import { PackListScreen } from "./screens/PackList";
import { ItemListScreen } from "./screens/ItemList";
import { ItemFindScreen } from "./screens/ItemFind";
import { DeveloperScreen } from "./screens/Developer";
import {
  DeckListScreen,
  DeckDetailScreen,
  DeckGenreScreen,
  DeckMakeScreen,
  DeckSettingScreen,
  DeckFinishScreen,
  DeckScreenshotScreen,
  DeckLeaderScreen,
} from "./screens/Deck";
import {
  CollectionDetailScreen,
  CollectionListScreen,
  CollectionGenreScreen,
  CollectionMakeScreen,
  CollectionSettingScreen,
  CollectionFinishScreen,
} from "./screens/Collection";

//コンポーネント
import { ChangeGenre, AppName } from "./components/Header";
import { TransactionListScreen } from "./screens/Transaction";
import { MycaAPI } from "./lib/function";
import { ShopMenuScreen } from "./screens/shop/Shop";
import { SateiScreen, PurchaseTermsScreen } from "./screens/shop/Satei";
import { GlobalNotifyProvider } from "./contexts/GlobalNotify";

const Stack =
  Platform.OS == "web" ? createNativeStackNavigator() : createStackNavigator();

export default function App() {
  //現在のジャンル
  const CurrentGenre = useState(0); //初期ジャンル値を0にすることでトップページに遷移させることができる
  const CurrentMenu = useState({
    top: "すべて",
    middle: "カード",
    bottom: "トップ",
  }); //デフォルトはマイカード

  const API = useRef(new MycaAPI()).current;

  useEffect(() => {
    (async () => {
      //セットアップを行い、あらかじめユーザー情報などを取得しておく
      //ここでセットアップを行うと非同期になってしまうため、メンテナンス情報確認に際に行うようにする
      // await API.setUp()
    })();
  }, []);

  return (
    <NavigationContainer>
      <GlobalNotifyProvider API={API}>
        <View
          style={{
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        >
          <Stack.Navigator
            screenOptions={({ navigation }) => {
              API.navigation = navigation;

              return {
                headerTitleAlign: "center",
                headerTitle: () => <AppName />,
                headerRight: () => <ChangeGenre navigation={navigation} />,
                headerBackTitleVisible: false,
              };
            }}
          >
            {/* メンテナンス中か判断するためのスクリーン */}
            <Stack.Screen name="Init" options={{ headerShown: false }}>
              {(props) => (
                <Init
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* ログイン画面 */}
            <Stack.Screen name="LoginScreen" options={{ headerShown: false }}>
              {(props) => (
                <LoginScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 新規会員登録スクリーン */}
            <Stack.Screen name="SignUpScreen" options={{ headerShown: false }}>
              {(props) => (
                <SignUpScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* パスワード変更スクリーン */}
            <Stack.Screen name="ChangePasswordScreen">
              {(props) => (
                <ChangePasswordScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* パスワード忘れた人用のスクリーン */}
            <Stack.Screen
              name="ForgetPasswordScreen"
              options={{ headerShown: false }}
            >
              {(props) => (
                <ForgetPasswordScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* アカウント削除スクリーン */}
            <Stack.Screen name="DeleteAccountScreen">
              {(props) => (
                <DeleteAccountScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 開発者向け確認スクリーン */}
            <Stack.Screen name="DeveloperScreen">
              {(props) => (
                <DeveloperScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* ログインが必要スクリーン */}
            <Stack.Screen name="NeedSignUpScreen">
              {(props) => (
                <NeedSignUpScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* アプリの紹介系 */}
            <Stack.Screen
              name="IntroductionInitScreen"
              component={IntroductionInitScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="IntroductionBoxRegisterScreen"
              component={IntroductionBoxRegisterScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="IntroductionItemRegisterScreen"
              component={IntroductionItemRegisterScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="IntroductionAssetConfirmScreen"
              component={IntroductionAssetConfirmScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="IntroductionUnknownPriceScreen"
              component={IntroductionUnknownPriceScreen}
              options={{ headerShown: false }}
            />

            {/* トップページのスクリーン */}
            <Stack.Screen name="トップページ">
              {(props) => (
                <TopPageScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* アイテム一覧スクリーン（元B1等） */}
            <Stack.Screen name="アイテム一覧">
              {(props) => (
                <ItemListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* パック一覧スクリーン（元B1等） */}
            <Stack.Screen name="パック一覧">
              {(props) => (
                <PackListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 絞り込み選択肢スクリーン（元B2等） */}
            <Stack.Screen name="アイテム絞り込み">
              {(props) => (
                <ItemFindScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 資産一覧スクリーン（元H3等） */}
            <Stack.Screen name="資産一覧">
              {(props) => (
                <MyAssetScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* マイカード一覧スクリーン（元E～） */}
            <Stack.Screen name="マイカード一覧">
              {(props) => (
                <MyItemListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* マイお気に入り一覧スクリーン（元I～） */}
            <Stack.Screen name="お気に入り一覧">
              {(props) => (
                <MyFavoriteListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* デッキ一覧スクリーン */}
            <Stack.Screen name="デッキ一覧">
              {(props) => (
                <DeckListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* デッキジャンルスクリーン */}
            <Stack.Screen name="デッキジャンル">
              {(props) => (
                <DeckGenreScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="デッキリーダー選択">
              {(props) => (
                <DeckLeaderScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="デッキ作成" options={{ headerRight: null }}>
              {(props) => (
                <DeckMakeScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="デッキ設定">
              {(props) => (
                <DeckSettingScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="デッキ作成完了">
              {(props) => (
                <DeckFinishScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* デッキ詳細スクリーン */}
            <Stack.Screen name="デッキ詳細">
              {(props) => (
                <DeckDetailScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            <Stack.Screen
              name="デッキスクリーンショット"
              options={{ headerShown: false }}
            >
              {(props) => (
                <DeckScreenshotScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* アイテム詳細スクリーン（元B11等） */}
            <Stack.Screen name="アイテム詳細">
              {(props) => (
                <ItemDetailScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* ジャンル選択スクリーン（元J1）　※ジャンル切り替えのためのstateのsetterも付与する */}
            <Stack.Screen name="ジャンル選択">
              {(props) => (
                <GenreSelectScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* コレクション一覧スクリーン */}
            <Stack.Screen name="コレクション一覧">
              {(props) => (
                <CollectionListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* コレクション作成スクリーン */}
            <Stack.Screen name="コレクションジャンル">
              {(props) => (
                <CollectionGenreScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="コレクション作成"
              options={{ headerRight: null }}
            >
              {(props) => (
                <CollectionMakeScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="コレクション設定">
              {(props) => (
                <CollectionSettingScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>
            <Stack.Screen name="コレクション作成完了">
              {(props) => (
                <CollectionFinishScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* コレクション詳細スクリーン */}
            <Stack.Screen name="コレクション詳細">
              {(props) => (
                <CollectionDetailScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 取引履歴一覧スクリーン */}
            <Stack.Screen name="取引履歴一覧">
              {(props) => (
                <TransactionListScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* ショップスクリーン */}
            <Stack.Screen name="ショップページ">
              {(props) => (
                <ShopMenuScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* ショップ査定スクリーン */}
            <Stack.Screen name="ショップ査定ページ">
              {(props) => (
                <SateiScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>

            {/* 買取規約確認スクリーン */}
            <Stack.Screen name="買取規約確認">
              {(props) => (
                <PurchaseTermsScreen
                  {...Object.assign(props, { CurrentGenre, CurrentMenu, API })}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        </View>
      </GlobalNotifyProvider>
    </NavigationContainer>
  );
}
