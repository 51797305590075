//詳細登録、売却のモーダルを定義するファイル

import { useState, useEffect } from "react";
import {
  Alert,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal,
  TextInput,
  KeyboardAvoidingView,
} from "react-native";
import { Image } from "expo-image";
import { AntDesign } from "@expo/vector-icons";
// import {Picker} from '@react-native-picker/picker';
// import {Picker} from '@react-native-community/picker';
// import PickerSelect from 'react-native-picker-select';
import { MycaAPI } from "../lib/function";
import { MycaButton } from "./Common";
const { style } = MycaAPI;

export function UserNameInputModal({ Info, setInfo, getUserInfo, API }) {
  const [UserData, setUserData] = useState();

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する

      setUserData(API.user);
    })();
  }, []);

  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "80%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    contentWrapper: {
      margin: 15,
      flexDirection: "column",
      alignItems: "center",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
    },
    item_name: {
      fontSize: 17,
      margin: 2,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
      flex: 1,
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    input_field: {
      margin: 15,
      gap: 8,
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
    },
    memo_input_field: {
      marginBottom: 80,
    },
    input_label: {
      width: "20%",
      backgroundColor: style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    register_button_field: {
      position: "absolute",
      paddingBottom: 15,
      paddingTop: 10,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 100,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
      marginBottom: 20,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },

    input_box: {
      height: 35,
      padding: 5,
      width: "95%",
      backgroundColor: "white",
      borderColor: "black",
      borderWidth: 0.5,
      borderRadius: 5,
      overflow: "hidden",
      marginBottom: 5,
      textAlign: "center",
    },
  });

  const Close = () => {
    setInfo({ isShow: false, ended: false });
  };

  const submit = async () => {
    if (UserData.id) {
      if (0 < inputValue.length <= 20) {
        //ここで不適切な言葉かどうかをチェックしたい
        const res = await API.editUserInfo(
          {
            user: UserData.id,
          },
          {
            display_name: inputValue,
          },
        );

        if (res.ok) {
          await getUserInfo(UserData);

          setInfo({
            isShow: true,
            ended: true,
          });
        }
      } else {
        Alert.alert("", "ユーザー名は1文字以上20文字以下にする必要があります");
      }
    }
  };

  return (
    <>
      {Info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={Info.isShow} animationType={"fade"}>
        <KeyboardAvoidingView style={{ height: "100%" }} behavior="padding">
          <View style={styles.frame}>
            <View style={styles.modal}>
              <View style={styles.header}>
                <Text style={styles.header_title}>ユーザー名登録</Text>
                <TouchableOpacity
                  style={styles.close_icon}
                  onPress={() => Close()}
                >
                  <AntDesign name="close" size={30} color="white" />
                </TouchableOpacity>
              </View>

              {!Info.ended ? (
                <>
                  <View style={styles.contentWrapper}>
                    <Text
                      style={{
                        fontSize: 18,
                        textAlign: "center",
                        fontWeight: "bold",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      コレクションを{"\n"}
                      一般公開する場合には{"\n"}
                      ユーザー名を作成してください
                    </Text>
                    <TextInput
                      value={inputValue}
                      onChangeText={(newValue) => {
                        setInputValue(newValue);
                      }}
                      style={styles.input_box}
                      placeholder="ユーザー名を入力"
                      placeholderTextColor="#000000ba"
                    />

                    <View>
                      <MycaButton color="red" onPress={() => submit()}>
                        {" "}
                        登録する{" "}
                      </MycaButton>
                      <MycaButton color="gray" onPress={() => Close()}>
                        {" "}
                        戻る{" "}
                      </MycaButton>
                    </View>
                  </View>
                </>
              ) : (
                <>
                  <Image
                    style={styles.ended_check}
                    source={require("../assets/ended_check.png")}
                  />
                  <Text style={styles.ended_string}>登録が完了しました</Text>

                  <TouchableOpacity
                    style={styles.ended_button_field}
                    onPress={() => Close()}
                  >
                    <Text style={styles.register_button}>閉じる</Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </>
  );
}
