import { useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Image } from "expo-image";
import { account_style as styles } from "../lib/style/Account.style.js";

import { Entypo } from "@expo/vector-icons";

function Logo(props) {
  const logo_styles = StyleSheet.create({
    logo_area: {
      width: 170,
      aspectRatio: 1,
      marginTop: 60,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 10,
    },
    logo: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
    },
  });

  return (
    <View style={logo_styles.logo_area}>
      <Image
        source={require("../assets/app_logos/mycalinks_logo_tate.png")}
        resizeMode="contain"
        style={logo_styles.logo}
      ></Image>
    </View>
  );
}

//ユーザー登録、ログイン等に関するスクリーンをまとめる

//ログインのスクリーン
export function LoginScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //既にログインしてたらこのスクリーンを飛ばす
  useEffect(() => {
    (async () => {
      if (props.API.user?.id) {
        Navigate();
      }
    })();
  }, []);

  const LogIn = (email, password) => {
    //新APIでログイン機能実装
    props.API.Login(email, password).then((response) => {
      if (response.status == 200) {
        //普通にログイン成功したか失敗してたら

        if (response.data.length == 1) {
          Navigate();
        } else {
          setError("メールアドレスかパスワードが違います");
        }
      } else if (response.status == 210) {
        if (response.data.length == 1) {
          console.log("仮発行したパスワードでログインできました");
          props.API.navigation.replace("ChangePasswordScreen"); // パスワード変更画面に遷移する
        }
      }
    });
  };

  const Navigate = () => {
    props.API.navigation.reset({
      index: 0,
      routes: [
        {
          name: "トップページ",
        },
      ],
    });
  };

  return (
    <ScrollView style={styles.container}>
      <Logo />

      {props.route.params?.situation == "after_signup" && (
        <>
          <Text style={styles.login_text_red}>会員登録が完了しました。</Text>
          <Text style={styles.login_text_red}>
            下記よりログインしてください。
          </Text>
        </>
      )}

      {props.route.params?.situation == "after_forgetpassword" && (
        <>
          <Text style={styles.login_text_red}>
            仮パスワードが書かれたメールを送信しました
          </Text>
          <Text style={styles.login_text_red}>
            下記よりログインし、すぐにパスワードを更新してください
          </Text>
        </>
      )}

      {!props.route.params?.situation && (
        <Text style={styles.login_title}>-ログイン-</Text>
      )}
      <Text style={styles.メールアドレス}>メールアドレス</Text>
      <TextInput
        placeholder="メールアドレス"
        value={email}
        onChangeText={setEmail}
        style={styles.placeholder}
      ></TextInput>
      <Text style={styles.パスワード}>パスワード</Text>
      <View style={styles.inputContainer}>
        <View style={styles.inputWrapper}>
          <TextInput
            placeholder="パスワード"
            textContentType="password"
            textBreakStrategy="simple"
            keyboardAppearance="default"
            type={"password"}
            value={password}
            onChangeText={setPassword}
            secureTextEntry={!showPassword}
            style={styles.textInput1}
          />
          <TouchableOpacity
            style={styles.iconWrapper}
            onPress={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <Entypo
                name="eye"
                width={32}
                height={32}
                style={{ fontSize: 20, color: "#c7c7c7" }}
              />
            ) : (
              <Entypo
                name="eye-with-line"
                width={32}
                height={32}
                style={{ fontSize: 20, color: "#c7c7c7" }}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity onPress={() => LogIn(email, password)}>
        <View style={styles.rect}>
          <Text style={styles.ログイン}>ログイン</Text>
        </View>
      </TouchableOpacity>
      {error ? <Text style={styles.error}>{error}</Text> : null}
      <TouchableOpacity
        onPress={() => props.API.navigation.navigate("SignUpScreen")}
      >
        <Text style={styles.新規登録はこちら}>新規登録はこちら</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => props.API.navigation.navigate("ForgetPasswordScreen")}
      >
        <Text style={styles.passreset}>パスワード再発行はこちら</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => Navigate()}>
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

//新規会員登録のスクリーン
export function SignUpScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const signIn = () => {
    if (!isValidEmail(email)) {
      setErrorMessage("正しいメールアドレスの形式ではありません");
      return;
    }

    //新APIの会員登録機能
    props.API.SignUp(email, password).then((res) => {
      if (res.status == 200) {
        props.navigation.navigate({
          name: "LoginScreen",
          params: { situation: "after_signup" },
        });
        console.log("会員登録完了");
      } else if (res.status == 210) {
        setErrorMessage("メールアドレスまたはパスワードが入力されていません");
      } else if (res.status == 211) {
        setErrorMessage("このメールアドレスは既に登録されています");
      }
    });
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  return (
    <ScrollView style={styles.container}>
      <Logo />
      <Text style={styles.login_title}>-新規会員登録-</Text>
      <Text style={styles.メールアドレス}>メールアドレス</Text>
      <TextInput
        placeholder="メールアドレス"
        value={email}
        onChangeText={setEmail}
        style={styles.placeholder}
      ></TextInput>
      <Text style={styles.パスワード}>パスワード</Text>
      <View style={styles.inputContainer}>
        <View style={styles.inputWrapper}>
          <TextInput
            placeholder="パスワード"
            textContentType="password"
            textBreakStrategy="simple"
            keyboardAppearance="default"
            type={"password"}
            value={password}
            onChangeText={setPassword}
            secureTextEntry={!showPassword}
            style={styles.textInput1}
          />
          <TouchableOpacity
            style={styles.iconWrapper}
            onPress={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <Entypo
                name="eye"
                width={32}
                height={32}
                style={{ fontSize: 20, color: "#c7c7c7" }}
              />
            ) : (
              <Entypo
                name="eye-with-line"
                width={32}
                height={32}
                style={{ fontSize: 20, color: "#c7c7c7" }}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
      {errorMessage !== "" && <Text style={styles.error}>{errorMessage}</Text>}
      <TouchableOpacity onPress={() => signIn()} style={styles.rect}>
        <Text style={styles.ログイン}>登録</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => props.API.navigation.navigate("LoginScreen")}
      >
        <Text style={styles.新規登録はこちら}>ログインはこちら</Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

//パスワード変更用スクリーン
export function ChangePasswordScreen(props) {
  const [password, setPassword] = useState("");
  const [msg, setmsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [UserData, setUserData] = useState();

  //ログインしてなかったらA1に遷移させる
  useEffect(() => {
    (async () => {
      if (!props.API.user?.id) props.API.navigation.replace("LoginScreen");

      setUserData({ ...!props.API.user });
    })();
  }, []);

  const ChangePassword = async () => {
    //パスワード変更
    if (UserData) {
      if (password.length >= 6) {
        const res = await props.API.changePassword(password);

        if (res.status == 200) {
          setmsg("パスワードを変更しました。引き続きお楽しみください");

          setTimeout(() => {
            props.API.logOut(); //あえてログインスクリーンに飛ばす
          }, 1500);
        } else {
          setmsg("何かがうまくいかなかったようです");
        }
      } else {
        setmsg("パスワードは6文字以上入力してください");
      }
    } else {
      props.API.NavigateToNeedSignUp();
    }
  };

  return (
    <ScrollView style={styles.container}>
      <Logo />

      {msg && (
        <>
          <Text style={styles.login_text_red}>{msg}</Text>
        </>
      )}

      <Text style={styles.login_title}>-パスワード変更-</Text>

      {msg != "パスワードを変更しました。引き続きお楽しみください" && (
        <>
          <Text style={styles.パスワード}>パスワード</Text>
          <View style={styles.inputContainer}>
            <View style={styles.inputWrapper}>
              <TextInput
                placeholder="パスワード"
                textContentType="password"
                textBreakStrategy="simple"
                keyboardAppearance="default"
                type={"password"}
                value={password}
                onChangeText={setPassword}
                secureTextEntry={!showPassword}
                style={styles.textInput1}
              />
              <TouchableOpacity
                style={styles.iconWrapper}
                onPress={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Entypo
                    name="eye"
                    width={32}
                    height={32}
                    style={{ fontSize: 20, color: "#c7c7c7" }}
                  />
                ) : (
                  <Entypo
                    name="eye-with-line"
                    width={32}
                    height={32}
                    style={{ fontSize: 20, color: "#c7c7c7" }}
                  />
                )}
              </TouchableOpacity>
            </View>
          </View>
          <TouchableOpacity onPress={() => ChangePassword()}>
            <View style={styles.rect}>
              <Text style={styles.ログイン}>パスワード変更</Text>
            </View>
          </TouchableOpacity>
        </>
      )}
    </ScrollView>
  );
}

//パスワード忘れた人用のスクリーン
export function ForgetPasswordScreen(props) {
  const [email, setEmail] = useState("");

  //既にログインしてたらこのスクリーンを飛ばす
  useEffect(() => {
    (async () => {
      if (props.API.user?.id) props.API.navigation.replace("LoginScreen");
    })();
  }, []);

  const reset = async () => {
    const res = await props.API.forgetPassword(email);

    if ("ok" in res) {
      props.API.navigation.navigate({
        name: "LoginScreen",
        params: { situation: "after_forgetpassword" },
      });
    } else if (res.error == "mail unsended") {
      Alert.alert(
        "",
        `
    メールアドレスのご登録が確認できませんでした。
    再度正しいアドレスを入力してください。
    `,
      );
    }
  };

  return (
    <ScrollView style={styles.container}>
      <Logo />
      <Text style={styles.login_title}>-パスワード再発行-</Text>
      <Text style={styles.メールアドレス}>メールアドレス</Text>
      <TextInput
        placeholder="メールアドレス"
        value={email}
        onChangeText={setEmail}
        style={styles.placeholder}
      ></TextInput>
      <TouchableOpacity onPress={() => reset()}>
        <View style={styles.rect}>
          <Text style={styles.ログイン}>送信する</Text>
        </View>
      </TouchableOpacity>
    </ScrollView>
  );
}

//アカウント削除用のスクリーン
export function DeleteAccountScreen({ navigation, API }) {
  const [UserData, setUserData] = useState();
  const [IsEnd, setIsEnd] = useState(0);

  //ログインしてなかったらA1に遷移させる
  useEffect(() => {
    (async () => {
      if (!API.user?.id) API.navigation.replace("LoginScreen");

      setUserData({ ...API.user });
    })();
  }, []);

  const userdelete = async () => {
    if (UserData) {
      await API.deleteUser();

      setIsEnd(1);

      setTimeout(() => {
        API.navigation.navigate("LoginScreen");
      }, 3000);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => API.navigation.navigate("DeveloperScreen")}
      >
        <Text style={styles.title}>アカウント削除</Text>
      </TouchableOpacity>

      {!IsEnd ? (
        <>
          <View>
            <Text style={styles.text_red}>
              登録したカードのデータを元に戻せません
            </Text>
            <Text style={styles.text_red}>
              本当にアカウントを削除してよろしいですか？
            </Text>
          </View>
          <TouchableOpacity onPress={() => userdelete()} style={styles.rect3}>
            <Text style={styles.登録2}>アカウント削除</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => API.navigation.goBack()}
            style={styles.rect2}
          >
            <Text style={styles.登録}>削除しない</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <View>
            <Text style={styles.text_red}>アカウントを削除しました</Text>
          </View>
        </>
      )}
    </View>
  );
}

//会員登録が必要です
export function NeedSignUpScreen(props) {
  const goTwoBack = () => {
    NavigateMenu(props.CurrentGenre, props.CurrentMenu, "トップ");
  };

  return (
    <View style={styles.container}>
      <View style={styles.point_box}>
        <Text style={styles.point_title}>会員登録が必要です。</Text>
        <Image
          source={{ uri: "https://pokeka.egoism.jp/pokeka/image/ng.jpg" }}
          resizeMode="contain"
          style={styles.point_image}
        ></Image>
        <Text style={styles.point_text}>
          会員登録をすることにより、自分の持っているカードを登録し資産を確認可能になります。
        </Text>
        <TouchableOpacity
          onPress={() => props.API.navigation.navigate("SignUpScreen")}
          style={styles.rect2}
        >
          <Text style={styles.登録}>会員登録はこちら</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={() => goTwoBack()}>
        <Text style={styles.skip}>SKIP</Text>
      </TouchableOpacity>
    </View>
  );
}
