//デッキの検索、コンプリート率の確認等を行うスクリーン

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Modal,
  ScrollView,
  Alert,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
  ActivityIndicator,
  TouchableWithoutFeedback,
  ImageBackground,
} from "react-native";
import { Image } from "expo-image";
import { MycaAPI, MycaStyle } from "../lib/function";
import { TopMenu, BottomMenu } from "../components/Menu";
import { DeckCard } from "../components/DeckCard";
import { useFocusEffect } from "@react-navigation/native";
import {
  RegulationModal,
  DeckTopIcon,
  DetailModalForDeck,
  SaveDraftModal,
  DraftListModal,
} from "../components/Deck";
import { B1_style as styles } from "../lib/style/B.style";
import { ItemListComponent } from "../components/Item";
import RNPickerSelect from "react-native-picker-select";
// import Icon2 from "react-native-vector-icons/MaterialCommunityIcons";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome6,
} from "@expo/vector-icons";
// import { PanResponder, Animated, } from 'react-native';
import { SortButton } from "../components/Sort";
import { DisplayModeButton } from "../components/Collection";
import { ItemSearchOptionList } from "../components/Search";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { MycaButton } from "../components/Common";
import * as Clipboard from "expo-clipboard";
import { DetailRegisterModal } from "../components/Register";

//デッキのスクリーンショットスクリーン
import * as ScreenOrientation from "expo-screen-orientation";

//デッキ一覧スクリーン
export function DeckListScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数

  const [MyItems, setMyItems] = useState([]); //アイテムの所有情報を格納する変数
  const [genreList, setGenreList] = useState([]); //ジャンルリスト
  const [regulationList, setRegulationList] = useState([]); //レギュレーションリスト

  //存在しない時のやつ
  const [waitForMoment, setWaitForMoment] = useState(false);

  const [deckCondition, setDeckCondition] = useState({}); //デッキの条件（ここではgenreしか使わない　何も指定していないと条件なし、つまり全てのコレクションを取得する）

  //ポケカ公式の時の待ち状態を作るため このステート変数にはデッキコードを入れて使う
  const [pokekaOfficialDeckLoading, setPokekaOfficialDeckLoading] =
    useState("");

  const [isModalVisible, setModalVisible] = useState(false);
  const [deckData, setDeckData] = useState();
  const [selectItem, setSelectItem] = useState();
  const previousRegulationGroupName = useRef(null);
  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      setWaitForMoment(false);
      setTimeout(() => setWaitForMoment(true), 2000);

      //先にからにしておく
      setItems([]);

      if (route.params?.condition) {
        setDeckCondition({
          ...route.params.condition,
        });
      } else {
        setDeckCondition({});
      }

      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;

      if (CurrentMenu[0].top == "一覧" && !_UserData)
        API.NavigateToNeedSignUp();

      setUserData(_UserData);

      //ジャンルデータも取得する
      //なお、持っていないジャンルは含めないようにする
      let genreList = await API.getGenres();
      genreList = genreList.filter(
        (genre) =>
          API.version >= genre.deck_available_version &&
          genre.deck_available_version,
      );

      if (CurrentMenu[0].top == "一覧") {
        const decks = await API.getDecks("owned", {
          user: _UserData?.id,
        });

        genreList = genreList.filter((genre) =>
          decks.find((deck) => deck.genre == genre.id),
        );
      } else {
        //探すだったらレギュレーションを選択肢に入れる
        let _regulationList = await API.getRegulations();
        if (route.params?.condition?.genre) {
          _regulationList = _regulationList
            .filter(
              (regulation) =>
                regulation.genre == route.params?.condition?.genre,
            )
            .map((regulation) => ({
              label: `${regulation.name}`,
              value: regulation.id,
            }));
        } else {
          //「一覧」だったらレギュレーションの横にジャンルの名前を表示する
          _regulationList = _regulationList.map((regulation) => ({
            label: `${regulation.name}（${regulation.genre_name}）`,
            value: regulation.id,
          }));
        }
        setRegulationList([..._regulationList]);
      }

      setGenreList([...genreList]);
    })();
  }, [route.params?.condition]);

  // ユーザー情報が変更されたときとフォーカスされたときにコレクションリストを取得しなおす
  useFocusEffect(
    useCallback(() => {
      (async () => {
        // 一覧に関してはログインしていないと取得できないが、探すに関してはログインしていなくても取得できる

        const topMenu = CurrentMenu[0].top;

        const mode = topMenu == "一覧" ? "owned" : "search";

        //ここで検索ワードもパラメターにのせる
        const _UserData = API.user;

        //余計なコンディションを削ぎ落とす
        let _deckCondition = { ...deckCondition };
        for (const prop in _deckCondition) {
          if (!_deckCondition[prop]) delete _deckCondition[prop];
        }

        const decks = await API.getDecks(mode, {
          user: _UserData?.id,
          ..._deckCondition,
        });

        //ポケカの公式デッキがヒットしているか確認
        if (decks.length && decks[0].kind == "pokemonOfficial") {
          //ヒットしているため、公式用ローディングを表示するのと、自動的にデッキ詳細画面へ遷移する
          const deckCode = _deckCondition.name;
          setItems([]);
          setPokekaOfficialDeckLoading(deckCode);

          setTimeout(async () => {
            //自動的に遷移する
            API.navigation.navigate("デッキ詳細", {
              item: {
                code: deckCode,
                genre: 1, //ポケモンであるため
                user: 0, //データベース上に存在しない作者のため、ここでは0にする
                user_name: "ポケモン公式",
                // regulation_id:1001, //ポケカ公式ではスタンダードレギュレーションのみ //ポケカ公式ではこの時点ではレギュレーションを識別しない
                // regulation_name: 'スタンダード',
              },
            });
          }, 1000);
        } else {
          setPokekaOfficialDeckLoading("");
          setItems([...decks]);
        }
      })();
    }, [deckCondition]),
  );

  const uniqueStyles = StyleSheet.create({
    searchBox: {
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: "black",
      marginBottom: 10,
      padding: 5,
      overflow: "hidden",
    },
    img_wrapper: {
      overflow: "hidden",
      aspectRatio: 1,
      width: "100%",
      marginBottom: 5,
      borderRadius: 15,
    },
    thumbnail: {
      aspectRatio: 1,
      resizeMode: "cover",
      transform: [{ scale: 1.8 }, { translateY: 18 }],
    },
    item_wrapper: {
      marginRight: "4%",
      marginLeft: "4%",
      maxWidth: "25.5%",
    },
    scrollView: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexGrow: 0,
      marginBottom: 5,
    },
    cat_box: {
      height: 25,
      borderColor: "#e4e4e4",
      borderWidth: 1,
      borderRadius: 20,
      marginRight: 6,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 10,
    },
    cat_box_active: {
      height: 25,
      backgroundColor: "rgba(184,42,42,1)",
      borderColor: "rgba(184,42,42,1)",
      borderWidth: 1,
      borderRadius: 20,
      borderWidth: 1,
      borderRadius: 20,
      marginRight: 6,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 10,
    },
    cat: {
      textAlign: "center",
      color: "#848484",
      fontSize: 14,
      lineHeight: 24,
    },
    cat_active: {
      textAlign: "center",
      color: "#ffffff",
      fontSize: 14,
      lineHeight: 24,
    },
    noRegisterText: {
      fontWeight: "bold",
      fontSize: 15,
    },
    collectionName: {
      fontSize: 13,
      fontWeight: "bold",
      marginBottom: 2,
    },
    complete: {
      fontSize: 11,
      textAlign: "center",
      color: MycaAPI.style.ThemeColor,
      fontWeight: "bold",
    },
    ownedRatio: {
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
    },
    version: {
      textAlign: "center",
      opacity: 0.4,
      fontSize: 11,
      marginTop: 2,
    },
    officialMarkWrapper: {
      backgroundColor: "white",
      top: 3,
      right: 3,
      height: 22,
      width: 22,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
    },
    officialMark: {
      width: 13,
      height: 13,
    },
    regName: {
      fontSize: 10,
      fontWeight: "bold",
    },
  });

  const renderItem = useCallback(
    ({ item, index }) => (
      <DeckTopIcon deckData={item} navigateDetail={openModal} API={API} />
    ),
    [Items],
  );

  const openModal = async (item) => {
    const { id: deck, user: creator, code, regulation } = item;

    const response = await API.getDeckItems(
      {
        deck,
        user: UserData.id,
        creator,
        code,
      },
      true,
    );

    const genreData = genreList.find((e) => e.id == item.genre);

    setSelectItem({
      ...item,
      genreData,
    });

    let modifiedItems = [];

    //regulation_groupが0〜1種類しかない場合や、コナン、ホロライブについては必要ない
    if (
      new Set(response.items.map((e) => e.regulation_group).filter((e) => e))
        .size < 2 ||
      [12001, 20001].includes(regulation)
    ) {
      modifiedItems = [...response.items];
    } else {
      response.items.forEach((item, index) => {
        if (
          item.regulation_group_name &&
          (index === 0 ||
            (item.regulation_group_name !==
              previousRegulationGroupName.current &&
              (regulation != 16001 || item.regulation_group == 16010)))
        ) {
          let zoneName =
            regulation == 16001 && item.regulation_group == 16001
              ? "オーダーデッキ"
              : regulation == 16001 && item.regulation_group == 16010
                ? "メインデッキ"
                : item.regulation_group_name;

          modifiedItems.push({
            id: zoneName,
            isShow: true,
            cardimage: genreData?.single_genre_image,
            name: zoneName,
          });
        }
        modifiedItems.push(item);
        previousRegulationGroupName.current = item.regulation_group_name;
      });
    }

    setDeckData({ ...response, items: modifiedItems });
    setModalVisible(true); //デッキデータ渡した方がいい？
  };

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  const picker_style = {
    inputIOS: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 0,
      marginBottom: 10,
    },
    inputAndroid: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 5,
      marginBottom: 10,
    },

    //カード詳細モーダル
    overlay: {
      zIndex: 100,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: "12%",
      justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0,0.5)",
    },
    modalContent: {
      backgroundColor: "white",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      height: "40%",
      width: "100%",
      alignItems: "center",
      // shadowColor: "rgba(0,0,0,1)",
      // shadowOffset: {
      //   width: 3,
      //   height: 0
      // },
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: "rgb(132, 132, 132)",
    },
    modalDiv: {
      width: "100%",
      backgroundColor: "#fff",
    },

    //表示するカード
    listFrame: {
      marginLeft: "5%",
      marginRight: "5%",
      width: "90%",
      height: "70%",
      marginTop: 20,
      borderWidth: 1,
      padding: 5,
      borderColor: "rgb(132, 132, 132)",
    },
    cardFrame: {
      width: "12%",
      marginRight: "0.5%",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 5,
    },
    img: {
      width: "100%",
      aspectRatio: 0.71,
    },
    requireCountFrame: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      aspectRatio: 1,
      width: 20,
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: 9999,
      justifyContent: "center",
      alignItems: "center",
    },
    requireCount: {
      width: 17,
      color: "white",
      textAlign: "center",
      fontSize: 12,
      fontWeight: "bold",
    },

    genreOverlay: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    overlayRequireCountFrame: {
      ...StyleSheet.absoluteFillObject,
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlayRequireName: {
      color: "white",
      textAlign: "center",
      fontSize: 8,
      fontWeight: "bold",
    },
  };

  const renderCardItem = useCallback(
    ({ item }) => {
      if (item.isShow) {
        return (
          <View style={[picker_style.cardFrame, { position: "relative" }]}>
            <ImageBackground
              source={{ uri: item.cardimage }}
              resizeMode="contain"
              style={picker_style.img}
            >
              <View style={picker_style.genreOverlay} />
            </ImageBackground>
            <View style={picker_style.overlayRequireCountFrame}>
              <Text style={picker_style.overlayRequireName}>{item.name}</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View style={picker_style.cardFrame}>
            <Image
              source={{
                uri: API.getImageUrl(item.genre, "item", item.cardimage),
              }}
              style={picker_style.img}
            />
            <View style={picker_style.requireCountFrame}>
              <Text style={picker_style.requireCount}>{item.item_count}</Text>
            </View>
          </View>
        );
      }
    },
    [Items],
  );

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      {isModalVisible && (
        <View style={picker_style.overlay}>
          <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
            <View style={{ flex: 1, width: "100%" }}></View>
          </TouchableWithoutFeedback>
          <View style={picker_style.modalContent}>
            <View style={picker_style.modalDiv}></View>
            <View style={picker_style.listFrame}>
              <FlatList
                numColumns={8}
                data={deckData?.items}
                keyExtractor={keyExtractor}
                renderItem={renderCardItem}
              />
            </View>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <MycaButton
                paddingLeft={20}
                paddingRight={20}
                color="red"
                width={170}
                marginRight={10}
                onPress={() => {
                  setModalVisible(false);
                  navigation.navigate("デッキ詳細", { item: selectItem });
                }}
              >
                詳細を見る
              </MycaButton>
              <MycaButton
                color="gray"
                width={170}
                onPress={() => setModalVisible(false)}
              >
                閉じる
              </MycaButton>
            </View>
          </View>
        </View>
      )}

      <View style={styles.image4ColumnRow}>
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={uniqueStyles.scrollView}
        >
          <TouchableOpacity
            onPress={() =>
              navigation.navigate({ name: "デッキ一覧", params: {} })
            }
            style={
              !deckCondition.genre
                ? uniqueStyles.cat_box_active
                : uniqueStyles.cat_box
            }
          >
            <Text
              style={
                !deckCondition.genre
                  ? uniqueStyles.cat_active
                  : uniqueStyles.cat
              }
            >
              一覧
            </Text>
          </TouchableOpacity>
          {genreList.map((genre, i) => (
            <TouchableOpacity
              key={i}
              onPress={() =>
                navigation.navigate({
                  name: "デッキ一覧",
                  params: { condition: { genre: genre.id } },
                })
              }
              style={
                deckCondition.genre == genre.id
                  ? uniqueStyles.cat_box_active
                  : uniqueStyles.cat_box
              }
            >
              <Text
                style={
                  deckCondition.genre == genre.id
                    ? uniqueStyles.cat_active
                    : uniqueStyles.cat
                }
              >
                {genre.display_name}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>

        {CurrentMenu[0].top == "探す" && (
          <>
            <TextInput
              placeholder="カード名またはデッキコードで検索"
              style={uniqueStyles.searchBox}
              value={deckCondition.name}
              onChangeText={(newValue) => {
                const _deckCondition = { ...deckCondition };
                _deckCondition.name = newValue;
                setDeckCondition({ ..._deckCondition });
              }}
            />
            <RNPickerSelect
              items={regulationList.map((e) => ({ ...e, color: "black" }))}
              placeholder={{
                label: `レギュレーションを選択`,
                value: 0,
              }}
              onValueChange={(newValue) => {
                let _deckCondition = { ...deckCondition };
                _deckCondition.regulation = parseInt(newValue);
                setDeckCondition({ ..._deckCondition });
              }}
              value={deckCondition.regulation}
              style={picker_style}
              Icon={() => {
                return Platform.OS === "ios" ? (
                  <AntDesign
                    name="caretdown"
                    size={15}
                    color="gray"
                    style={{
                      width: 400, //IOSでタッチに反応させるため
                      textAlign: "right",
                      marginRight: 7,
                      lineHeight: 25,
                      // opacity: 0,
                    }}
                  />
                ) : null;
              }}
            />
          </>
        )}

        {Boolean(Items.length) && (
          <FlatList
            numColumns={2}
            data={Items}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReachedThreshold={1}
            style={{ flex: 1 }}
          />
        )}

        {Boolean(pokekaOfficialDeckLoading) && (
          <View style={{ position: "absolute", top: "42%", width: "100%" }}>
            <Text style={{ textAlign: "center", fontWeight: "bold" }}>
              ポケモン公式デッキを検索しています
            </Text>
            <Text style={{ textAlign: "center", fontWeight: "bold" }}>
              デッキコード：{pokekaOfficialDeckLoading}
            </Text>
            <ActivityIndicator size="large" color="#b82a2a" />
          </View>
        )}

        {!Items.length && CurrentMenu[0].top == "一覧" && waitForMoment && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Text style={uniqueStyles.noRegisterText}>
              デッキがまだ登録されていません
            </Text>
            <MycaButton
              color="red"
              onPress={() =>
                API.NavigateMenu(CurrentGenre, CurrentMenu, "デッキ", "作る")
              }
            >
              デッキを作る
            </MycaButton>
            <MycaButton
              color="red"
              onPress={() =>
                API.NavigateMenu(CurrentGenre, CurrentMenu, "デッキ", "探す")
              }
            >
              デッキを探す
            </MycaButton>
          </View>
        )}
      </View>
    </View>
  );
}

//デッキの詳細スクリーン
export function DeckDetailScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  const [MyItems, setMyItems] = useState([]); //アイテムの所有情報を格納する変数
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数

  const [DetailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報

  const [settingModalInfo, setSettingModalInfo] = useState({
    isShow: false,
  });

  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  const [displaySetting, setDisplaySetting] = useState(
    //表示方法
    "全表示",
  );

  const [thisDeck, setThisDeck] = useState({}); //このデッキの情報

  const displayTypes = [
    { label: "一覧表示", value: "cardList" },
    { label: "タイル表示", value: "card" },
    { label: "所有数表示", value: "list" },
    { label: "レアリティ", value: "rarity" },
  ];
  const [displayType, setDisplayType] = useState(displayTypes[0].value);
  const [detailVisible, setDetailVisible] = useState({});
  const [deckData, setDeckData] = useState(); //デッキの詳細情報を格納する変数
  const [cardListDeckData, setCardListDeckData] = useState({
    displayReg: false,
    item: [],
  }); //カードリスト形式の表示で使用するデッキ詳細情報を格納する変数
  const previousRegulationGroupName = useRef(null);

  //レギュレーショングループの選択肢など
  const [regulationGroup, setRegulationGroup] = useState({
    options: [],
  });

  const [thisDeckCardType, setThisDeckCardType] = useState();

  //所有数を変える関数v2
  const setNumber = (id, number) => {
    if (UserData) {
      let NewItems = [...Items];
      NewItems.find((item) => item.id == id).number =
        parseInt(NewItems.find((item) => item.id == id).number) + number;
      NewItems.find((item) => item.id == id).total_number =
        parseInt(NewItems.find((item) => item.id == id).total_number) + number;

      setItems([...NewItems]);

      //データベースの方も変える
      API.registerItem(
        {
          user: UserData.id,
          item: id,
        },
        {
          is_detail: 0, //簡易登録であるため
          number,
        },
      ).then((response) => {
        //念のため個数を反映させる
        NewItems.find((item) => item.id == id).total_number =
          response.total_count;
        NewItems.find((item) => item.id == id).number = response.easy_count;

        setItems([...NewItems]);
      });
    } else {
      API.NavigateToNeedSignUp();
    }
  };

  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;
      setUserData(_UserData);

      //情報を取得する
      const {
        id: deck,
        user: creator,
        code,
        regulation,
        genreData,
      } = route.params?.item;

      const response = await API.getDeckItems({
        deck,
        user: _UserData.id,
        creator,
        code,
      });

      setDeckData({
        ...response,
      });

      processItems([...response.items]);

      //情報を合体させる
      let _thisDeck = {
        ...Object.assign(route.params?.item, {
          //ここでポケカ公式デッキの場合のコンプリート率を結合させるかもしれん
          genreData,
        }),
      };
      setThisDeck(_thisDeck);

      //アイテムをセットする
      setItems([...response.items]);

      //レギュレーショングループの情報を取得する
      if (regulation) {
        const _regulationGroups = await API.getRegulationGroups(regulation);

        setRegulationGroup({
          options: [..._regulationGroups],
        });

        setDisplayCondition({ regulation_group: _regulationGroups[0].id });
      }

      //デッキカードタイプを取得
      setThisDeckCardType(
        API.constant.deckCardTypes.find(
          (e) =>
            e.genre == _thisDeck.genre || e.regulation == _thisDeck.regulation,
        ),
      );
    })();
  }, [route.params?.item]);

  const processItems = (responseItems) => {
    let modifiedItems = [];
    let hasRegulationGroups = false;

    //regulation_groupが0〜1種類しかない場合や、コナンについては必要ない
    if (
      new Set(responseItems.map((e) => e.regulation_group).filter((e) => e))
        .size < 2 ||
      [12001, 20001].includes(route.params?.item?.regulation)
    ) {
      modifiedItems = [...responseItems];
    } else {
      responseItems.forEach((item, index) => {
        if (item.regulation_group_name) {
          if (
            index === 0 ||
            (item.regulation_group_name !==
              previousRegulationGroupName.current &&
              (route.params?.item?.regulation != 16001 ||
                item.regulation_group_name == "メイン"))
          ) {
            //プロ野球の場合、最初にオーダーデッキ

            let zoneName =
              route.params?.item?.regulation == 16001 &&
              item.regulation_group_name == "先発"
                ? "オーダーデッキ"
                : route.params?.item?.regulation == 16001 &&
                    item.regulation_group_name == "メイン"
                  ? "メインデッキ"
                  : item.regulation_group_name;

            modifiedItems.push({
              name: zoneName,
              items: [item],
            });
          } else {
            modifiedItems[modifiedItems.length - 1].items.push(item);
          }
          previousRegulationGroupName.current = item.regulation_group_name;
          hasRegulationGroups = true; // regulation_group_nameを持つアイテムが存在
        } else {
          modifiedItems.push(item);
        }
      });
    }

    setCardListDeckData({
      displayReg: hasRegulationGroups, // regulation_group_nameが存在する場合のみtrue
      items: modifiedItems,
    });
  };

  // ユーザー情報が変更されたときとフォーカスされたときに所有データ、お気に入りデータを更新してItemsに登録する
  useFocusEffect(
    useCallback(() => {
      if (!UserData) return false;

      //所有データを取得
      API.getItem(
        { user: UserData.id },
        {
          count: 1, //ジャンル別に取得するようにしたい
          item: 1,
        },
      ).then((data) => setMyItems(data));
    }, [UserData, DetailRegisterModalInfo]),
  );

  //お気に入り情報と所有情報が変更されたときにリンクする
  useEffect(() => {
    if (Items.length) {
      setItems([
        ...Items.map((item) => {
          //所有情報
          const ThisItem = MyItems.find((e) => e.id == item.id);
          item = Object.assign(item, {
            number: parseInt(ThisItem ? ThisItem.number || 0 : 0),
            total_number: parseInt(ThisItem ? ThisItem.total_number || 0 : 0),
          });

          return item;
        }),
      ]);
    }
  }, [MyItems, Items.length, Items[0]]);

  //ソート設定が変わった時に並び替える
  useEffect(() => {
    setItems([...API.rearrangeDeckItems(Items, OrderSetting)]);
  }, [OrderSetting]);

  const ShowDetailRegisterModal = (item) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item,
    });
  };

  const showSettingModal = (mode) => {
    setSettingModalInfo({
      isShow: mode,
    });
  };

  const uniqueStyles = StyleSheet.create({
    collectionMetaNameField: {
      position: "relative",
    },
    collectionSettingButton: {
      fontSize: 40,
      opacity: 0.3,
    },
    collectionSettingButtonField: {
      position: "absolute",
      right: 10,
      top: -15,
    },
    collection_meta_name: {
      marginTop: 10,
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "center",
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    collection_meta_version: {
      textAlign: "center",
      opacity: 0.4,
      fontSize: 12,
      marginTop: 2,
    },
    collection_meta_complete: {
      fontSize: 13,
      textAlign: "center",
      color: MycaAPI.style.ThemeColor,
      fontWeight: "bold",
    },
    collection_meta_ownedRatio: {
      fontSize: 13,
      textAlign: "center",
      fontWeight: "bold",
    },
    collection_meta_code: {
      marginTop: 8,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      opacity: 0.8,
    },
    versionConfirmField: {
      position: "absolute",
      bottom: "12%",
      backgroundColor: MycaAPI.style.ThemeColor,
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2.5%",
      zIndex: 10,
    },
    addCollectionField: {
      justifyContent: "center",
      padding: "1.5%",
    },
    versionConfirmMsg: {
      color: "white",
      fontSize: 14,
      fontWeight: "bold",
      opacity: 0.9,
    },
    versionConfirmBtn: {
      color: MycaAPI.style.ThemeColor,
      backgroundColor: "white",
      borderRadius: 15,
      overflow: "hidden",
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 10,
      paddingLeft: 10,
      fontSize: 14,
      fontWeight: "bold",
    },
    addCollectionBtn: {
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 15,
      overflow: "hidden",
    },
    itemListWrapper: {
      marginTop: 13,
    },
    item_image: {
      opacity: 1,
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 100,
      opacity: 0.5,
    },
    frame: {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
    },
    modal: {
      width: "100%",
      padding: 20,
      paddingBottom: 30,
      transition: ".3s",
      backgroundColor: "white",
      overflow: "hidden",
    },
    settingButton: {
      width: "100%",
      margin: "auto",
    },
    settingButtonText: {
      textAlign: "center",
      fontSize: 17,
      lineHeight: 40,
    },
    itemCount: {
      position: "absolute",
      backgroundColor: MycaAPI.style.ThemeColor,
      color: "white",
      right: 2,
      bottom: 2,
      width: 22,
      lineHeight: 22,
      textAlign: "center",
      fontWeight: "bold",
      borderRadius: 10,
      overflow: "hidden",
    },
    scrollView: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexGrow: 0,
      marginBottom: Platform.OS === "android" ? 0 : 10,
      marginTop: Platform.OS === "android" ? 15 : 30,
      justifyContent: "space-around",
    },

    cat_box: {
      height: 22,
      borderColor: "#e4e4e4",
      borderWidth: 1,
      borderRadius: 20,
      // marginRight:6,
      paddingLeft: 5,
      paddingRight: 5,
    },
    cat_box_active: {
      height: 22,
      backgroundColor: "rgba(184,42,42,1)",
      borderColor: "rgba(184,42,42,1)",
      borderWidth: 1,
      borderRadius: 20,
      borderWidth: 1,
      borderRadius: 20,
      // marginRight:6,
      paddingLeft: 5,
      paddingRight: 5,
    },
    cat: {
      textAlign: "center",
      color: "#848484",
      fontSize: 12,
      lineHeight: 21,
    },
    cat_active: {
      textAlign: "center",
      color: "#ffffff",
      fontSize: 12,
      lineHeight: 21,
    },

    listFrame: {
      marginLeft: "5%",
      width: "90%",
      height: "100%",
      marginTop: 30,
    },
    scrollContainer: {
      paddingBottom: 100,
    },
    cardFrame: {
      width: "12%",
      marginRight: "0.5%",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 5,
    },
    img: {
      width: "100%",
      aspectRatio: 0.71,
    },
    requireCountFrame: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      aspectRatio: 1,
      width: 20,
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: 9999,
      justifyContent: "center",
      alignItems: "center",
    },
    requireCount: {
      width: 17,
      color: "white",
      textAlign: "center",
      fontSize: 12,
      fontWeight: "bold",
    },

    textInputWrapper: {
      overflow: "hidden",
      borderColor: "rgba(100,100,100,0.5)",
      borderWidth: Platform.OS == "android" ? 1 : 0,
      position: "absolute",
      bottom: Platform.OS == "android" ? 0 : -20,
      right: 5,
      width: 95,
      zIndex: 10,
      ...(Platform.OS === "android" && { height: 20 }),
      alignItems: "center",
      justifyContent: "center",
    },
    textInputWrapperAndroid: {
      overflow: "hidden",
      borderColor: "rgba(100,100,100,0.5)",
      borderWidth: 1,
      marginTop: 10,
      marginLeft: "20%",
      width: "60%",
      borderRadius: 8,
      height: 20,
      alignItems: "center",
      justifyContent: "center",
    },

    iconDiv2: {
      position: "absolute",
      top: 75,
      right: 5,
      aspectRatio: 1,
      width: 24,
      backgroundColor: "rgb(157, 157, 157)",
      borderRadius: 12,
      zIndex: 10,
    },
    displayChangeIcon: {
      color: "white",
      lineHeight: 24,
      textAlign: "center",
      fontSize: 14,
    },

    headerContainer: {
      flexDirection: "row",
      alignItems: "left",
      paddingVertical: 5,
      backgroundColor: "rgb(184, 42, 42)",
      marginBottom: 5,
    },
    headerText: {
      color: "white",
      fontSize: 12,
      fontWeight: "bold",
      marginHorizontal: 10,
    },
  });

  const picker_style = StyleSheet.create({
    inputIOS: {
      color: "#121212",
      fontSize: 10,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 0,
      paddingRight: 30,
    },
    inputAndroid: {
      lignItems: "center",
      justifyContent: "center",
      color: "#121212",
      fontSize: 10,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      padding: 0,
      borderRadius: 8,
    },
    iconContainer: {
      top: 4,
      right: 5,
    },
  });

  //所有率を算出する
  const OwnedRatio = () => {
    const sum = Items.length;
    const ownedCount = Items.filter((item) => item.total_number).length;

    return (
      <>
        {ownedCount != 0 && ownedCount == sum ? (
          <Text style={uniqueStyles.collection_meta_complete}>Complete!</Text>
        ) : (
          <Text style={uniqueStyles.collection_meta_ownedRatio}>
            {ownedCount}/{sum}
          </Text>
        )}
      </>
    );
  };

  //デッキ追加ボタン
  const addDeck = async () => {
    //デッキコードにmycaが含まれていないときはポケモン公式デッキだと判断する
    if (!thisDeck.code.includes("ml")) {
      const blueprint = {
        genre: thisDeck.genre,
        isPokeOfficial: 1,
        items: Items.map((item) => ({
          item: item.id,
          item_count: item.item_count,
          regulation_group: item.regulation_group,
        })),
      };

      navigation.navigate("デッキ設定", { blueprint });
    } else {
      const res = await API.registerDeck("register", {
        user: UserData.id,
        deck: thisDeck.id,
      });

      if (res) {
        Alert.alert("", "デッキが追加できました");

        //コレクション一覧画面に遷移
        API.NavigateMenu(CurrentGenre, CurrentMenu, "デッキ", "一覧");
      } else {
        Alert.alert("", "エラーが発生しました");
      }
    }
  };

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) =>
      Boolean(item.id) && (
        <View style={styles.imagebox}>
          <View style={styles.image4Column}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate({
                  name: "アイテム詳細",
                  params: { item },
                })
              }
            >
              <View>
                <Image
                  source={{
                    uri: API.getImageUrl(
                      item.genre_id,
                      "item",
                      item.cardimage,
                      false,
                    ),
                  }}
                  resizeMode="contain"
                  style={[styles.image4]}
                ></Image>

                <Text style={uniqueStyles.itemCount}>{item.item_count}</Text>
              </View>

              <ItemListComponent item={item} />

              <Text style={styles.カード名8}>
                ￥
                {(item.price || 0)
                  .toLocaleString("en-US", { maximumFractionDigits: 0 })
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TouchableOpacity>

            <View style={styles.rect16}>
              <TouchableOpacity
                onPress={() => setNumber(item.id, -1)}
                style={styles.del_box}
              >
                <Text style={styles.del_box_text}>-</Text>
              </TouchableOpacity>

              <View style={styles.pos_box}>
                <Text style={styles.pos_box_text}>
                  {item.number == item.total_number
                    ? item.total_number
                    : `${item.number}(${
                        parseInt(item.total_number) - parseInt(item.number)
                      })`}
                </Text>
              </View>

              <TouchableOpacity
                onPress={() => setNumber(item.id, 1)}
                style={styles.add_box}
              >
                <Text style={styles.add_box_text}>+</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      ),
    [Items],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  const renderCardItem = useCallback(
    ({ item }) => {
      return (
        <TouchableOpacity
          style={uniqueStyles.cardFrame}
          onPress={() =>
            API.navigation.navigate({
              name: "アイテム詳細",
              params: {
                item,
              },
            })
          }
        >
          <Image
            source={{
              uri: API.getImageUrl(item.genre, "item", item.cardimage),
            }}
            style={uniqueStyles.img}
          />
          <View style={uniqueStyles.requireCountFrame}>
            <Text style={uniqueStyles.requireCount}>{item.item_count}</Text>
          </View>
        </TouchableOpacity>
      );
    },
    [Items],
  );

  const renderGroupedItems = () => {
    return (
      //修正点
      <ScrollView
        style={uniqueStyles.listFrame}
        contentContainerStyle={uniqueStyles.scrollContainer}
      >
        {cardListDeckData.items.map((group, index) => (
          <View key={index}>
            <View style={uniqueStyles.headerContainer}>
              <Text style={uniqueStyles.headerText}>{group.name}</Text>
            </View>
            <FlatList
              key={index}
              numColumns={8}
              data={group.items}
              keyExtractor={(item, index) => index.toString()}
              renderItem={renderCardItem}
              scrollEnabled={false}
              contentContainerStyle={{ marginBottom: 5 }}
            />
          </View>
        ))}
      </ScrollView>
    );
  };

  const [displayCondition, setDisplayCondition] = useState({});

  const narrowDisplayCondition = (prop, value) => {
    setDisplayCondition(
      Object.assign({ ...displayCondition }, { [prop]: value }),
    );
  };

  const NarrowDisplayButton = ({ prop, data, isAll, width }) => {
    const { value, label } = data;

    return (
      <TouchableOpacity
        onPress={() => narrowDisplayCondition(prop, value)}
        style={[
          displayCondition[prop] == value || (isAll && !displayCondition[prop])
            ? uniqueStyles.cat_box_active
            : uniqueStyles.cat_box,
          { width: `${width}%` },
        ]}
      >
        <Text
          style={
            displayCondition[prop] == value ||
            (isAll && !displayCondition[prop])
              ? uniqueStyles.cat_active
              : uniqueStyles.cat
          }
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      {displayType != "cardList" && (
        <>
          <SortButton
            {...{ OrderSetting, setOrderSetting }}
            page={`${CurrentMenu[0].top == "一覧" ? "My" : ""}CollectionDetail`}
          />
          {CurrentMenu[0].top == "一覧" && (
            <DisplayModeButton
              displaySetting={displaySetting}
              setDisplaySetting={setDisplaySetting}
            />
          )}
        </>
      )}

      <DetailRegisterModal
        API={API}
        Info={DetailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
      />

      {settingModalInfo.isShow && (
        <TouchableOpacity
          style={uniqueStyles.DarkBackground}
        ></TouchableOpacity>
      )}

      <Modal
        transparent={true}
        visible={settingModalInfo.isShow}
        animationType={"fade"}
      >
        <TouchableOpacity
          style={uniqueStyles.frame}
          onPress={() => showSettingModal(false)}
        >
          <View style={uniqueStyles.modal}>
            <TouchableWithoutFeedback>
              <>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    showSettingModal(false);

                    const data = {
                      name: thisDeck.name,
                      regulation: thisDeck.regulation_name,
                      regulation_id: thisDeck.regulation,
                      code: thisDeck.code,
                      items: Items,
                      genreData: thisDeck.genreData,
                    };

                    API.navigation.navigate("デッキスクリーンショット", {
                      data,
                    });
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>
                    スクショモード
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    //このコレクションのアイテム情報を取得して作成画面へ遷移

                    const { items } = await API.getDeckItems({
                      deck: thisDeck.id,
                    });

                    const blueprint = {
                      genre: thisDeck.genre,
                      regulation: thisDeck.regulation,
                      leader_kind_condition: thisDeck.leader_kind_condition
                        ? JSON.parse(thisDeck.leader_kind_condition)
                        : {},
                      name: thisDeck.name,
                      items,
                      editMode: thisDeck.id,
                    };

                    showSettingModal(false);

                    navigation.navigate("デッキ作成", { blueprint });
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>編集</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    //このコレクションのアイテム情報を取得して作成画面へ遷移

                    const { items } = await API.getDeckItems({
                      deck: thisDeck.id,
                    });

                    const blueprint = {
                      genre: thisDeck.genre,
                      regulation: thisDeck.regulation,
                      leader_kind_condition: thisDeck.leader_kind_condition
                        ? JSON.parse(thisDeck.leader_kind_condition)
                        : {},
                      name: thisDeck.name,
                      items, //editModeを付与しないことでデッキが削除されるのを防ぐ
                    };

                    showSettingModal(false);

                    navigation.navigate("デッキ作成", { blueprint });
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>
                    コピーして編集
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    Alert.alert("", "本当に削除しますか？", [
                      {
                        text: "キャンセル",
                        onPress: () => {
                          console.log("キャンセルされました");
                        },
                        style: "cancel",
                      },
                      {
                        text: "削除",
                        onPress: async () => {
                          await API.registerDeck("delete", {
                            user: UserData.id,
                            deck: thisDeck.id,
                          });

                          Alert.alert("", "削除できました");

                          navigation.navigate({
                            name: "デッキ一覧",
                            params: {},
                          });
                        },
                        style: "destructive",
                      },
                    ]);
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>削除</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    await Clipboard.setStringAsync(thisDeck.code);
                    Alert.alert("", "コピーできました");
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>
                    コードをコピー
                  </Text>
                </TouchableOpacity>
                {thisDeck.user == UserData.id && (
                  <TouchableOpacity
                    style={uniqueStyles.settingButton}
                    onPress={async () => {
                      await API.editDeck(
                        {
                          user: UserData.id,
                          id: thisDeck.id,
                        },
                        {
                          public: !thisDeck.public,
                        },
                      );
                      setThisDeck((prev) => {
                        prev.public = !prev.public;
                        return { ...prev };
                      });
                    }}
                  >
                    <Text style={uniqueStyles.settingButtonText}>
                      {thisDeck.public ? "非公開にする" : "公開にする"}
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>

      {
        //このデッキを追加するボタン
        CurrentMenu[0].top == "探す" && !thisDeck.registered ? (
          <View
            style={[
              uniqueStyles.versionConfirmField,
              uniqueStyles.addCollectionField,
            ]}
          >
            <TouchableOpacity onPress={() => addDeck()}>
              <Text
                style={[
                  uniqueStyles.versionConfirmBtn,
                  uniqueStyles.addCollectionBtn,
                ]}
              >
                このデッキを追加
              </Text>
            </TouchableOpacity>
          </View>
        ) : null
      }

      <View style={uniqueStyles.collectionMetaNameField}>
        {thisDeck.name && (
          <Text style={uniqueStyles.collection_meta_name}>{thisDeck.name}</Text>
        )}
        {CurrentMenu[0].top == "一覧" && (
          <TouchableOpacity
            style={uniqueStyles.collectionSettingButtonField}
            onPress={() => showSettingModal(true)}
          >
            <Text style={uniqueStyles.collectionSettingButton}>…</Text>
          </TouchableOpacity>
        )}
      </View>

      {thisDeck.regulation_name && (
        <Text style={uniqueStyles.collection_meta_version}>
          {thisDeck.regulation_name}
        </Text>
      )}
      <View
        style={[
          !thisDeck.regulation_name && { marginBottom: 30 },
          { position: "relative" },
        ]}
      >
        <Text style={uniqueStyles.collection_meta_code}>
          デッキコード：{thisDeck.code}
        </Text>
        <View
          style={
            Platform.OS === "android"
              ? uniqueStyles.textInputWrapperAndroid
              : uniqueStyles.textInputWrapper
          }
        >
          <RNPickerSelect
            items={displayTypes.map((e) => ({ ...e, color: "black" }))}
            onValueChange={(value) => setDisplayType(value)}
            placeholder={{
              label: "表示形式を選択してください",
              value: "",
            }}
            value={displayType}
            style={picker_style}
            Icon={() => {
              return Platform.OS === "ios" ? (
                <AntDesign
                  name="caretdown"
                  size={15}
                  color="gray"
                  style={{
                    width: 90, //IOSでタッチに反応させるため
                    textAlign: "right",
                    marginRight: 0,
                    lineHeight: 15,
                    // opacity: 0,
                  }}
                />
              ) : null;
            }}
          />
        </View>
      </View>

      {/* ポケモン、ワンピース、ドラゴンボールの場合、レギュレーショングループではなくoption1によって分ける */}
      {displayType !== "cardList" &&
        (thisDeckCardType ? (
          <View
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={[uniqueStyles.scrollView, { marginTop: 25 }]}
          >
            <NarrowDisplayButton
              width={100 / (thisDeckCardType.options?.length + 1) - 2}
              prop={thisDeckCardType.column}
              data={{ label: "全て", value: null }}
              isAll
            />
            {thisDeckCardType.options?.map((each, i) => (
              <NarrowDisplayButton
                key={i}
                width={100 / (thisDeckCardType.options?.length + 1) - 2}
                prop={thisDeckCardType.column}
                data={each}
              />
            ))}
          </View>
        ) : (
          //遊戯王の場合、レギュレーショングループで分けられるようにする
          <View
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={uniqueStyles.scrollView}
          >
            {regulationGroup.options
              ?.filter(
                (e) => ![20001].includes(e.id), //ホロライブのテンプレート系カードは除外
              )
              .map((each, i) => (
                <NarrowDisplayButton
                  key={i}
                  width={
                    100 /
                      regulationGroup.options?.filter(
                        (e) => ![20001].includes(e.id), //ホロライブのテンプレート系カードは除外
                      ).length -
                    2
                  }
                  prop="regulation_group"
                  data={{ value: each.id, label: each.name }}
                />
              ))}
          </View>
        ))}

      {displayType === "list" || displayType === "rarity" ? (
        <DeckCard
          {...{
            navigation,
            deckData,
            setDeckData,
            UserData,
            detailVisible,
            setDetailVisible,
            displayType,
            API,
            displayCondition,
            displaySetting,
            thisDeckCardType,
            OrderSetting,
          }}
        />
      ) : displayType === "cardList" ? (
        !cardListDeckData.displayReg ? (
          <View style={uniqueStyles.listFrame}>
            <FlatList
              key={displayType}
              numColumns={8}
              data={cardListDeckData.items}
              keyExtractor={(item, index) => index.toString()}
              renderItem={renderCardItem}
            />
          </View>
        ) : (
          renderGroupedItems()
        )
      ) : (
        <View style={[styles.image4ColumnRow, uniqueStyles.itemListWrapper]}>
          <FlatList
            key={displayType}
            numColumns={3}
            data={Items.filter((item) => {
              // 上のメニューでの絞り込み
              if (
                API.constant.deckFixedProperty.includes(
                  item.option_for_deck || "",
                )
              ) {
              } else {
                for (const prop in displayCondition) {
                  if (prop == "regulation_group" && thisDeckCardType) continue;
                  if (prop == "regulation_groups") {
                    if (
                      !(displayCondition.regulation_groups || []).includes(
                        item.regulation_group,
                      )
                    ) {
                      return false;
                    }
                  } else if (
                    displayCondition[prop] &&
                    displayCondition[prop] != item[prop]
                  )
                    return false;
                }
              }
              switch (displaySetting) {
                case "所持済み":
                  if (item.total_number) {
                    return true;
                  } else {
                    return false;
                  }
                case "未所持":
                  if (!item.total_number) {
                    return true;
                  } else {
                    return false;
                  }
                default:
                  return true;
              }
            })}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
          />
          {!Items.length && (
            <View style={{ position: "absolute", top: "42%", width: "100%" }}>
              <ActivityIndicator size="large" color="#b82a2a" />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

//デッキジャンルスクリーン
export function DeckGenreScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [GenreData, setGenreData] = useState([]);

  const [drafts, setDrafts] = useState([]);

  const [regulationModalInfo, setRegulationModalInfo] = useState({
    isShow: false,
    genre: 0,
  });
  const [regulationList, setRegulationList] = useState([]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        API.getGenres().then((response) => {
          setGenreData(
            [...response].filter(
              (genre) =>
                API.version >= genre.deck_available_version &&
                genre.deck_available_version,
            ),
          );
        });

        //ここで下書きのものがあるかどうかを確認する
        const _UserData = API.user;

        if (!_UserData) API.NavigateToNeedSignUp();

        const existDrafts = await API.getDecks("draft", {
          user: _UserData.id,
        });

        setDrafts([...existDrafts]);

        //レギュレーションのリストを取得する
        const _regulationList = await API.getRegulations();
        setRegulationList([..._regulationList]);
      })();
    }, []),
  );

  const showRegulationModal = (genre) => {
    setRegulationModalInfo({
      isShow: true,
      genre,
    });
  };

  // const navigateToListScreen = () => {

  //   setDrafts([])

  //   //なぜかこうしないとクラッシュするため
  //   setTimeout(() => {
  //     API.NavigateMenu(CurrentGenre,CurrentMenu,'デッキ','一覧')
  //   },10)

  // }

  const navigateToEditScreen = async (blueprint = {}, draftId) => {
    //選択したレギュレーションを保持して作成画面に遷移
    setRegulationModalInfo({
      isShow: false,
      genre: 0,
    });

    if (draftId) {
      //下書きを載せた状態で遷移する場合
      const draft = drafts.find((e) => e.id == draftId);

      //アイテムを取得する
      const items = await API.getDeckItems({ deck: draft.id });

      //blueprintにセット
      blueprint = {
        id: draftId,
        genre: draft.genre,
        regulation: draft.regulation,
        leader_kind_condition: draft.leader_kind_condition
          ? JSON.parse(draft.leader_kind_condition)
          : {},
        items: items.items,
      };
    }

    setDrafts([]);

    //リーダー選択をする必要があったら もちろんレギュレーションをしっかり選択している時のみ
    if (blueprint.leader_kind_label && blueprint.regulation) {
      API.navigation.navigate("デッキリーダー選択", { blueprint });
    } else {
      API.navigation.navigate("デッキ作成", { blueprint });
    }
  };

  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={[thisStyles.poke_link, thisStyles.active]}>
        <TouchableOpacity onPress={() => showRegulationModal(item.id)}>
          <Image
            source={{ uri: item.single_genre_image }}
            resizeMode="contain"
            style={thisStyles.image_poke2}
          ></Image>
          <Text style={thisStyles.genreName}>{item.display_name}</Text>
        </TouchableOpacity>
      </View>
    ),
    [GenreData],
  );

  const keyExtractor = useCallback((Item, i) => i, [GenreData]);

  const thisStyles = StyleSheet.create({
    genreName: {
      textAlign: "center",
      // fontWeight: 'bold',
    },
    textBox: {
      alignItems: "center",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: 20,
      marginTop: 30,
      marginBottom: 10,
    },
    text: {
      fontWeight: "bold",
      fontSize: 12,
    },
    flexbox: {
      width: "95%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      marginTop: 10,
      paddingBottom: 20,
    },
    poke_link: {
      width: "18%",
      marginLeft: "1%",
      marginRight: "1%",
      marginBottom: 30,
      opacity: 0.5,
    },
    image_poke2: {
      width: "100%",
      aspectRatio: 1,
      marginLeft: "auto",
      marginRight: "auto",
      opacity: 1,
    },
    active: {
      opacity: 1,
    },
  });

  const [draftListModalVisible, setDraftListModalVisible] = useState(false);

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <DraftListModal
        API={API}
        drafts={drafts}
        visible={draftListModalVisible}
        setVisible={setDraftListModalVisible}
        navigateToEditScreen={navigateToEditScreen}
      />
      <RegulationModal
        navigateToEditScreen={navigateToEditScreen}
        info={regulationModalInfo}
        setInfo={setRegulationModalInfo}
        regulationList={regulationList}
      />

      <View style={thisStyles.textBox}>
        <Text style={thisStyles.boldText}>デッキ作成</Text>
        <Text style={thisStyles.text}>作成するジャンルを選んでください。</Text>
        <TouchableOpacity
          style={{
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "black",
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 10,
            paddingLeft: 10,
            overflow: "hidden",
            borderRadius: 10,
            position: "absolute",
            right: 10,
            bottom: 20,
          }}
          onPress={() => setDraftListModalVisible(true)}
        >
          <Text>下書き一覧</Text>
        </TouchableOpacity>
      </View>

      <View style={thisStyles.flexbox}>
        <FlatList
          numColumns={5}
          data={GenreData}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          style={{ height: "100%" }}
        />
      </View>
    </View>
  );
}

//リーダーや国家等選択スクリーン
export function DeckLeaderScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [optionData, setOptionData] = useState({});

  const [leaderItems, setLeaderItems] = useState([]);
  const [narrowColor, setNarrowColor] = useState("赤");

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (route?.params?.blueprint?.genre) {
          const thisFindType = await API.getOptions(
            route?.params?.blueprint?.genre,
            route?.params?.blueprint?.leader_kind_label,
          );

          setOptionData({
            ...thisFindType,
          });
        }
      })();
    }, [route?.params?.blueprint]),
  );

  //色の条件が変わった時に表示するカードを変える
  useEffect(() => {
    (async () => {
      const _condition = {
        genre: route?.params?.blueprint?.genre,
        type: "リーダー",
        option1: `%${narrowColor}%`,
      };

      const response = await API.getItem(
        {
          ..._condition,
        },
        {
          detail: 1,
        },
        {},
        {},
      );

      setLeaderItems([...response]);
    })();
  }, [narrowColor]);

  const keyExtractor = useCallback((Item, i) => i, [optionData]);

  const thisStyles = StyleSheet.create({
    textBox: {
      alignItems: "center",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: 20,
      marginTop: 30,
      marginBottom: 10,
    },
    text: {
      fontWeight: "bold",
      fontSize: 12,
    },

    container: {
      flex: 1,
      backgroundColor: "rgba(248,248,248,1)",
      position: "relative",
      alignItems: "center",
    },
    rea: {
      textAlign: "center",
      fontSize: 20,
      fontWeight: "bold",
    },
    typebox: {
      width: "23%",
      marginLeft: "1%",
      marginRight: "1%",
      marginBottom: 20,
      alignItems: "center",
    },
    cardImage: {
      width: 80,
      height: 100,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circleImage: {
      width: 50,
      height: 50,
      marginLeft: "auto",
      marginRight: "auto",
    },
    imageRow: {
      flex: 1,
      display: "wrap",
      flexDirection: "row",
      marginTop: 15,
      marginLeft: 10,
      marginRight: 10,
      paddingBottom: "23%",
    },
    タイプ名: {
      color: "rgba(2,2,2,1)",
      fontSize: 12,
      textAlign: "center",
      marginTop: 5,
      display: "flex",
    },
    narrowListContainerStyle: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 0,
      gap: 5,
      marginTop: 10,
    },
    narrowList: {
      width: "90%",
      margin: "auto",
    },

    narrowOptionWrapper: {
      gap: 4,
      height: 30,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "white",
      borderWidth: 0.5,
      borderColor: "#0000004a",
      borderRadius: 10,
      overflow: "hidden",
      padding: 5,
    },
    narrowColorString: {
      fontSize: 13,
    },
    narrowColorWrapper: {
      height: 20,
      width: 20,
      borderRadius: 10,
      overflow: "hidden",
    },
  });

  //ワンピースとドラゴンボールの時の選択肢
  const colorOptions = {
    3: [
      {
        label: "赤",
        value: "赤",
        colorCode: "#ca1212",
      },
      {
        label: "緑",
        value: "緑",
        colorCode: "#008e67",
      },
      {
        label: "青",
        value: "青",
        colorCode: "#1b76bf",
      },
      {
        label: "紫",
        value: "紫",
        colorCode: "#81247a",
      },
      {
        label: "黒",
        value: "黒",
        colorCode: "#232528",
      },
      {
        label: "黄",
        value: "黄",
        colorCode: "#c2c849",
      },
    ],
    9: [
      {
        label: "赤",
        value: "赤",
        colorCode: "#ca1212",
      },
      {
        label: "緑",
        value: "緑",
        colorCode: "#008e67",
      },
      {
        label: "青",
        value: "青",
        colorCode: "#1b76bf",
      },
      {
        label: "黄",
        value: "黄",
        colorCode: "#c2c849",
      },
    ],
  };

  const renderItem = useCallback(({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        //このシリーズで縛っている状態で編集画面に遷移する

        const blueprint = {
          genre: route?.params?.blueprint?.genre,
          regulation: route?.params?.blueprint?.regulation,
          leader_kind_condition: {
            //DBとワンピースではoption1をみて色をしぼる
            [optionData.columnOnItems]:
              optionData.matchPolicy == "partial"
                ? `%${item.value}%`
                : item.value,
          },
          items: [], //こちらではitemsは特に入れない
        };

        API.navigation.navigate("デッキ作成", { blueprint });
      }}
      style={thisStyles.typebox}
    >
      {item.img ? (
        <>
          <Image
            source={item.img} //imgがhttpsから始まる場合はコノハウィングをつけない
            resizeMode="contain"
            // style={thisStyles.image}
            style={thisStyles.cardImage}
          ></Image>
          <Text style={thisStyles.タイプ名}>{item.name}</Text>
        </>
      ) : (
        <>
          <Text style={thisStyles.rea}>{item.name}</Text>
        </>
      )}
    </TouchableOpacity>
  ));

  const renderLeaderItem = useCallback(({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        //このリーダーをdeckItemsに追加した状態で編集画面に遷移する

        //このリーダーの色を判断する
        const thisLeaderColors = item.option1.split("/");

        const blueprint = {
          genre: route?.params?.blueprint?.genre,
          regulation: route?.params?.blueprint?.regulation,
          leader_kind_condition: {
            //DBとワンピースではoption1をみて色をしぼる
            option1: thisLeaderColors,
          },
          items: [
            Object.assign(item, {
              item_count: 1,
              regulation_group: 0,
              option_for_deck: "リーダー", //option_for_deckを使うことで、これがリーダーであることを示す
            }),
          ],
        };

        API.navigation.navigate("デッキ作成", { blueprint });
      }}
      style={thisStyles.typebox}
    >
      <Image
        source={{ uri: API.getImageUrl(item.genre_id, "item", item.cardimage) }}
        resizeMode="contain"
        // style={styles.image4}
        style={thisStyles.cardImage}
      ></Image>
    </TouchableOpacity>
  ));

  const renderNarrowList = useCallback(({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        setNarrowColor(item.value);
      }}
      style={[
        thisStyles.narrowOptionWrapper,
        narrowColor == item.value && { borderWidth: 1, borderColor: "black" },
      ]}
    >
      <View
        style={[
          thisStyles.narrowColorWrapper,
          { backgroundColor: item.colorCode },
        ]}
      ></View>
      <Text style={thisStyles.narrowColorString}>{item.label}</Text>
    </TouchableOpacity>
  ));

  return (
    <View style={thisStyles.container}>
      {/* <TopMenu {...{navigation, CurrentGenre, CurrentMenu}} /> */}
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <View style={thisStyles.textBox}>
        <Text style={thisStyles.boldText}>デッキ作成</Text>
        {Object.keys(colorOptions).includes(
          String(route?.params?.blueprint?.genre),
        ) ? (
          <Text style={thisStyles.text}>リーダーを選んでください。</Text>
        ) : (
          <Text style={thisStyles.text}>
            {route?.params?.blueprint?.leader_kind_label}を選んでください。
          </Text>
        )}
      </View>

      {
        //ワンピースとドラゴンボールの時のみ
        Object.keys(colorOptions).includes(
          String(route?.params?.blueprint?.genre),
        ) ? (
          <>
            <View style={thisStyles.narrowList}>
              <FlatList
                contentContainerStyle={thisStyles.narrowListContainerStyle}
                data={colorOptions[route?.params?.blueprint?.genre]}
                horizontal={true}
                renderItem={renderNarrowList}
              />
            </View>
            <View style={thisStyles.imageRow}>
              <FlatList
                data={leaderItems}
                numColumns={4}
                keyExtractor={keyExtractor}
                renderItem={renderLeaderItem}
              />
            </View>
          </>
        ) : (
          <>
            <View style={thisStyles.imageRow}>
              <FlatList
                data={(optionData.options || [])
                  .filter((e) => e.img)
                  .filter((e) => e.value && e.value != "-")}
                numColumns={4}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
              />
            </View>
          </>
        )
      }
    </View>
  );
}

export function DeckMakeScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  const [saveDraftModalInfo, setSaveDraftModalInfo] = useState({
    isShow: false,
  });

  const [UserData, setUserData] = useState(null);
  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  const [Items, setItems] = useState([]);
  const [deckItems, setDeckItems] = useState([]); //deckItemsに関してはアイテムIDと共にその数item_countも保持させる

  const [deckId, setDeckId] = useState(0); //現在編集しているデッキのID

  const dropAreaLayout = useRef(null);

  const [PageNo, setPageNo] = useState(1); //現在のページ数

  const [InputValue, setInputValue] = useState({}); //絞り込みの入力内容

  const FlatRef = useRef(null);

  const [modalVisible, setModalVisible] = useState(false); //詳細モーダル表示
  const [modalData, setModalData] = useState(null);

  //下に表示するアイテム（選択肢）のコンディション
  const [condition, setCondition] = useState({});

  //上に表示するアイテム（デッキ候補）のコンディション
  const [displayCondition, setDisplayCondition] = useState({});

  //絞り込みボタンの表示/非表示
  const [searchOptionButtonShow, setSearchOptionButtonShow] = useState(false);

  //ソートボタンの表示/非表示
  const [sortButtonShow, setSortButtonShow] = useState(false);

  //アイテムの種類 //デッキなのでcardのみ
  const [itemKind, setItemKind] = useState("card"); //card

  //レギュレーショングループ
  const [regulationGroup, setRegulationGroup] = useState({
    selected: 0,
    options: [],
  });

  //ジャンル情報（テンプレート系カードのプレースホルダー用）
  const [genres, setGenres] = useState([]);

  //並び替えと絞り込みが共存しないように

  const showSortButton = () => {
    if (!sortButtonShow && searchOptionButtonShow) {
      setSearchOptionButtonShow(false);
    }

    setSortButtonShow(!sortButtonShow);
  };

  const showSearchOptionButton = () => {
    if (!searchOptionButtonShow && sortButtonShow) {
      setSortButtonShow(false);
    }

    setSearchOptionButtonShow(!searchOptionButtonShow);
  };

  //利き手の件
  const [rightleft, setRightleft] = useState("右");

  //deckcardtypeのやつ
  const [thisDeckCardType, setThisDeckCardType] = useState();

  //最初の状態を記録しておく
  const [initDeckItems, setInitDeckItems] = useState([]);

  //下書きを残すやつ
  const [isEdited, setIsEdited] = useState(false);

  //下書きを保存するかしないか
  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      const action = e.data?.action;

      //メニュー選択の場合や、変更がない場合は、無条件に通す
      if (action.type != "POP" || !isEdited) return true;

      //それ以外の場合、一旦プリベントする
      e.preventDefault();

      //モーダルの表示とともに、actionを渡す
      setSaveDraftModalInfo({
        isShow: true,
      });

      return true;
    });

    return unsubscribe;
  }, [navigation, isEdited, deckId]);

  useEffect(() => {
    (async () => {
      if (route.params?.blueprint) {
        setIsEdited(false);

        //IDをセットする
        setDeckId(route.params?.blueprint?.id);

        //レギュレーショングループを取得する（regulation: 0じゃなかったら）
        if (route.params?.blueprint?.regulation) {
          const _regulationGroups = await API.getRegulationGroups(
            route.params?.blueprint?.regulation,
          );

          //初期で選択しておくもの
          //テンプレート系カード以外のものを選択しておく
          const selected = _regulationGroups.find(
            (e) =>
              !API.constant.deckTemplate
                .map((e) => e.items.map((e1) => e1.regulation_group))
                .flat()
                .includes(e.id),
          ).id;

          setRegulationGroup({
            selected: selected,
            options: [..._regulationGroups],
          });
        }

        let _items = [];
        if (route.params?.blueprint?.items?.length) {
          _items = [...route.params?.blueprint?.items];
        } else {
          //ここでテンプレートがある場合つっこんでいく
          let thisTemplate = {};
          if (
            (thisTemplate = API.constant.deckTemplate.find(
              (e) => e.regulation == route.params?.blueprint?.regulation,
            ))
          ) {
            _items = thisTemplate.items;
          }
        }

        //initDeckItemsをセット
        setInitDeckItems([..._items]);

        setDeckItems(API.rearrangeDeckItems(_items));

        //ユーザーデータも取得しておく
        const _UserData = API.user;
        setUserData(_UserData);

        setThisDeckCardType(
          API.constant.deckCardTypes.find(
            (e) =>
              e.genre == route.params?.blueprint?.genre ||
              e.regulation == route.params?.blueprint?.regulation,
          ),
        );

        //ジャンルデータを取得
        const _genres = await API.getGenres();

        setGenres(_genres);
      }
    })();
  }, [route.params?.blueprint]);

  //goBackする前に警告を出す
  useEffect(() => {
    // navigation.addListener('f', (e) => {
    //   if (!isEdited) {
    //     //変更していない場合はそのまま通す
    //     return
    //   }
    //   //そうでない場合は、一旦イベントを無効化する
    //   e.preventDefault()
    //   //警告
    //   Alert.alert(
    //     '',
    //     '下書きに保存しますか？',
    //     [
    //       {
    //         text: "保存する", style: 'default', onPress: () => {
    //           console.log("下書きを消さずに、goBackする")
    //           navigation.dispatch(e.data.action)
    //         }
    //       },
    //       {
    //         text: "キャンセル", style: 'cancel', onPress: () => {
    //           console.log("何もしない")
    //         }
    //       },
    //       {
    //         text: '保存せずに終了',
    //         style: 'destructive',
    //         onPress: () => {
    //           console.log("下書きを削除して、goBackする")
    //           navigation.dispatch(e.data.action)
    //         }
    //           ,
    //       },
    //     ]
    //   );
    // })
  }, [navigation, isEdited]);

  useEffect(() => {
    (async () => {
      let _condition = {
        genre: route.params?.blueprint?.genre,
      };
      const { init } = API.constant.GenreList.find(
        (e) => e.genre == route.params?.blueprint?.genre,
      );
      if (route.params?.blueprint?.genre != 6) {
        _condition.displaytype1 =
          itemKind == "card" ? `${init}カード` : `${init}ボックス`;
      }

      //regulationGroupの設定が変わった時にconditionを作り直す
      //同時に、上の絞り込みも変える
      //国家や色などを選択する系のレギュレーションだったらここで絞り込みができるようにする
      if (regulationGroup.selected) {
        const thisRegulationGroup = regulationGroup.options.find(
          (group) => regulationGroup.selected == group.id,
        );

        const id_for_regulation = thisRegulationGroup.id_for_regulation;

        _condition.id_for_regulation = id_for_regulation;

        const _displayCondition = { ...displayCondition };
        _displayCondition.regulation_group = regulationGroup.selected;

        setDisplayCondition({ ..._displayCondition });
      }

      //国家や色などで制約をかける
      if (route.params?.blueprint?.leader_kind_condition) {
        _condition = Object.assign(
          _condition,
          route.params?.blueprint?.leader_kind_condition,
        );
      }

      setCondition({
        ..._condition,
      });

      //InputValueも付随してリセットする
      setInputValue({});
    })();
  }, [regulationGroup]);

  useEffect(() => {
    (async () => {
      setPageNo(0);
    })();
  }, [condition, OrderSetting]);

  //ページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    if (condition.genre) {
      (async () => {
        if (PageNo == 0) {
          setPageNo(1);
          return false;
        }

        if (
          condition &&
          (!route.params?.blueprint?.regulation || condition.id_for_regulation)
        ) {
          let NewItems = await API.getItem(
            condition,
            { detail: 1 },
            {
              cur: PageNo,
              per: 18,
            },
            {
              column: OrderSetting.column,
              mode: OrderSetting.mode,
            },
          );

          if (!NewItems.length) {
            if (PageNo == 1) {
              setItems([]);
            }
            return false;
          }

          //現在のItemsにそのまま結合していいのか判断
          if (PageNo == 1 && Items.length) {
            setItems([...NewItems]);

            // FlatRef.current?.scrollToIndex({
            //   index:0,
            //   animated:true,
            // })
          } else {
            //ページ番号がかわったらカードデータを再取得する
            setItems([
              ...Items.concat(NewItems).filter(
                (v, i, a) => i == a.findIndex((item) => item.id == v.id),
              ),
            ]);
          }
        }
      })();
    }
  }, [PageNo]);

  const collectionMakeStyles = StyleSheet.create({
    scrollView: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexGrow: 0,
      marginTop: 8,
      marginBottom: 10,
      justifyContent: "space-around",
    },
    cat_box: {
      height: 22,
      borderColor: "#e4e4e4",
      borderWidth: 1,
      borderRadius: 20,
      // marginRight:6,
      paddingLeft: 5,
      paddingRight: 5,
    },
    cat_box_active: {
      height: 22,
      backgroundColor: "rgba(184,42,42,1)",
      borderColor: "rgba(184,42,42,1)",
      borderWidth: 1,
      borderRadius: 20,
      borderWidth: 1,
      borderRadius: 20,
      // marginRight:6,
      paddingLeft: 5,
      paddingRight: 5,
    },
    cat: {
      textAlign: "center",
      color: "#848484",
      fontSize: 12,
      lineHeight: 21,
    },
    cat_active: {
      textAlign: "center",
      color: "#ffffff",
      fontSize: 12,
      lineHeight: 21,
    },

    //dropエリア
    box: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexWrap: "wrap",
      alignContent: "flex-start",
      paddingLeft: "auto",
      paddingRight: "auto",
      marginBottom: 7,
      // ...platformStyles,
      flex: 1,
      padding: 5,
      // borderColor: 'rgb(132, 132, 132)',
      // borderWidth: 1,
    },

    //検索欄
    searchBox: {
      flexDirection: "row",
      backgroundColor: "#acacac",
      alignItems: "center",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    input_box: {
      // width:'65%',
      flex: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginRight: 5,
      height: 30,
    },
    searchBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 8,
      marginRight: 5,
      width: 60,
      height: 30,
    },
    rightleft: {
      width: 30,
    },
    searchText: {
      fontSize: 12,
      color: "rgb(157, 157, 157)",
      fontWeight: "bold",
    },

    //検索カード表示エリア
    scrollViewBox: {
      flexDirection: "row",
      marginLeft: "2%",
      marginBottom: 50,
      height: 150,
    },
    cardContainer: {
      // width: '100%'
    },

    collectionCard: {
      width: "22%",
      marginRight: "3%",
      justifyContent: "center",
      // elevation: 5,
    },
    card: {
      width: 75,
      marginRight: 10,
      justifyContent: "center",
      paddingTop: 5,
    },
    image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    text: {
      fontSize: 10,
      color: "rgb(132, 132, 132)",
    },
    rarity: {
      fontSize: 8,
      color: "rgb(132, 132, 132)",
    },

    //追加
    addBtn: {
      backgroundColor: "rgb(184, 42, 42)",
      position: "absolute",
      width: 30,
      height: 30,
      borderRadius: 40,
      top: 2,
      zIndex: 10000,
    },
    addText: {
      color: "white",
      lineHeight: 27,
      textAlign: "center",
      fontSize: 23,
      fontWeight: "bold",
    },

    //削除ボタン
    deleteBtn: {
      backgroundColor: "rgb(184, 42, 42)",
      position: "absolute",
      width: 30,
      height: 30,
      borderRadius: 40,
      top: -5,
      zIndex: 10000,
    },
    deleteText: {
      color: "white",
      lineHeight: 27,
      textAlign: "center",
      fontSize: 23,
      fontWeight: "bold",
    },
    changeText: {
      color: "white",
      lineHeight: 29,
      textAlign: "center",
      fontSize: 17,
      fontWeight: "bold",
    },

    //登録
    registerBox: {
      position: "absolute",
      width: "96%",
      marginLeft: "2%",
      bottom: "13%",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: MycaStyle.ThemeColor,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 10,
      borderRadius: 10,
      justifyContent: "space-between",
    },
    registerText: {
      fontSize: 15,
      color: "rgb(255, 255, 255)",
    },
    registerBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 25,
      paddingRight: 25,
      borderRadius: 15,
      marginRight: 8,
    },
    registerBtnText: {
      fontSize: 12,
      fontWeight: "bold",
      color: "rgb(184, 42, 42)",
    },
    rect16: {
      width: "100%",
      height: 25,
      borderWidth: 1,
      borderColor: "rgba(210,210,210,1)",
      borderRadius: 2,
      marginTop: 5,
      backgroundColor: "white",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "hidden",
      marginTop: 5,
      justifyContent: "center",
    },
    del_box: {
      width: "20%",
      textAlign: "center",
    },
    del_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },
    pos_box: {
      width: "60%",
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderColor: "rgba(210,210,210,1)",
    },
    pos_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },
    add_box: {
      width: "20%",
    },
    add_box_text: {
      lineHeight: 22,
      textAlign: "center",
    },
  });

  //カード選択フィールドの大きさ調整
  const [isExpanded, setIsExpanded] = useState(false);

  const DragAndDropCard = useCallback(
    ({ item }) => {
      // const position = useRef(new Animated.ValueXY()).current;

      // const resetPosition = () => { //ポジションを戻す
      //   Animated.spring(position, {
      //     toValue: { x: 0, y: 0 },
      //     useNativeDriver: false
      //   }).start();
      // };

      // const panResponder = useRef(
      //   PanResponder.create({
      //     onStartShouldSetPanResponder: () => {
      //       // if (Platform.OS === 'android') {　※いったんドラックを無効
      //       //   return false;
      //       // }
      //       // setScrollEnabled(false); // ScrollViewのスクロールを無効化
      //       // return true;
      //       return false;
      //     },

      //     onMoveShouldSetPanResponder: (evt, gestureState) => {
      //       // if (Platform.OS === 'android') { ※いったんドラックを無効
      //       //   return false;
      //       // }
      //       // const dragThreshold = 5; // 5px動かしたらドラッグとみなす
      //       // const isDrag = Math.abs(gestureState.dx) > dragThreshold || Math.abs(gestureState.dy) > dragThreshold;
      //       // if (isDrag) {
      //       //   setScrollEnabled(false);
      //       // }
      //       // return isDrag;
      //       return false;
      //     },

      //     onPanResponderMove: Animated.event(
      //       [
      //         null,
      //         {
      //           dx: position.x,
      //           dy: position.y,
      //         },
      //       ],
      //       { useNativeDriver: false }
      //     ),
      //     onPanResponderRelease: (e, gestureState) => {
      //       if (isDropArea(gestureState)) {
      //         const isExistingInCollection = collection.some(data => data.id === item.id);

      //         if (!isExistingInCollection) { // コレクションに追加し、Items から削除
      //           setCollection(prev => [...prev, item]);
      //           setItems(currentItems => currentItems.filter(data => data.id !== item.id));
      //         }else{
      //           resetPosition();
      //         }
      //       } else {
      //         deleteCollection(item.id);
      //       }
      //       setScrollEnabled(true);
      //     },
      //   })
      // ).current;

      // const isDropArea = (gestureState) => {
      //   const { pageY, moveY } = gestureState;
      //   const { y, height } = dropAreaLayout.current;

      //   return (pageY >= y && pageY <= y + height) || (moveY >= y && moveY <= y + height);
      // };

      return (
        // <Animated.View
        //   style={[
        //     collectionMakeStyles.card,
        //     {
        //       transform: position.getTranslateTransform(),
        //       width: screenWidth * 0.22,
        //     },
        //   ]}
        //   {...panResponder.panHandlers}
        // >
        <View
          style={[
            collectionMakeStyles.card,
            { width: Dimensions.get("window").width < 390 ? "22.3%" : "17.3%" },
          ]}
        >
          <TouchableOpacity
            onPress={() => setDeckItem(item, "add")}
            style={[
              collectionMakeStyles.addBtn,
              rightleft == "右" ? { right: -3 } : { left: -3 },
            ]}
          >
            <Text style={collectionMakeStyles.addText}>+</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => showModal(item)}>
            <Image
              source={{
                uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
              }}
              resizeMode="contain"
              style={collectionMakeStyles.image}
            />
          </TouchableOpacity>
        </View>
      );
    },
    [Items, rightleft, deckItems],
  );

  const showModal = (item) => {
    setModalData(
      Object.assign(
        {
          regulation_group: item.regulation_group || regulationGroup.selected, //レギュレーショングループ情報も入れておく
        },
        item,
      ),
    );
    setModalVisible(true);
  };

  //一気にカードを追加できないようにする デッキの場合は主にバリデートのため
  const addingNow = useRef(false);

  //下書き保存する
  //ここの_deckItemsはdeckItemsステート変数に変更を反映する手前の仮の値
  const saveDraft = async (_deckItems, isCheckpoint) => {
    if (route.params?.blueprint?.genre && UserData) {
      const blueprint = {
        id: deckId, //二回目の保存以降は、有効な値が入る
        genre: route.params?.blueprint?.genre,
        regulation: route.params?.blueprint?.regulation, //レギュレーションIDを入れておく
        name: route.params?.blueprint?.name, //あれば名前も入れておく
        leader_kind_condition: route.params?.blueprint?.leader_kind_condition, //縛る系のやつのコンディションをオブジェクトそのまま送信する
        items: _deckItems.map((item) => ({
          item: item.id,
          item_count: item.item_count,
          regulation_group: item.regulation_group,
          option_for_deck: item.option_for_deck,
        })), //item_count情報まで保持させる
      };

      //データベースに送信
      const res = await API.createDeck(
        {
          mode: isCheckpoint ? "checkpoint" : "draft",
          user: UserData.id,
        },
        {
          ...blueprint,
        },
      );

      return res; //エラーや成功など全て含めて返す
    } else {
      return {};
    }
  };

  //deckItemsのスクロールRef
  const deckItemsScrollRef = useRef();

  const editedItemInfo = useRef({ id: 0 });

  const setDeckItem = async (
    item,
    mode,
    specificRegulationGroup,
    replaceItem,
  ) => {
    //mode = add or remove

    let editedItemId = 0;

    //値渡しにするため
    item = { ...item };

    if (addingNow.current) return false;
    addingNow.current = true;

    //あえて非同期でaddingNowをfalseにする
    setTimeout(() => (addingNow.current = false), 500);

    //デッキの場合、変更するのはdeckItemsのみ
    let _deckItems = JSON.parse(JSON.stringify(deckItems));

    const targetRegulationGroup =
      specificRegulationGroup ||
      item.regulation_group ||
      regulationGroup.selected;

    //すでにフィールドに追加されているか確認 現在選択されているレギュレーショングループに限る
    let thisItem = _deckItems.find(
      (each) =>
        each.id == item.id && targetRegulationGroup == each.regulation_group,
    );

    if (mode == "add") {
      editedItemId = item.id;

      //追加モードの時
      if (thisItem) {
        thisItem.item_count++;
      } else {
        //テンプレート系カードだった場合、そこにはめる
        let insteadTargetIndex = -1;

        if (
          (insteadTargetIndex = _deckItems.findIndex(
            (e) => e.id == 0 && e.regulation_group == targetRegulationGroup,
          )) > -1
        ) {
          //置換する

          const originalInsteadTarget = { ..._deckItems[insteadTargetIndex] };

          const nextCount = originalInsteadTarget.item_count - 1;

          _deckItems[insteadTargetIndex] = Object.assign(
            { ...originalInsteadTarget },
            item,
            { item_count: 1 },
          );

          if (nextCount) {
            _deckItems.splice(
              insteadTargetIndex + 1,
              0,
              Object.assign(
                { ...originalInsteadTarget },
                {
                  item_count: nextCount,
                },
              ),
            );
          }

          narrowDisplayCondition(
            "regulation_group",
            regulationGroup.options.find(
              (e) =>
                !API.constant.deckTemplate
                  .map((e) => e.items.map((e1) => e1.regulation_group))
                  .flat()
                  .includes(e.id),
            ).id,
          );
        }

        //テンプレート系カードだったら普通には追加しない
        else if (
          !API.constant.deckTemplate
            .map((e) => e.items.map((e1) => e1.regulation_group))
            .flat()
            .includes(targetRegulationGroup)
        ) {
          _deckItems.push(
            Object.assign(item, {
              item_count: 1,
              regulation_group: targetRegulationGroup,
            }),
          );

          //一番下まで自動スクロールさせる
          // if (deckItemsScrollRef.current) {
          //   deckItemsScrollRef.current.scrollTo({ x: 0, y: 1000, animated: true });
          // }
        }
      }
    } else if (mode == "remove" && thisItem) {
      //削除モードの時
      if (thisItem.item_count == 1) {
        _deckItems = _deckItems.filter(
          (each) =>
            each.id != item.id ||
            regulationGroup.selected != each.regulation_group,
        );
      } else {
        thisItem.item_count--;
      }
    } else if (mode == "delete" && thisItem) {
      //全削除モードの時
      _deckItems = _deckItems.filter(
        (each) =>
          each.id != item.id || targetRegulationGroup != each.regulation_group,
      );
    } else if (mode == "backToTemplate" && thisItem) {
      //空のテンプレートカードがある場合、削除するだけにする
      let emptyTemplateItem = {};
      if (
        (emptyTemplateItem = _deckItems.find(
          (e) => e.id == 0 && e.regulation_group == thisItem.regulation_group,
        ))
      ) {
        emptyTemplateItem.item_count++;
        _deckItems = _deckItems.filter(
          (each) =>
            each.id != item.id ||
            targetRegulationGroup != each.regulation_group,
        );
      } else {
        thisItem = Object.assign(thisItem, {
          id: 0,
          item_count: 1,
        });
      }
    } else if (mode == "changeOtherItem" && replaceItem) {
      editedItemId = replaceItem?.id;

      //テンプレート系カードで、他のレアリティを表示するモーダルから選択された時
      if (thisItem) {
        //上の方から選択された場合

        //リプレースする
        //念の為、同じアイテムがないか確認して、あったら何もしない
        if (
          _deckItems.find(
            (e) =>
              e.id == replaceItem.id &&
              e.regulation_group == targetRegulationGroup,
          )
        )
          return false;
        thisItem = Object.assign(thisItem, replaceItem, { item_count: 1 });

        //他のレギュレーショングループを選択させる
        narrowDisplayCondition(
          "regulation_group",
          regulationGroup.options.find(
            (e) =>
              !API.constant.deckTemplate
                .map((e) => e.items.map((e1) => e1.regulation_group))
                .flat()
                .includes(e.id),
          ).id,
        );
      } else {
        //ない場合、普通に追加する

        addingNow.current = false;
        setDeckItem(replaceItem, "add");
        return false; //一旦ここで処理を終わらせる
      }
    }

    //バリデートを行いつつ下書き保存
    const response = await saveDraft(_deckItems);
    let errorContent;

    if (response.ok) {
      //OKだったら普通にdeckItemsに書き込む

      //変更済みにする
      setIsEdited(true);

      //作成できたデッキIDをセットする
      setDeckId(response.id);

      //ここで適切な順番に並び替える

      editedItemInfo.current = {
        id: editedItemId,
      };

      setDeckItems([...API.rearrangeDeckItems(_deckItems)]);

      // setTimeout(() => {
      //   editedItemInfo.current = {
      //     id: 0,
      //   }
      // },1000)
    } else if ((errorContent = response.error)) {
      //エラーだったら原因を提示する

      //レアリティ選択モーダルは閉じる
      // setModalVisible(false)

      switch (errorContent.kind) {
        case "same_name_id_too_many": //同名カードが多すぎる時
          if (modalVisible) {
            alert(
              "すでに上限枚数まで登録されています。\n他のレアリティの枚数を変更後カードを追加してください。",
            );
          } else {
            alert(`このカードの上限枚数は${errorContent.maximum}枚です。`);
          }

          highlightItems({ same_name_id: errorContent.cause.same_name_id });
          break;

        case "combination_restriction": //コンビ制約
          alert(`コンビ制約です`);

          highlightItems({ same_name_id: errorContent.cause.same_name_id });
          break;

        case "dendo_point": //殿堂ポイントが超えていた時
          if (route.params?.blueprint?.genre == 1) {
            alert("殿堂ポイントが上限に達しました。");
          } else {
            alert("カード数が上限に達しています。");
          }

          highlightItems({ dendo_point: 1 });
          break;

        case "color_variation": //ガンダムなどの、カラバリ問題
          alert("色の種類は2種類までとなっています");

          highlightItems({ id: item.id }); //今追加しようとしているカードをハイライト

          break;
      }
    }
  };

  //指定された条件に沿っているカードをハイライトする
  //highlightConditionは基本的にプロパティ名とその値の条件を指定している
  //highlightConditionは基本的にAND条件となる
  const highlightItems = (highlightCondition) => {
    let _deckItems = JSON.parse(JSON.stringify(deckItems));

    let alreadySetNarrowDisplayCondition = false;

    _deckItems = _deckItems.map((item) => {
      for (const prop in highlightCondition) {
        switch (
          prop //特殊な場合は特殊な対応を行う
        ) {
          case "dendo_point": //殿堂ポイントだった場合は殿堂ポイントがあるカードをハイライトする（ポケモンのみ）
            if (!item.option4) return item;
            break;

          case "regulation_group": //レギュレーショングループをマーキングするモードだったら
            //テンプレートで使うようなものだったらレギュレーショングループを選択させない
            if (
              !API.constant.deckTemplate
                .map((e) => e.items.map((e1) => e1.regulation_group))
                .flat()
                .includes(highlightCondition[prop]) &&
              !alreadySetNarrowDisplayCondition
            ) {
              narrowDisplayCondition(
                "regulation_group",
                highlightCondition[prop],
              );
              alreadySetNarrowDisplayCondition = true;
            }

          default:
            if (
              Array.isArray(highlightCondition[prop]) &&
              highlightCondition[prop].includes(item[prop])
            )
              return item;

            if (item[prop] != highlightCondition[prop]) return item;
        }
      }

      //全部生き残った場合、該当のアイテムとしてハイライトプロパティを追加する
      return Object.assign(item, {
        highlight: 1,
      });
    });

    setDeckItems([..._deckItems]);

    //1秒後に解除する
    setTimeout(() => {
      let _deckItems = [...deckItems];
      _deckItems = _deckItems.map((item) => {
        delete item.highlight;
        return item;
      });
      setDeckItems([..._deckItems]);
    }, 1000);
  };

  const highlightStyle = StyleSheet.create({
    item: {
      backgroundColor: "#ff000042",
    },
    itemImage: {
      opacity: 0.5,
      backgroundColor: "#ff000042",
    },
  });

  //登録を行う処理
  const navigateToSettingScreen = async () => {
    //まずチェックポイントモードで下書き保存してみて、エラーを吐かないかチェック

    const response = await saveDraft(deckItems, true);
    let errorContent;

    if (response.ok) {
      //OKだったら普通に次の画面へ遷移する

      //blueprintをここでまとめる
      const blueprint = {
        id: response.id,
        genre: route.params?.blueprint?.genre,
        regulation: route.params?.blueprint?.regulation,
        name: route.params?.blueprint?.name,
        leader_kind_condition: route.params?.blueprint?.leader_kind_condition,
        editMode: route.params?.blueprint?.editMode, //編集モードでやっているかを判断するため
        items: deckItems.map((item) => ({
          item: item.id,
          item_count: item.item_count,
          regulation_group: item.regulation_group,
          option_for_deck: item.option_for_deck,
        })), //item_count情報まで保持させる
      };

      navigation.navigate("デッキ設定", { blueprint });
    } else if ((errorContent = response.error)) {
      //エラーだったら原因を提示する

      //エラーの種類によってエフェクトなどを出し分ける

      switch (errorContent.kind) {
        case "same_name_id_too_many": //同名カードが多すぎる時
          alert(
            "すでに上限枚数まで登録されています。\n他のレアリティの枚数を変更後カードを追加してください。",
          );
          highlightItems({ same_name_id: errorContent.cause.same_name_id });
          break;

        case "combination_restriction": //コンビ制約
          alert(`コンビ制約です`);

          highlightItems({ same_name_id: errorContent.cause.same_name_id });
          break;

        case "dendo_point": //殿堂ポイントが超えていた時
          if (route.params?.blueprint?.genre == 1) {
            alert("殿堂ポイントが上限に達しました。");
          } else {
            alert("カード数が上限に達しています。");
          }

          highlightItems({ dendo_point: 1 });
          break;

        case "color_variation": //ガンダムなどの、カラバリ問題
          alert("色の種類は2種類までとなっています");

          break;

        case "duema_gr_wrong_count": //デュエマの超GR問題だった場合
          alert("超GRゾーンは0枚か12枚でなければなりません");
          highlightItems({
            regulation_group: errorContent.cause.regulation_group,
          });
          break;

        case "regulation_group_item_too_few": //レギュレーショングループあたりの枚数が少なすぎた場合
          if (
            API.constant.deckTemplate
              .map((e) => e.items.map((e1) => e1.regulation_group))
              .flat()
              .includes(errorContent.cause.regulation_group)
          ) {
            alert(
              `${errorContent.cause.regulation_group_name.replace("カード", "")}カードの枚数が足りていません`,
            );
          } else {
            alert(
              `${errorContent.cause.regulation_group_name.replace("デッキ", "")}デッキの枚数が${errorContent.cause.right}枚を下回っています`,
            );
          }

          highlightItems({
            regulation_group: errorContent.cause.regulation_group,
          });

          break;

        //テンプレート系カードの場合、「パートナーカードの枚数が足りていません」

        case "regulation_group_item_too_many": //レギュレーショングループあたりの枚数が多すぎた場合
          alert(
            `${errorContent.cause.regulation_group_name.replace("デッキ", "")}デッキの枚数が${errorContent.cause.right}枚を超えています`,
          );
          highlightItems({
            regulation_group: errorContent.cause.regulation_group,
          });
          break;

        case "no_item_selected": //アイテム数が0だった場合
          alert("デッキの枚数が不足しています。");
          break;
      }
    }
  };

  const narrowDisplayCondition = (prop, value) => {
    //regulation_groupの絞り込みだった場合、同時に選択中のレギュレーショングループも変更する
    if (prop == "regulation_group") {
      setRegulationGroup({
        ...Object.assign(regulationGroup, {
          selected: value,
        }),
      });
    }

    //ポケモンの場合、option1もコンディションに入れる
    if (route.params?.blueprint?.genre == 1 && prop == "option1" && value) {
      setCondition({
        ...Object.assign(condition, {
          option1: value,
        }),
      });
    }

    setDisplayCondition(
      Object.assign({ ...displayCondition }, { [prop]: value }),
    );
  };

  const getDeckItemsCount = (_condition) => {
    return deckItems.reduce((curSum, item) => {
      if (
        API.constant.deckFixedProperty.includes(item.option_for_deck || "") &&
        (route.params?.blueprint?.regulation != 16001 ||
          !_condition.regulation_groups)
      )
        return curSum;
      if (item.id == 0) return curSum;
      for (const prop in _condition) {
        if (prop == "regulation_groups") {
          if (
            !_condition.regulation_groups ||
            !(
              (_condition.regulation_groups || []).includes(
                item.regulation_group,
              ) && item.id != 0
            )
          )
            return curSum;
        } else if (_condition[prop] && _condition[prop] != item[prop])
          return curSum;
      }
      return (curSum += item.item_count);
    }, 0);
  };

  const NarrowDisplayButton = ({ prop, data, isAll, width }) => {
    const { value, label } = data;

    return (
      <TouchableOpacity
        onPress={() => narrowDisplayCondition(prop, value)}
        style={[
          displayCondition[prop] == value || (isAll && !displayCondition[prop])
            ? collectionMakeStyles.cat_box_active
            : collectionMakeStyles.cat_box,
          { width: `${width}%` },
        ]}
      >
        <Text
          style={[
            displayCondition[prop] == value ||
            (isAll && !displayCondition[prop])
              ? collectionMakeStyles.cat_active
              : collectionMakeStyles.cat,
            { fontSize: 9 + (4 * width) / 100 },
          ]}
        >
          {label.slice(0, 4)}
          {label.length > 4 && "…"} ({getDeckItemsCount({ [prop]: value })})
        </Text>
      </TouchableOpacity>
    );
  };

  const templateStyles = StyleSheet.create({
    placeholderWrapper: {
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000090",
    },
    placeholderText: {
      fontSize: 12,
      fontWeight: "bold",
      color: "white",
      textAlign: "center",
    },
  });

  return (
    <View style={[styles.container]}>
      {/* <KeyboardAvoidingView style={{height:"100%"}} behavior="padding"> */}

      {/* <TopMenu {...{navigation, CurrentGenre, CurrentMenu}} /> */}
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <DetailModalForDeck
        modalData={modalData}
        setModalData={setModalData}
        visible={modalVisible}
        setModalVisible={setModalVisible}
        deckItems={deckItems}
        setDeckItem={setDeckItem}
        API={API}
      />

      <SaveDraftModal
        API={API}
        info={saveDraftModalInfo}
        setInfo={setSaveDraftModalInfo}
        saveDraft={saveDraft}
        initDeckItems={initDeckItems}
      />

      {/* ポケモン、ワンピース、ドラゴンボールの場合、レギュレーショングループではなくoption1によって分ける */}
      {thisDeckCardType ? (
        <View
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={collectionMakeStyles.scrollView}
        >
          <NarrowDisplayButton
            width={100 / (thisDeckCardType.options?.length + 1) - 2}
            prop={thisDeckCardType.column}
            data={{ label: "全て", value: null }}
            isAll
          />
          {thisDeckCardType.options?.map((each, i) => (
            <NarrowDisplayButton
              width={100 / (thisDeckCardType.options?.length + 1) - 2}
              prop={thisDeckCardType.column}
              data={each}
            />
          ))}
        </View>
      ) : (
        //その他の場合、レギュレーショングループで分けられるようにする
        <View
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={collectionMakeStyles.scrollView}
        >
          {regulationGroup.options
            ?.filter(
              (e) => ![20001].includes(e.id), //ホロライブのテンプレート系カードは除外
            )
            .map((each, i) => (
              <NarrowDisplayButton
                width={
                  100 /
                    regulationGroup.options?.filter(
                      (e) => ![20001].includes(e.id), //ホロライブのテンプレート系カードは除外
                    ).length -
                  2
                }
                prop="regulation_group"
                data={{ value: each.id, label: each.name }}
              />
            ))}
        </View>
      )}

      <View
        style={[collectionMakeStyles.box, { paddingBottom: 50, paddingTop: 0 }]}
        ref={dropAreaLayout}
        onLayout={(event) => {
          dropAreaLayout.current = event.nativeEvent.layout;
        }}
      >
        <ScrollView
          contentContainerStyle={[
            collectionMakeStyles.cardContainer,
            {
              flexDirection: "row",
              flexWrap: "wrap",
              paddingTop: 5,
              rowGap: 15,
              paddingBottom: 50,
            },
          ]}
          style={{ height: "100%" }}
          ref={deckItemsScrollRef}
        >
          {deckItems
            //テンプレート系カードを展開する
            .map((each) =>
              each.id == 0 && each.regulation_group
                ? Array.from({ length: each.item_count }, (e) => each)
                : each,
            )
            .flat()
            .filter((item) => {
              //このアイテムのregulation_groupが0だったら通す（リーダーorレギュレーションに関係ない場合を考慮して）

              if (
                API.constant.deckFixedProperty.includes(
                  item.option_for_deck || "",
                ) &&
                route.params?.blueprint?.regulation != 16001
              ) {
              } else {
                //ここでdeckItemsとして表示するやつを絞り込む
                //displayConditionはAND条件
                for (const prop in displayCondition) {
                  if (prop == "regulation_group" && thisDeckCardType) continue;
                  if (prop == "regulation_groups") {
                    if (
                      !(displayCondition.regulation_groups || []).includes(
                        item.regulation_group,
                      )
                    ) {
                      return false;
                    }
                  } else if (
                    displayCondition[prop] &&
                    displayCondition[prop] != item[prop]
                  )
                    return false;
                }
              }

              return true;
            })
            .map((item, i) => {
              //自動スクロール
              if (
                editedItemInfo.current?.id == item.id &&
                deckItemsScrollRef.current
              ) {
                const rowHeight = 154;
                const targetItemRowIndex = Math.floor(i / 4);

                setTimeout(() => {
                  deckItemsScrollRef.current.scrollTo({
                    x: 0,
                    y: rowHeight * targetItemRowIndex,
                    animated: true,
                  });
                  editedItemInfo.current = {
                    id: 0,
                  };
                }, 200);
              }

              return (
                <View
                  style={[
                    collectionMakeStyles.collectionCard,
                    item.highlight && highlightStyle.item,
                    { justifyContent: "flex-start" },
                  ]}
                >
                  {/* ここのボタンではアイテムの全削除を行う */}
                  {!API.constant.deckFixedProperty.includes(
                    item.option_for_deck || "",
                  ) ? (
                    <TouchableOpacity
                      onPress={() => setDeckItem(item, "delete")}
                      style={[
                        collectionMakeStyles.deleteBtn,
                        rightleft == "右" ? { right: -3 } : { left: -3 },
                      ]}
                    >
                      <Text style={collectionMakeStyles.deleteText}>×</Text>
                    </TouchableOpacity>
                  ) : item.regulation_group && item.id != 0 ? (
                    <TouchableOpacity
                      onPress={() => {
                        //チェンジするボタン
                        //一度このアイテムを削除して下から選ばせる
                        narrowDisplayCondition(
                          "regulation_group",
                          item.regulation_group,
                        );
                        setDeckItem(item, "backToTemplate");
                      }}
                      style={[
                        collectionMakeStyles.deleteBtn,
                        rightleft == "右" ? { right: -3 } : { left: -3 },
                      ]}
                    >
                      <FontAwesome6
                        style={collectionMakeStyles.changeText}
                        name="rotate"
                        color="white"
                      />
                    </TouchableOpacity>
                  ) : (
                    <></>
                  )}

                  {/* その場で選択させる系、テンプレート系カード */}
                  {API.constant.deckFixedProperty.includes(
                    item.option_for_deck || "",
                  ) && item.regulation_group ? (
                    <TouchableOpacity
                      onPress={() => {
                        if (
                          regulationGroup.selected != item.regulation_group &&
                          item.id == 0
                        ) {
                          narrowDisplayCondition(
                            "regulation_group",
                            item.regulation_group,
                          );
                        } else if (item.id != 0) {
                          //すでにある時、他のレアリティ表示モードにする

                          showModal(item);
                        }
                      }}
                    >
                      <Image
                        source={{
                          uri:
                            item.id == 0
                              ? (
                                  genres.find(
                                    (genre) =>
                                      genre.id ==
                                      route.params?.blueprint?.genre,
                                  ) || {}
                                ).single_genre_image
                              : API.getImageUrl(
                                  item.genre_id,
                                  "item",
                                  item.cardimage,
                                ),
                        }}
                        resizeMode="contain"
                        style={[collectionMakeStyles.image, item.id == 0]}
                      ></Image>
                      {item.id == 0 && (
                        <View style={templateStyles.placeholderWrapper}>
                          {regulationGroup.selected != item.regulation_group ? (
                            <Text style={templateStyles.placeholderText}>
                              {item.option_for_deck}
                              {"\n"}カードを選ぶ
                            </Text>
                          ) : (
                            <Text style={templateStyles.placeholderText}>
                              下から{"\n"}選択してください
                            </Text>
                          )}
                        </View>
                      )}
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => showModal(item)}>
                      <Image
                        source={{
                          uri: API.getImageUrl(
                            item.genre_id,
                            "item",
                            item.cardimage,
                          ),
                        }}
                        resizeMode="contain"
                        style={[
                          collectionMakeStyles.image,
                          item.highlight && highlightStyle.itemImage,
                        ]}
                      ></Image>
                      <View
                        style={{
                          position: "absolute",
                          bottom: 3,
                          width: "100%",
                          left: 0,
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: MycaAPI.style.ThemeColor,
                            width: 22,
                            aspectRatio: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            borderRadius: 11,
                          }}
                        >
                          <Text
                            style={{
                              color: "white",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {item.item_count}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )}
                  <View
                    style={[
                      collectionMakeStyles.rect16,
                      {
                        borderWidth: 0,
                        justifyContent: "space-between",
                        backgroundColor: "transparent",
                      },
                    ]}
                  >
                    {/* 特殊カードのレンダリング */}
                    {API.constant.deckFixedProperty.includes(
                      item.option_for_deck || "",
                    ) /* リーダーの場合は専用のレンダリングを施す */ ? (
                      <Text style={{ margin: "auto", lineHeight: 23 }}>
                        {item.option_for_deck}
                      </Text>
                    ) : (
                      <>
                        <TouchableOpacity
                          onPress={() => setDeckItem(item, "remove")}
                          style={[
                            {
                              width: 25,
                              height: "100%",
                            },
                          ]}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            source={require("../assets/minusButton.png")}
                          ></Image>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => setDeckItem(item, "add")}
                          style={[
                            {
                              width: 25,
                              height: "100%",
                            },
                          ]}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            source={require("../assets/plusButton.png")}
                          ></Image>
                        </TouchableOpacity>
                      </>
                    )}
                  </View>
                </View>
              );
            })}
        </ScrollView>
      </View>
      <KeyboardAvoidingView
        behavior="padding"
        style={{ width: "100%", bottom: 70 }}
        enabled={!isExpanded}
      >
        <View style={[collectionMakeStyles.searchBox]}>
          <TextInput
            placeholder={itemKind == "card" ? `カード名` : `ボックス名`}
            onChangeText={(newValue) => {
              const newInputValue = { ...InputValue };

              const label = itemKind == "card" ? `カード名` : `ボックス名`;

              newInputValue[label] = {
                value: newValue,
                prop: "name",
              };

              setInputValue({
                ...newInputValue,
              });
            }}
            style={collectionMakeStyles.input_box}
            value={
              InputValue[itemKind == "card" ? `カード名` : `ボックス名`]?.value
            }
          ></TextInput>
          <SortButton
            page="Collection"
            OrderSetting={OrderSetting}
            setOrderSetting={setOrderSetting}
            isShow={sortButtonShow}
            switchIsShow={showSortButton}
          />
          <TouchableOpacity
            style={collectionMakeStyles.searchBtn}
            onPress={() => {
              if (condition.genre != 6) {
                showSearchOptionButton();
              } else {
                Alert.alert("", "このジャンルでは絞り込み機能は使えません");
              }
            }}
          >
            <Text style={collectionMakeStyles.searchText}>
              {searchOptionButtonShow ? "閉じる" : "絞り込み"}
            </Text>
          </TouchableOpacity>
          <ItemSearchOptionList
            API={API}
            itemKind={itemKind}
            setItemKind={setItemKind}
            InputValue={InputValue}
            setInputValue={setInputValue}
            condition={condition}
            setCondition={setCondition}
            isShow={searchOptionButtonShow}
            setIsShow={setSearchOptionButtonShow}
            isDeck={true}
          />

          <TouchableOpacity
            style={[
              collectionMakeStyles.searchBtn,
              collectionMakeStyles.rightleft,
            ]}
            onPress={() => setRightleft(rightleft == "右" ? "左" : "右")}
          >
            <MaterialCommunityIcons
              style={styles.searchText}
              size={20}
              name="arrow-left-right-bold"
              color="rgb(157, 157, 157)"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: 50,
              height: 25,
              backgroundColor: "#acacac",
              position: "absolute",
              right: 0,
              top: -25,
              zIndex: 10,
              overflow: "hidden",
              justifyContent: "center",
              borderRadius: 10,
              borderBottomLeftRadius: isExpanded ? 10 : 0,
              borderBottomRightRadius: isExpanded ? 10 : 0,
              borderTopLeftRadius: isExpanded ? 0 : 10,
              borderTopRightRadius: isExpanded ? 0 : 10,
              transform: [
                {
                  rotate: isExpanded ? "180deg" : "0deg",
                },
              ],
            }}
            onPress={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <Image
              resizeMode="contain"
              style={{ width: "100%", height: "80%" }}
              source={require("../assets/grayArrow2.png")}
            ></Image>
          </TouchableOpacity>
        </View>

        {/* <View style={[collectionMakeStyles.scrollViewBox,{ marginBottom:60,backgroundColor: 'white',height:isExpanded ? 330 : 120 }]}> */}
        <View
          style={[
            collectionMakeStyles.scrollViewBox,
            {
              marginBottom: 60,
              backgroundColor: "white",
              height: isExpanded ? Dimensions.get("window").height - 450 : 120,
            },
          ]}
        >
          <FlatList
            contentContainerStyle={[collectionMakeStyles.cardContainer]}
            keyboardShouldPersistTaps="handled"
            horizontal={false}
            showsHorizontalScrollIndicator={false}
            numColumns={Dimensions.get("window").width < 390 ? 4 : 5}
            data={Items}
            ref={FlatRef}
            onEndReached={() => {
              setPageNo(PageNo + 1);
            }}
            onEndReachedThreshold={1}
            renderItem={(
              { item, index }, //現在選択されているレギュレーショングループだけ表示する
            ) => <DragAndDropCard key={index} item={item} />}
          />
        </View>
      </KeyboardAvoidingView>

      <View style={collectionMakeStyles.registerBox}>
        <Text style={collectionMakeStyles.registerText}>
          {String(getDeckItemsCount({})).padStart(3, "0")}枚
        </Text>
        <TouchableOpacity
          style={collectionMakeStyles.registerBtn}
          onPress={() => navigateToSettingScreen()}
        >
          <Text style={collectionMakeStyles.registerBtnText}>登録</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

//デッキ設定スクリーン
export function DeckSettingScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [blueprint, setBlueprint] = useState({});
  const [UserData, setUserData] = useState(null);
  // const [topCard, setTopCard] = useState(null);
  const [deckItems, setDeckItems] = useState([]);

  const [regulationList, setRegulationList] = useState([]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setBlueprint({
          ...route.params?.blueprint,
          public: 0,
          package_color: "01",
        });

        //アイテムの画像を取得する
        let _deckItems = route.params?.blueprint?.items;
        _deckItems = await API.getItem(
          {
            id: _deckItems.map((e) => e.item),
          },
          {
            detail: 1,
          },
          {},
          {
            column: "ml_deck_order",
            mode: "DESC",
          },
        );

        setDeckItems([..._deckItems]);

        const _UserData = API.user;
        if (!_UserData) {
          API.NavigateToNeedSignUp();
        } else {
          setUserData(_UserData);
        }

        if (route.params?.blueprint?.isPokeOfficial) {
          //レギュレーションリストを取得する
          const _regulationList = await API.getRegulations();
          setRegulationList([
            ..._regulationList
              .filter((regulation) => regulation.genre == 1)
              .map((regulation) => ({
                label: `${regulation.name}`,
                value: regulation.id,
              })),
          ]);
        }
      })();
    }, [route.params?.blueprint]),
  );

  const uniqueStyles = StyleSheet.create({
    wrapper: {
      // alignItems: 'left',
      width: "90%",
      marginLeft: "5%",
      marginBottom: 100,
    },
    h4: {
      fontSize: 17,
      fontWeight: "bold",
      marginTop: 20,
      marginBottom: 5,
    },
    nameInputField: {
      height: 35,
      padding: 5,
      width: "100%",
      backgroundColor: "white",
      borderColor: "black",
      borderWidth: 0.2,
      borderRadius: 5,
      overflow: "hidden",
      marginBottom: 10,
    },
    imageWrapper: {
      width: 200,
      height: 200,
      borderColor: "black",
      borderWidth: 0.5,
      justifyContent: "center",
    },
    publicRadioContainer: {
      gap: 30,
      flexDirection: "row",
      marginTop: 8,
      marginBottom: 20,
    },

    scrollViewBox: {
      flexDirection: "row",
      marginLeft: "2%",
      width: "96%",
    },
    cardContainer: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // width: '100%',
    },
    image: {
      // marginRight: 5,
      aspectRatio: 0.71,
      width: 60,
    },
    selectTopText: {
      fontSize: 12,
      fontWeight: "bold",
      color: "white",
      position: "absolute",
      top: 35,
      left: 8,
    },
    shadowStyle: {
      backgroundColor: "black",
      opacity: 0.5,
    },
    deckCaseColorContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 0,
    },
    colorCircle: {
      width: 34,
      height: 34,
      borderRadius: 17,
      margin: 5,
    },
    selectText: {
      fontSize: 8,
      fontWeight: "bold",
      color: "white",
      position: "absolute",
      top: 10,
      left: 15,
    },
    selectColorText: {
      fontSize: 10,
      fontWeight: "bold",
      color: "white",
      position: "absolute",
      top: 5,
      left: 8,
    },
    backBlack: {
      marginRight: 5,
      backgroundColor: "black",
    },
    backGray: {
      backgroundColor: "gray",
      aspectRatio: 0.71,
      width: 60,
    },

    packageChoiceImage: {
      width: "100%",
      height: "100%",
    },
  });

  //トップカード１
  const renderThumbnailItem1 = useCallback(
    ({ item }) => {
      const isSelected = blueprint.thumbnail_item == item.id;

      const select = () => {
        setBlueprint((prevBlueprint) => ({
          ...prevBlueprint,
          thumbnail_item: isSelected ? null : item.id,
        }));
      };

      return (
        <View>
          <TouchableOpacity
            onPress={() => select()}
            style={uniqueStyles.backBlack}
          >
            <Image
              source={{
                uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
              }}
              resizeMode="contain"
              style={[
                uniqueStyles.image,
                isSelected && uniqueStyles.shadowStyle,
              ]}
            />
            {isSelected && (
              <Text style={uniqueStyles.selectTopText}>選択済み</Text>
            )}
          </TouchableOpacity>
        </View>
      );
    },
    [blueprint],
  );

  //トップカード２
  const renderThumbnailItem2 = useCallback(
    ({ item }) => {
      const isSelected = blueprint.thumbnail_item2 == item.id;

      const select = () => {
        setBlueprint((prevBlueprint) => ({
          ...prevBlueprint,
          thumbnail_item2: isSelected ? null : item.id,
        }));
      };

      return (
        <View>
          <TouchableOpacity
            onPress={() => select()}
            style={uniqueStyles.backBlack}
          >
            {item.id ? (
              <Image
                source={{
                  uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
                }}
                resizeMode="contain"
                style={[
                  uniqueStyles.image,
                  isSelected && uniqueStyles.shadowStyle,
                ]}
              />
            ) : (
              <View style={uniqueStyles.backGray}>
                {!isSelected && (
                  <Text style={uniqueStyles.selectTopText}>画像なし</Text>
                )}
              </View>
            )}
            {isSelected && (
              <Text style={uniqueStyles.selectTopText}>選択済み</Text>
            )}
          </TouchableOpacity>
        </View>
      );
    },
    [blueprint],
  );

  //パッケージからの選択肢
  const renderColorOption = useCallback(
    ({ item }) => {
      const isSelected = blueprint.package_color == item.value;

      const select = () => {
        setBlueprint((prevBlueprint) => ({
          ...prevBlueprint,
          package_color: item.value,
        }));
      };

      return (
        <TouchableOpacity onPress={() => select()}>
          <View style={uniqueStyles.colorCircle} key={item.value}>
            <Image source={item.src} style={uniqueStyles.packageChoiceImage} />
            {isSelected && (
              <Text style={[uniqueStyles.selectColorText]}>選択{"\n"}済み</Text>
            )}
          </View>
        </TouchableOpacity>
      );
    },
    [blueprint],
  );

  const keyExtractor = (item, index) => `${item.id_for_deck}-${index}`;

  const createDeck = async () => {
    if (!blueprint.name) {
      // Alert.alert("","デッキ名を入力してください")
      blueprint.name = "newデッキ";
    }

    if (blueprint.isPokeOfficial && !blueprint.regulation) {
      Alert.alert("", "レギュレーションを選択してください");
    } else {
      const { ok, code } = await API.createDeck(
        {
          user: UserData.id,
          mode: "final",
        },
        {
          ...blueprint,
        },
      );

      if (ok) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: "デッキ作成完了",
              params: {
                blueprint,
                code,
              },
            },
          ],
        });
      }
    }
  };

  const picker_style = {
    inputIOS: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 0,
      marginBottom: 10,
    },
    inputAndroid: {
      color: "#121212",
      fontSize: 13,
      width: "100%",
      borderWidth: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginTop: 5,
      marginBottom: 10,
    },
  };

  const packageOptions = Object.keys(
    MycaAPI.loadAppImage.deck.package.choice,
  ).map((option) => ({
    value: option,
    src: MycaAPI.loadAppImage.deck.package.choice[option],
  }));

  return (
    <View style={styles.container}>
      <BottomMenu {...{ API, navigation, CurrentGenre, CurrentMenu }} />

      <ScrollView style={uniqueStyles.wrapper}>
        <Text style={uniqueStyles.h4}>デッキ名</Text>
        <TextInput
          placeholder="デッキ名を入力してください。"
          placeholderTextColor="rgb(132, 132, 132)"
          value={blueprint.name}
          style={uniqueStyles.nameInputField}
          onChangeText={(newValue) => {
            const _blueprint = { ...blueprint };
            _blueprint.name = newValue;

            setBlueprint({ ..._blueprint });
          }}
        />

        {blueprint.isPokeOfficial && (
          <>
            <Text style={uniqueStyles.h4}>レギュレーション</Text>
            <RNPickerSelect
              items={regulationList.map((e) => ({ ...e, color: "black" }))}
              placeholder={{
                label: `レギュレーションを選択`,
                value: 0,
              }}
              onValueChange={(newValue) => {
                let _blueprint = { ...blueprint };
                _blueprint.regulation = parseInt(newValue);
                setBlueprint({ ..._blueprint });
              }}
              value={blueprint.regulation}
              style={picker_style}
              Icon={() => {
                return Platform.OS === "ios" ? (
                  <AntDesign
                    name="caretdown"
                    size={15}
                    color="gray"
                    style={{
                      width: 400, //IOSでタッチに反応させるため
                      textAlign: "right",
                      marginRight: 7,
                      lineHeight: 25,
                      // opacity: 0,
                    }}
                  />
                ) : null;
              }}
            />
          </>
        )}

        <Text style={uniqueStyles.h4}>デッキトップカード画像(1枚目)</Text>
        <View style={uniqueStyles.scrollViewBox}>
          <FlatList
            contentContainerStyle={uniqueStyles.cardContainer}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            data={deckItems}
            keyExtractor={keyExtractor}
            renderItem={renderThumbnailItem1}
          />
        </View>
        <Text style={{ textAlign: "left", marginTop: 8 }}>
          ※画像を選択しない場合は自動的に選ばれます。
        </Text>

        <Text style={uniqueStyles.h4}>デッキトップカード画像(2枚目)</Text>
        <View style={uniqueStyles.scrollViewBox}>
          <FlatList
            contentContainerStyle={uniqueStyles.cardContainer}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            data={[{ id: null }, ...deckItems]}
            keyExtractor={keyExtractor}
            renderItem={renderThumbnailItem2}
          />
        </View>

        <Text style={[uniqueStyles.h4, { marginTop: 25 }]}>デッキケース色</Text>
        <FlatList
          contentContainerStyle={uniqueStyles.deckCaseColorContainer}
          horizontal={true}
          data={packageOptions}
          renderItem={renderColorOption}
        />

        <Text style={[uniqueStyles.h4, { textAlign: "left", marginTop: 25 }]}>
          作成したデッキを一般公開するか{"\n"}お選びください。
        </Text>
        <RadioButtonGroup
          containerStyle={uniqueStyles.publicRadioContainer}
          radioBackground={MycaAPI.style.ThemeColor}
          selected={blueprint.public}
          size={20}
          onSelected={(newValue) => {
            const _blueprint = { ...blueprint };
            _blueprint.public = newValue;

            setBlueprint({ ..._blueprint });
          }}
        >
          <RadioButtonItem value={1} label="公開" />
          <RadioButtonItem value={0} label="非公開" />
        </RadioButtonGroup>

        <MycaButton color="red" width={250} onPress={() => createDeck()}>
          デッキを登録
        </MycaButton>
        <MycaButton
          color="gray"
          width={250}
          onPress={() => API.navigation.goBack()}
        >
          {blueprint.isPokeOfficial ? "デッキ詳細に戻る" : "デッキ編集に戻る"}
        </MycaButton>
      </ScrollView>
    </View>
  );
}

//デッキ作成完了
export function DeckFinishScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  //ここは何も処理書いてないです

  const uniqueStyles = StyleSheet.create({
    wrapper: {
      alignItems: "center",
      width: "90%",
      marginLeft: "5%",
    },
    h4: {
      fontSize: 17,
      fontWeight: "bold",
      marginTop: 25,
      marginBottom: 5,
    },
    h6: {
      fontSize: 12,
      fontWeight: "bold",
    },
    deckCodeContainer: {
      marginTop: 10,
      width: "80%",
      height: 300,
      borderColor: "black",
      borderWidth: 1,
    },
    codeField: {
      backgroundColor: "white",
      padding: 8,
      borderWidth: 0.5,
      borderColor: "rgb(204, 204, 204)",
      width: 300,
      textAlign: "center",
      marginTop: 5,
      marginBottom: 20,
    },
  });

  const [deckData, setDeckData] = useState({});

  useEffect(() => {
    (async () => {
      //ここで完成されたデッキの情報をデータベースからデッキコードとユーザーデータを使って取得する

      //ユーザデータを取得する
      const _UserData = API.user;
      if (!_UserData) API.NavigateToNeedSignUp();

      const { blueprint, code } = route.params;

      let thisDeckInfo = await API.getDecks("owned", {
        user: _UserData.id,
        code,
      });
      thisDeckInfo = thisDeckInfo[0];

      setDeckData({ ...thisDeckInfo });
    })();
  }, [route.params?.code]);

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <View style={uniqueStyles.wrapper}>
        <Text style={uniqueStyles.h4}>デッキの登録が完了が完了しました！</Text>

        <View style={{ width: "70%", height: "50%" }}>
          {deckData.code && <DeckTopIcon API={API} deckData={deckData} />}
        </View>

        <Text style={{ fontSize: 15 }}>デッキコード</Text>
        <Text style={uniqueStyles.codeField}>{deckData.code}</Text>

        <MycaButton
          color="red"
          width={230}
          onPress={async () => {
            await Clipboard.setStringAsync(deckData.code);
            Alert.alert("", "コピーできました");
          }}
        >
          コードをコピーする
        </MycaButton>
        <MycaButton
          color="gray"
          width={230}
          onPress={() =>
            API.NavigateMenu(CurrentGenre, CurrentMenu, "デッキ", "一覧")
          }
        >
          デッキ一覧に戻る
        </MycaButton>
      </View>
    </View>
  );
}

export function DeckScreenshotScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [deckData, setDeckData] = useState();
  const previousRegulationGroupName = useRef(null);
  const [numColumns, setNumColumns] = useState(); //横の数

  const [modalVisible, setModalVisible] = useState(false);
  const [horizontalMode, setHorizontalMode] = useState(false);

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width,
  );

  const devData = {
    //開発用
    name: "デッキ名デッキ名",
    regulation: "レギュレーション名",
    code: "00000000000000000",
    items: Array.from({ length: 40 }, (_, index) => ({
      id: index,
      cardimage: "card/svI/svI_018",
      item_count: 4,
    })),
  };

  useEffect(() => {
    const data = route.params?.data;

    if (data) {
      //カードの種類を表示するための要素を追加する
      let modifiedItems = [];

      if (
        new Set(data.items.map((e) => e.regulation_group).filter((e) => e))
          .size < 2 ||
        [12001, 20001].includes(data.regulation_id)
      ) {
        modifiedItems = [...data.items];
      } else {
        data.items.forEach((item, index) => {
          if (
            item.regulation_group_name &&
            (index === 0 ||
              (item.regulation_group_name !==
                previousRegulationGroupName.current &&
                (data.regulation_id != 16001 ||
                  item.regulation_group == 16010)))
          ) {
            let zoneName =
              data.regulation_id == 16001 && item.regulation_group == 16001
                ? "オーダーデッキ"
                : data.regulation_id == 16001 && item.regulation_group == 16010
                  ? "メインデッキ"
                  : item.regulation_group_name;

            modifiedItems.push({
              id: zoneName,
              isShow: true,
              cardimage: data.genreData.single_genre_image,
              name: zoneName,
            });
          }
          modifiedItems.push(item);
          previousRegulationGroupName.current = item.regulation_group_name;
        });
      }
      setDeckData({ ...data, items: modifiedItems });
    } else {
      setDeckData(devData); // 開発用
    }
  }, [route.params]);

  useEffect(() => {
    const changeOrientation = async () => {
      if (horizontalMode) {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT,
        );
      } else {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT_UP,
        );
      }
    };
    changeOrientation();
  }, [horizontalMode]);

  useEffect(() => {
    const calculateNumColumns = (length) => {
      if (length <= 9) {
        return 3;
      } else if (length <= 20) {
        return 4;
      } else if (length <= 30) {
        return 5;
      } else if (length <= 40) {
        return 6;
      } else {
        return 7;
      }
    };

    const dataLength = deckData?.items?.length ?? devData.items.length;
    setNumColumns(calculateNumColumns(dataLength));
  }, [deckData]);

  const renderItem = useCallback(
    ({ item }) => {
      const imgFrameStyle = {
        ...uniqueStyles.imgFrame,
        width: screenWidth / numColumns,
      };

      if (item.isShow) {
        return (
          <View style={[imgFrameStyle, { position: "relative" }]}>
            <ImageBackground
              source={{ uri: item.cardimage }}
              resizeMode="contain"
              style={uniqueStyles.img}
            >
              <View style={uniqueStyles.overlay} />
            </ImageBackground>
            <View style={uniqueStyles.overlayRequireCountFrame}>
              <Text style={uniqueStyles.overlayRequireName}>{item.name}</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View style={imgFrameStyle}>
            <Image
              source={{ uri: API.getImageUrl(1, "item", item.cardimage) }}
              style={uniqueStyles.img}
            />
            <View style={uniqueStyles.requireCountFrame}>
              <Text style={uniqueStyles.requireCount}>{item.item_count}</Text>
            </View>
          </View>
        );
      }
    },
    [numColumns, screenWidth],
  );

  const keyExtractor = (item) => item.id.toString();

  const uniqueStyles = StyleSheet.create({
    frame: {
      height: "100%",
      width: "100%",
    },
    titelFrame: {
      marginTop: "10%",
      alignItems: "center",
      width: "100%",
      height: "11%",
    },
    titelFrameHorizontal: {
      alignItems: "center",
      justifyContent: "center",
      height: 90,
      width: 260,
      marginTop: 2,
    },
    logo: {
      width: 80,
      height: 30,
      resizeMode: "contain",
      marginBottom: 4,
    },
    deckName: {
      alignItems: "center",
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 4,
    },
    regName: {
      alignItems: "center",
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 4,
    },
    deckCode: {
      color: "rgb(132, 132, 132)",
      alignItems: "center",
      fontSize: 12,
    },

    listFrame: {
      width: "100%",
      height: "89%",
      marginTop: 20,
    },
    imgFrame: {
      alignItems: "center",
      justifyContent: "center",
      padding: 3,
    },
    imgFrameHorizontal: {
      alignItems: "center",
      height: 90,
      width: 65,
      paddingLeft: 1,
      paddingRight: 1,
      marginTop: 2,
    },
    img: {
      width: "100%",
      aspectRatio: 0.71,
    },
    imgHorizontal: {
      width: 63,
      aspectRatio: 0.71,
    },
    requireCountFrame: {
      backgroundColor: "rgb(184, 42, 42)",
      aspectRatio: 1,
      width: 23,
      borderRadius: 12,
      position: "absolute",
      right: 3,
      bottom: "5%",
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    requireCount: {
      color: "white",
      textAlign: "center",
      fontSize: 14,
      fontWeight: "bold",
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    overlayRequireCountFrame: {
      ...StyleSheet.absoluteFillObject,
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlayRequireName: {
      color: "white",
      textAlign: "center",
      fontSize: 14,
      fontWeight: "bold",
    },

    //modal
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    modalFrame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "90%",
      borderRadius: 18,
      padding: 20,
      transition: ".3s",
      backgroundColor: "white",
      overflow: "hidden",
    },
  });

  return (
    <>
      {modalVisible && (
        <TouchableOpacity
          style={uniqueStyles.DarkBackground}
        ></TouchableOpacity>
      )}

      <Modal
        supportedOrientations={["portrait", "landscape"]}
        transparent={true}
        visible={modalVisible}
        animationType={"fade"}
      >
        <View style={uniqueStyles.modalFrame}>
          <View
            style={[uniqueStyles.modal, horizontalMode ? { width: "50%" } : {}]}
          >
            <MycaButton
              color="red"
              width={282}
              onPress={() => setModalVisible(!modalVisible)}
            >
              スクショモードに戻る
            </MycaButton>
            <MycaButton
              color="red"
              width={282}
              onPress={() => {
                setHorizontalMode(!horizontalMode);
                setModalVisible(!modalVisible);
              }}
            >
              スクショモード{horizontalMode ? "縦" : "横"}に切り替え
            </MycaButton>
            <MycaButton
              color="gray"
              width={282}
              onPress={async () => {
                setHorizontalMode(false);
                await new Promise((resolve) => setTimeout(resolve, 100)); //0.1秒待ってから遷移
                navigation.navigate("デッキ詳細");
                setModalVisible(!modalVisible);
              }}
            >
              スクショモードを終了する
            </MycaButton>
          </View>
        </View>
      </Modal>

      <TouchableOpacity
        onPress={() => setModalVisible(!modalVisible)}
        style={[
          uniqueStyles.frame,
          horizontalMode
            ? { flexDirection: "row", flexWrap: "wrap", marginLeft: 20 }
            : {},
        ]}
      >
        {horizontalMode ? (
          <>
            <View style={uniqueStyles.titelFrameHorizontal}>
              <Image
                source={{
                  uri: "https://static.mycalinks.io/app/general/logo/mycalinks_logo_yoko.png",
                }}
                style={uniqueStyles.logo}
              />
              <Text style={uniqueStyles.deckName}>{deckData?.name}</Text>
              <Text style={uniqueStyles.regName}>{deckData?.regulation}</Text>
              <Text style={uniqueStyles.deckCode}>
                デッキコード：{deckData?.code}
              </Text>
            </View>
            {deckData?.items.map((item) =>
              item.isShow ? (
                <View
                  style={[
                    uniqueStyles.imgFrameHorizontal,
                    { position: "relative" },
                  ]}
                  key={item.id}
                >
                  <ImageBackground
                    source={{ uri: item.cardimage }}
                    resizeMode="contain"
                    style={uniqueStyles.img}
                  >
                    <View style={uniqueStyles.overlay} />
                  </ImageBackground>
                  <View style={uniqueStyles.overlayRequireCountFrame}>
                    <Text style={uniqueStyles.overlayRequireName}>
                      {item.name}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={uniqueStyles.imgFrameHorizontal} key={item.id}>
                  <Image
                    source={{ uri: API.getImageUrl(1, "item", item.cardimage) }}
                    style={uniqueStyles.imgHorizontal}
                  />
                  <View style={uniqueStyles.requireCountFrame}>
                    <Text style={uniqueStyles.requireCount}>
                      {item.item_count}
                    </Text>
                  </View>
                </View>
              ),
            )}
          </>
        ) : (
          <>
            <View style={uniqueStyles.titelFrame}>
              <Image
                source={{
                  uri: "https://static.mycalinks.io/app/general/logo/mycalinks_logo_yoko.png",
                }}
                style={uniqueStyles.logo}
              />
              <Text style={uniqueStyles.deckName}>{deckData?.name}</Text>
              <Text style={uniqueStyles.regName}>{deckData?.regulation}</Text>
              <Text style={uniqueStyles.deckCode}>
                デッキコード：{deckData?.code}
              </Text>
            </View>
            <View style={uniqueStyles.listFrame}>
              <FlatList
                key={numColumns}
                numColumns={numColumns}
                data={deckData?.items}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
              />
            </View>
          </>
        )}
      </TouchableOpacity>
    </>
  );
}
