//HTTP API通信のため
import axios, { AxiosError } from "axios";

//ハッシュ化のため
import * as Crypto from "expo-crypto";

//base64エンコード
import { decode as atob, encode as btoa } from "base-64";

//ローカルストレージを使うため
import AsyncStorage from "@react-native-async-storage/async-storage";

//ユーザー情報の保持にセキュアストアを使う
import * as SecureStore from "expo-secure-store";

//プラットフォーム判断
import { Platform } from "react-native";

import moment from "moment";

import FormData from "form-data";

FormData.prototype[Symbol.toStringTag] = "FormData";

// const v3_api_endpoint = "http://192.168.0.61:3010";
// const v3_api_endpoint = "http://192.168.11.5:3010";
// const v3_api_endpoint = "http://localhost:3010";
const v3_api_endpoint = "https://api.mycalinks.io";

const posEndpoint = "https://pos.mycalinks.io/api";
// const posEndpoint = "https://staging.pos.mycalinks.io/api";
// const posEndpoint = "http://192.168.0.61:3020/api";

const api = axios.create({
  baseURL: v3_api_endpoint,
  timeout: 20000,
  maxRedirects: 10,
});

//よく使う関数をここでまとめる

export class MycaAPI {
  constructor() {}

  async setUp() {
    //APIのセットアップ関数 この時にUserのUpdateも行う

    //最新バージョンをセットする
    this.version = "20241215";

    //ローカルストレージからユーザーデータを取得
    this.user = await MycaAPI.getUserData();

    //サーバーの定数を取得
    const res = await this.send("GET", `/setting/constant/`, {
      version: this.version,
    });
    this.constant = res.data;

    //ユーザーバージョンを更新する
    await this.updateUser();
  }

  //ジャンル別、メニューに遷移するための関数
  NavigateMenu = (
    CurrentGenre,
    CurrentMenu,
    targetBottomMenu,
    targetTopMenu,
    targetMiddleMenu,
  ) => {
    //topMenuは指定されなかったら一番目のものが勝手に選択される

    const thisGenreInfo = this.constant.GenreList.find(
      (e) => e.genre == CurrentGenre[0],
    );
    const init = thisGenreInfo?.init;
    const middleMenu = thisGenreInfo?.middleMenu;

    let navigateParam = {
      index: 0,
      routes: [
        {
          name: "トップページ",
        },
      ],
    };

    if (targetBottomMenu == "お気に入り") {
      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: CurrentMenu[0].top, //お気に入りにはtopメニューがないため現状維持
        middle: CurrentMenu[0].middle,
        bottom: targetBottomMenu,
      });

      navigateParam.routes = [
        {
          name: "お気に入り一覧",
          params: {
            type: "一覧",
          },
        },
      ];
    } else if (targetBottomMenu == "マイカード") {
      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: CurrentMenu[0].top,
        middle: CurrentMenu[0].middle,
        bottom: targetBottomMenu,
      });

      navigateParam.routes = [
        {
          name: "資産一覧",
        },
      ];
    } else if (targetBottomMenu == "トップ") {
      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: CurrentMenu[0].top,
        middle: CurrentMenu[0].middle,
        bottom: targetBottomMenu,
      });

      CurrentGenre[1](0); //トップページ用の番号
      //CurrentGenreはトップページでの選択中ジャンル情報の保持のためにも使う

      navigateParam.routes = [
        {
          name: "トップページ",
        },
      ];
    } else if (targetBottomMenu == "会員証") {
      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: CurrentMenu[0].top,
        middle: CurrentMenu[0].middle,
        bottom: targetBottomMenu,
      });

      navigateParam.routes = [
        {
          name: "ショップページ",
        },
      ];
    } else if (targetBottomMenu == "アイテム") {
      //ミドルメニューを取得する
      targetMiddleMenu = targetMiddleMenu || middleMenu[0].label; //ミドルメニューが選択されていないときは1番目を選択（カード）

      //トップメニューを取得する
      const topMenus = middleMenu.find(
        (e) => e.label == targetMiddleMenu,
      ).topMenu;
      targetTopMenu = targetTopMenu || topMenus[0].label;

      const thisTopMenu = topMenus.find((e) => e.label == targetTopMenu);

      console.log(
        `${targetBottomMenu}の${targetMiddleMenu}の${targetTopMenu}にいけと言われています`,
      );

      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: targetTopMenu,
        middle: targetMiddleMenu,
        bottom: targetBottomMenu,
      });

      switch (targetMiddleMenu) {
        case "カード":
        case "PSA":
          switch (targetTopMenu) {
            case "すべて":
              const condition = thisTopMenu.condition || {
                displaytype1: `${init}${targetMiddleMenu}`,
              }; //自動的にconditionを作成する
              navigateParam.routes = [
                {
                  name: "アイテム一覧",
                  params: {
                    condition,
                  },
                },
              ];

              break;

            case "パック":
              navigateParam.routes = [
                {
                  name: "パック一覧",
                },
              ];

              break;

            default: //他の絞り込み系についてはItemFindScreenを用いる
              navigateParam.routes = [
                {
                  name: "アイテム絞り込み",
                  params: {
                    type: targetTopMenu, //ここを設定することでレアリティの絞り込みなのか、タイプの絞り込み…etc 等を判断できる
                  },
                },
              ];

              break;
          }

          break;

        case "ボックス": //ボックスの場合はそのままconditionに変換する
          const condition = {
            displaytype1: `${init}${targetMiddleMenu}`,
            displaytype2: `${init}${targetTopMenu}`,
          };
          navigateParam.routes = [
            {
              name: "アイテム一覧",
              params: {
                condition,
              },
            },
          ];

          break;
      }
    } else {
      //ここからはアイテム以外のトップメニューがある系
      // targetBottomMenu = targetBottomMenu || 'アイテム' //ボトムメニューすら指定されていない場合、「アイテム」を選択させる
      const ThisbottomMenu = this.constant.bottomMenus.find(
        (e) => e.label == targetBottomMenu,
      ); //ボトムメニュー情報を取得

      targetTopMenu = targetTopMenu || ThisbottomMenu.topMenu[0].label; //トップメニューを自動的に選択する

      CurrentMenu[1]({
        //先にメニューステートを更新しておく
        top: targetTopMenu,
        middle: CurrentMenu[0].middle,
        bottom: targetBottomMenu,
      });

      //各ボトムメニューに対しての場合分けを行っていく
      switch (targetBottomMenu) {
        case "コレクション": //コレクション
          switch (targetTopMenu) {
            case "一覧":
            case "探す":
              navigateParam.routes = [
                {
                  name: "コレクション一覧",
                },
              ];
              break;

            case "作る":
              navigateParam.routes = [
                {
                  name: "コレクションジャンル",
                },
              ];
              break;
          }

          break;

        case "デッキ": //デッキ
          switch (targetTopMenu) {
            case "一覧":
            case "探す":
              navigateParam.routes = [
                {
                  name: "デッキ一覧",
                },
              ];
              break;

            case "作る":
              navigateParam.routes = [
                {
                  name: "デッキジャンル",
                },
              ];
              break;
          }

          break;
      }
    }

    const resutl = this.navigation.reset({
      //ルートを一回リセットすることで戻れなくさせる
      ...navigateParam,
    });
  };

  //会員登録が必要ページへ遷移
  NavigateToNeedSignUp = () => {
    this.navigation.navigate("NeedSignUpScreen");
  };

  //画像URLを取得する //これはスタティックでOK
  getImageUrl = (genre, mode, url, is_detail, isGrayscale) => {
    const root = "https://static.mycalinks.io/app/item/";

    if (!url) return root;

    const ThisInfo = this.constant.GenreList.find((e) => e.genre == genre);
    const Init = ThisInfo[`${mode}ImageInit`];

    const extension = is_detail ? ".jpg" : ".gif";

    const bwFlg = isGrayscale ? "_bw" : "";

    return `${root}${Init}${url}${bwFlg}${extension}`;
  };

  //スタイルのため
  static style = {
    ThemeColor: "rgba(184,42,42,1)",
    thinGray: "rgba(0,0,0,0.3)",
    BlackBoxShadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 5,
      shadowOpacity: 0.3,
      elevation: 4,
    },
    GrayBoxShadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 5,
      shadowOpacity: 0.13,
      elevation: 3,
    },
    rounded: {
      borderRadius: 10,
      overflow: "hidden",
    },
    backgroundGray: "rgba(248,248,248,1)",
    sellColor: "rgb(86, 173, 139)",
    buyColor: "rgba(184, 42, 42, 1)",
  };

  //アプリ内画像
  static loadAppImage = {
    deck: {
      package: {
        box: {
          "01": require(`../assets/deck/package/box/01.png`),
          "02": require(`../assets/deck/package/box/02.png`),
          "03": require(`../assets/deck/package/box/03.png`),
          "04": require(`../assets/deck/package/box/04.png`),
          "05": require(`../assets/deck/package/box/05.png`),
          "06": require(`../assets/deck/package/box/06.png`),
          "07": require(`../assets/deck/package/box/07.png`),
          "08": require(`../assets/deck/package/box/08.png`),
          "09": require(`../assets/deck/package/box/09.png`),
          10: require(`../assets/deck/package/box/10.png`),
          11: require(`../assets/deck/package/box/11.png`),
          12: require(`../assets/deck/package/box/12.png`),
          13: require(`../assets/deck/package/box/13.png`),
          14: require(`../assets/deck/package/box/14.png`),
          15: require(`../assets/deck/package/box/15.png`),
          16: require(`../assets/deck/package/box/16.png`),
          17: require(`../assets/deck/package/box/17.png`),
          18: require(`../assets/deck/package/box/18.png`),
          19: require(`../assets/deck/package/box/19.png`),
          20: require(`../assets/deck/package/box/20.png`),
          21: require(`../assets/deck/package/box/21.png`),
          22: require(`../assets/deck/package/box/22.png`),
          23: require(`../assets/deck/package/box/23.png`),
          24: require(`../assets/deck/package/box/24.png`),
          25: require(`../assets/deck/package/box/25.png`),
          26: require(`../assets/deck/package/box/26.png`),
          27: require(`../assets/deck/package/box/27.png`),
          28: require(`../assets/deck/package/box/28.png`),
          29: require(`../assets/deck/package/box/29.png`),
          30: require(`../assets/deck/package/box/30.png`),
        },
        choice: {
          "01": require(`../assets/deck/package/choice/01.png`),
          "02": require(`../assets/deck/package/choice/02.png`),
          "03": require(`../assets/deck/package/choice/03.png`),
          "04": require(`../assets/deck/package/choice/04.png`),
          "05": require(`../assets/deck/package/choice/05.png`),
          "06": require(`../assets/deck/package/choice/06.png`),
          "07": require(`../assets/deck/package/choice/07.png`),
          "08": require(`../assets/deck/package/choice/08.png`),
          "09": require(`../assets/deck/package/choice/09.png`),
          10: require(`../assets/deck/package/choice/10.png`),
          11: require(`../assets/deck/package/choice/11.png`),
          12: require(`../assets/deck/package/choice/12.png`),
          13: require(`../assets/deck/package/choice/13.png`),
          14: require(`../assets/deck/package/choice/14.png`),
          15: require(`../assets/deck/package/choice/15.png`),
          16: require(`../assets/deck/package/choice/16.png`),
          17: require(`../assets/deck/package/choice/17.png`),
          18: require(`../assets/deck/package/choice/18.png`),
          19: require(`../assets/deck/package/choice/19.png`),
          20: require(`../assets/deck/package/choice/20.png`),
          21: require(`../assets/deck/package/choice/21.png`),
          22: require(`../assets/deck/package/choice/22.png`),
          23: require(`../assets/deck/package/choice/23.png`),
          24: require(`../assets/deck/package/choice/24.png`),
          25: require(`../assets/deck/package/choice/25.png`),
          26: require(`../assets/deck/package/choice/26.png`),
          27: require(`../assets/deck/package/choice/27.png`),
          28: require(`../assets/deck/package/choice/28.png`),
          29: require(`../assets/deck/package/choice/29.png`),
          30: require(`../assets/deck/package/choice/30.png`),
        },
      },
    },
  };

  //ローカルストレージからユーザーデータを取得
  static async getUserData() {
    try {
      let Data;

      if (await SecureStore.isAvailableAsync()) {
        Data = await SecureStore.getItemAsync("mydata");
      } else {
        Data = await AsyncStorage.getItem("mydata");
      }

      return Data ? JSON.parse(Data) : null;
    } catch (err) {
      console.log("ユーザーデータが読み込めませんでした");
      console.error(err);
    }
  }

  //ローカルストレージにユーザーデータを登録
  static async setUserData(val) {
    try {
      const Data = JSON.stringify(val);

      if (await SecureStore.isAvailableAsync()) {
        await SecureStore.setItemAsync("mydata", Data);
      } else {
        AsyncStorage.setItem("mydata", Data);
      }
    } catch (err) {
      console.log("ユーザーデータが登録できませんでした");
      console.error(err);
    }
  }

  //ローカルストレージのデータをクリア
  static async resetStorage() {
    if (await SecureStore.isAvailableAsync()) {
      await SecureStore.deleteItemAsync("mydata");
    } else {
      AsyncStorage.clear();
    }

    return true;
  }

  //API実行のミドルウェア axiosを利用する
  async send(method, url, data, option = {}) {
    let headers = {};

    //userがパラメータに含まれる場合はトークンをのせる
    if (data?.user || data?.props?.user || data?.myca_user) {
      const token = await this.getToken();

      //有効なトークンがなかったら処理を中断してログイン画面にスクリーンさせる
      if (!token) {
        await this.navigateToLogin();
        return false;
      }

      //トークンがあったらそれをヘッダーに載せる
      headers.MycaToken = token;
    }

    option.headers = headers;

    if (data instanceof FormData) {
      option.headers["Content-Type"] = "multipart/form-data";
    }

    //リクエストを送信していく
    let res;
    try {
      switch (method) {
        case "POST":
          res = await api.post(url, data, option);
          break;

        case "PUT":
          res = await api.put(url, data, option);
          break;

        case "GET":
          res = await api.get(url, { params: data, ...option });
          break;
      }
    } catch (e) {
      console.log(`API送信中にエラー`, e.request);

      // AxiosError.prototype.

      ///認証エラーかどうかを判断
      if (e.response?.status == 401) {
        //認証エラーだったらログイン画面に遷移させる
        console.log("ログインに遷移させます");
        await this.navigateToLogin();
        return false;
      }
    }

    //特に何もなかったらそのまま返却する
    return res;
  }

  //認証トークンを取得
  async getToken() {
    const shortToken = this.user?.shortToken;
    const longToken = this.user?.longToken;

    //ショートトークンがあったら普通に実行する
    if (shortToken && this.validateToken(shortToken)) {
      return shortToken;
    }
    //ロングトークンがあったらショートトークンを発行させて実行し直す
    else if (longToken && this.validateToken(longToken)) {
      //この場でショートトークンを発行する
      const res = await api.post(
        `/user/account/get/`,
        { user: this.user?.id },
        {
          headers: {
            MycaToken: longToken,
          },
        },
      );

      //それでもうまく発行できなかったらnullをかえす
      if (res.status != 200) {
        return null;
      } else {
        //新しいshortTokenをローカルストレージに保存しつつ、APIをセットアップし直す
        const { newShortToken } = res.data;
        let newUserData = await MycaAPI.getUserData();
        newUserData.shortToken = newShortToken;
        await MycaAPI.setUserData(newUserData);
        await this.setUp();

        //そしてもう一回このAPIを呼び出す
        return await this.getToken();
      }
    }
    //トークンの有効期限が切れているか、存在しなかったらnullをかえす
    else {
      return null;
    }
  }

  //トークンの中身を取得する（改ざんされているかは判断しない
  validateToken(token) {
    const splitted = token.split(".");

    if (splitted.length != 3) return false;

    const decoded = atob(splitted[1]);
    let jsonData = {};

    try {
      jsonData = JSON.parse(decoded);
    } catch {
      return false;
    }

    //有効期限を確認
    if (!jsonData.exp) return false;

    const thisExp = moment(jsonData.exp);

    if (moment().isAfter(thisExp)) return false;

    return true;
  }

  //ログイン画面へ遷移
  async navigateToLogin() {
    await this.logOut();
    this.navigation.replace("LoginScreen");

    return true;
  }

  //日付の形式を / を使うようにする
  static DateFormat(date) {
    return moment(date).format("") == "Invalid date"
      ? ""
      : moment(date).format("YYYY/MM/DD");
  }

  //これはスタティックで大丈夫
  static async getSetting() {
    //設定情報を取得する

    const res = await api.get(`/setting/`);

    return res.data;
  }

  async logOut() {
    //正しくログアウトを行う

    this.user = {};
    await MycaAPI.resetStorage();

    this.navigation.reset({
      //スクリーンの履歴を消す
      index: 0,
      routes: [
        {
          name: "LoginScreen",
        },
      ],
    });

    return true;
  }

  async Login(mail, password) {
    //ログインを行う

    const hashed_password = await MycaAPI.sha256(password);

    const res = await this.send("POST", `/user/account/login/`, {
      mail,
      hashed_password,
    });

    //ログインができたか判断する
    if (res.data.length == 1) {
      //できたら、このユーザーデータをストレージに反映させる
      //ここではロングトークンも格納されている
      const userData = res.data[0];

      await MycaAPI.setUserData(userData);

      //ここで再びAPIをsetupすることでユーザーデータを取り込ませる
      //そして利用日を更新させる
      await this.setUp();
    }

    return res;
  }

  async SignUp(mail, password) {
    //新規会員登録を行う

    const hashed_password = await MycaAPI.sha256(password);

    const res = await this.send("POST", `/user/account/signup/`, {
      mail,
      hashed_password,
    });

    return res;
  }

  async deleteUser() {
    //ユーザーを削除する

    const user = this.user?.id;

    if (user) {
      const res = await this.send("POST", `/user/account/delete/`, { user });

      //ログアウトする
      await this.logOut();

      return res.data;
    } else {
      return false;
    }
  }

  async updateUser() {
    //ユーザーの最終更新日を変える

    //ユーザーをAPIから取得する
    const user = this.user?.id;

    if (user) {
      const res = await this.send("POST", `/user/account/update/`, {
        user,
        version: this.version,
      });

      return res.data;
    } else {
      return false;
    }
  }

  async forgetPassword(mail) {
    //パスワードの再発行

    const res = await this.send("POST", `/user/account/forget-password/`, {
      mail,
    });

    return res.data;
  }

  async getUserInfo() {
    //ユーザーの詳細情報を取得する

    const user = this.user?.id;

    if (user) {
      const { data: res } = await this.send("GET", `/user/account/`, { user });
      return res;
    } else {
      return false;
    }
  }

  //ユーザー情報を編集する関数
  async editUserInfo(props, values) {
    if (values.password) {
      values.password = await MycaAPI.sha256(values.password);
    }

    const { data: res } = await this.send("POST", `/user/account/edit/`, {
      props,
      values,
    });

    return res;
  }

  async changePassword(password) {
    //パスワード変更を行う

    const user = this.user?.id;

    if (user) {
      const hashed_password = await MycaAPI.sha256(password);

      const res = await this.send("POST", `/user/account/change-password/`, {
        user,
        hashed_password,
      });

      console.log("新APIでパスワード変更処理を行いました");

      return res;
    } else {
      return false;
    }
  }

  async getItem(props, fields, pages = {}, order = {}) {
    //アイテム情報を取得する

    //そのままAPIに送信する
    const data = { props, fields, pages, order };

    const res = await this.send("POST", `/item/detail/`, data);

    return res.data.data;
  }

  //アイテムのアクセス数をカウントアップするAPI
  async accessItem(item) {
    const res = await this.send("POST", "/item/detail/access/", { item });
    console.log("カウントアップさせたはずです");
    return res.data;
  }

  async registerItem(props, values) {
    //アイテム所有情報を登録する

    console.log("APIを送信しました");

    //そのままAPIに送信する
    const data = { props, values };
    const res = await this.send("POST", `/user/registration/`, data);

    return res.data;
  }

  async sellItem(props, values) {
    //アイテムを売却する

    //そのままAPIに送信する
    const data = { props, values };
    const res = await this.send("POST", `/user/registration/sell/`, data);

    return res.data;
  }

  async getFavorite(user) {
    //お気に入りを取得する

    const data = { user };
    const res = await this.send("GET", `/user/favorite/`, data);

    console.log("新APIでユーザーのお気に入り情報を取得しました");

    return res.data;
  }

  async setFavorite(user, item) {
    //お気に入りに登録する

    const data = { user, item };
    await this.send("POST", `/user/favorite/set/`, data);

    console.log("新APIでユーザーのお気に入り情報を追加しました");
  }

  async unsetFavorite(user, item) {
    //お気に入りから削除する

    const data = { user, item };
    await this.send("POST", `/user/favorite/unset/`, data);

    console.log("新APIでユーザーのお気に入り情報を削除しました");
  }

  async getAsset(props, fields = {}) {
    //あらゆる条件を指定してユーザーの資産を取得する

    const data = { props, fields };
    const res = await this.send("GET", `/user/asset/`, data);

    return res.data.data;
  }

  //アイテムの有効価格情報開始日を取得する
  async getItemPriceStartDate(item) {
    const { data: res } = await this.send("GET", `/item/price/data-start/`, {
      item,
    });

    return res?.start_date;
  }

  async getAssetGraph(props) {
    const data = { props };
    const past_res = await this.send("GET", `/user/asset/graph/`, data);
    const past_data = past_res.data;

    //前日までのデータを取得していく
    //データを結合する
    const raw_data = past_data.start_row
      .concat(past_data.rows)
      .sort((a, b) => a.date.localeCompare(b.date));

    let item_current_asset = {};
    let graph_data = [];

    if (raw_data.length) {
      //一番古い日付から順番に
      for (
        let ThisDay = raw_data[0].date;
        ThisDay < moment().format("YYYY-MM-DD");
        ThisDay = moment(ThisDay).add(1, "d").format("YYYY-MM-DD")
      ) {
        //この日付に変動があったアイテム
        const ThisDayItem = raw_data.filter((record) => record.date == ThisDay);

        //一切変動が無い場合、飛ばす
        if (!ThisDayItem.length) continue;

        ThisDayItem.forEach((record) => {
          item_current_asset[record.item] = record.value;
        });

        let ThisTotalAsset = 0;
        for (const item in item_current_asset) {
          ThisTotalAsset += item_current_asset[item];
        }

        graph_data.push({
          date: ThisDay,
          value: ThisTotalAsset,
        });
      }
    }

    return graph_data;
  }

  async getItemGraph(props, fields = {}) {
    const data = { props, fields };
    const past_res = await this.send("GET", `/item/price/graph/`, data);
    const past_data = past_res.data;

    //データを取得していく
    //データを結合する
    const raw_data = past_data.start_row
      .concat(past_data.rows)
      .sort((a, b) => a.date.localeCompare(b.date));

    let graph_data = {};

    //一番古い日付から順番に
    raw_data.forEach((record) => {
      const { item, date, value } = record;

      if (item in graph_data) {
        graph_data[item].push({
          value,
          date,
        });
      } else {
        graph_data[item] = [
          {
            value,
            date,
          },
        ];
      }
    });

    return graph_data;
  }

  async getGenres() {
    //ジャンルデータを取得する

    const res = await this.send("GET", `/item/genre/`, {
      version: this.version,
    });

    return res.data;
  }

  async getOptions(genre, kind_label, item_type) {
    //オプションデータを取得する

    const data = { genre, kind_label, item_type };
    const { data: res_data } = await this.send("GET", `/item/option/`, data);

    let result_array = {};
    const imageDir = `https://static.mycalinks.io/app/item/`;

    if (!res_data[0]) return false;

    result_array.label = kind_label;
    result_array.columnOnItems = res_data[0].kind;
    result_array.matchPolicy = res_data[0].match_policy;

    console.log("ここまではきてる");

    result_array.options = res_data.map((option) => ({
      name: option.option_label,
      value: option.option_value,
      img: {
        uri: (option.image_url || "").includes("https")
          ? option.image_url
          : `${imageDir}${option.image_url}`,
      },
    }));

    return result_array;
  }

  //トップページ用のカードを取得する 面倒くさいから全部一気に取得
  async getTopItems() {
    const res = await this.send("GET", `/item/genre/pickups/`);

    return res.data;
  }

  //コレクション自体のリストを取得する props:{ user,genre } ※mode = owned, search, draft ownedの場合はuserを指定しないといけないが、searchの場合はuserを指定しないことで非会員の人でも利用ができる genreを指定していない場合は全てを取得する
  async getCollections(mode, props) {
    const res = await this.send("GET", `/item/collection/list/`, {
      mode,
      props,
    });

    return res.data;

    //返り値の形式
    const response = `
      [
        {
          id:number, //コレクションのID これをコレクションコードにする感じかと
          name:string, //コレクションの名前
          image_url:string, //コレクションのサムネイル画像（仮）
          version:number, //コレクションのバージョン（mode=ownedだったらユーザーが登録しているバージョンで、mode=searchだったらそのコレクションの最新のバージョンとなる
          user:number, //コレクション作者のユーザーID（仮） ※管理人が作るときは0に設定
          collection_sum:number, //コレクションの中身のカードの枚数
          owned_sum:number, //指定されたuserが所有している枚数
          update_date:datetime(YYYY-MM-DD HH:mm:ss), //コレクションの最終更新日時（多分使わない）
        }
      ]
      `;
  }

  //指定されたコレクションの中のカードを取得する props:{ collection,version,user } ※
  async getCollectionItems(props) {
    //userがないと所有数情報は返されなくなる

    let { data: result } = await this.send("GET", `/item/collection/items/`, {
      props: { id: props.collection },
    });

    result.items = await this.getItem(
      { id: result.items, user: props.user || 0 },
      { detail: 1, includes_none: 1 },
      {},
      {},
    );

    result.id = props.collection;

    return result;

    //返り値の形式
    const response = `
      {
        id:number, //コレクションのID = コレクションコード
        this_version:number, //指定されているバージョン
        latest_version:number, //このコレクションの最新バージョン（バージョンの更新ができますみたいなやつの表示用）
        items:[ //コレクションのアイテムリスト
          {
            id:number, //アイテムID
            ...略
            number:number, //簡易登録登録数
            total_number:number, //合計登録数
            pricegap:number, //前日比の価格
          }
        ]
      }
      `;
  }

  //コレクションを登録したり登録解除するための関数 mode = register / delete, props = { user,collection }
  async registerCollections(mode, props) {
    const { data: res } = await this.send(
      "POST",
      `/item/collection/register/`,
      { mode, props },
    );

    return res.ok;
  }

  //コレクションを編集するための関数（表示/非表示切り替え用）
  async editCollections(props, values) {
    //propsではuserとcollectionが必要

    const { data: res } = await this.send("POST", `/item/collection/edit/`, {
      props,
      values,
    });

    return res.ok;
  }

  //コレクションを作成する関数（下書きの保存もこれで行う）
  async createCollections(props, values) {
    //propsではmodeとuserを含める必要がある modeはdraft | final
    //valuesはgenre,name,public,items[]によって構成される

    if (values.editMode) {
      //編集モードだったら、まず最初にこのコレクションを自分の所有物から削除する
      await this.registerCollections("delete", {
        collection: values.editMode,
        user: props.user,
      });
    }

    const { data: res } = await this.send("POST", `/item/collection/create/`, {
      props,
      values,
    });

    return res.code; //コレクションコードを返す。下書きの場合はnullがかえる。処理に失敗した場合はundefinedなどがかえるだろう
  }

  //レギュレーションのリストを取得する ここでは全部取得する
  async getRegulations() {
    const { data } = await this.send("GET", `/item/deck/regulations/`);
    return data;
  }

  //デッキのリストを取得する
  async getDecks(mode, props) {
    const res = await this.send("GET", `/item/deck/list/`, { mode, props });

    return res.data;

    //返り値の形式
    const response = `
      [
        {
          id:number, //デッキのID これは識別子
          name:string, //デッキの名前
          image_url:string, //デッキのサムネイル画像
          user:number, //デッキ作者のユーザーID（仮） ※管理人が作るときは1に設定
          user_name:string, //デッキ作成者の名前
          collection_sum:number, //コレクションの中身のカードの枚数
          owned_sum:number, //指定されたuserが所有している枚数
          update_date:datetime(YYYY-MM-DD HH:mm:ss), //コレクションの最終更新日時（多分使わない）
        }
      ]
      `;
  }

  //デッキを適切な順番に並び替える関数
  rearrangeDeckItems(deckItems, OrderSetting, isDetail) {
    //並び替えのためには基本的にregulation_groupとoption_for_deckとml_deck_orderが必要
    let _deckItems = [...deckItems];

    const fixedOptionList = this.constant.deckFixedProperty;

    _deckItems = _deckItems.sort((a, b) => {
      if (isDetail) {
        a = a.items.find((subItem) => subItem.id_for_deck == a.id_for_deck);
        b = b.items.find((subItem) => subItem.id_for_deck == b.id_for_deck);
      }

      //リーダー系のカードは優先的に表示させる
      if (
        fixedOptionList.includes(a.option_for_deck || "") &&
        !fixedOptionList.includes(b.option_for_deck || "")
      )
        return -1;
      if (
        !fixedOptionList.includes(a.option_for_deck || "") &&
        fixedOptionList.includes(b.option_for_deck || "")
      )
        return 1;

      //並び替え設定があるならそれを尊重する
      if (OrderSetting) {
        if (b[OrderSetting.column] != a[OrderSetting.column]) {
          return OrderSetting.mode == "DESC"
            ? b[OrderSetting.column] - a[OrderSetting.column]
            : a[OrderSetting.column] - b[OrderSetting.column];
        }
      }

      //次にregulation_group順に表示する
      if (a.regulation_group > b.regulation_group) return 1;
      if (a.regulation_group < b.regulation_group) return -1;

      //次に、ml_deck_order順に表示する こちらは降順
      if (a.ml_deck_order > b.ml_deck_order) return -1;
      if (a.ml_deck_order < b.ml_deck_order) return 1;

      return 0;
    });

    return [..._deckItems];
  }

  //指定されたデッキの中のカードを取得する props:{ deck,user,creator,code } ※
  async getDeckItems(props) {
    //userがないと所有数情報は返されなくなる

    let { data: result } = await this.send("GET", `/item/deck/items/`, {
      props: { id: props.deck, creator: props.creator, code: props.code },
    });

    let ResultData = {};

    //ここでポケカ公式の場合、getDeckのような取得の仕方にする
    if (props.code && !props.deck && props.creator == 0) {
      const getItemInfoPromises = result.map(async (Candidate) => {
        var items = [];

        const { kind_id, id_for_deck } = Candidate;
        const genre = 1;

        //kind_idがなかったらこのカードだけの情報を取得する
        if (kind_id) {
          items = await this.getItem(
            { genre, kind_id, user: props.user },
            { detail: 1, includes_none: 1 },
            {},
            { column: "deck_order", mode: "DESC" },
          );
        } else {
          items = await this.getItem(
            { genre, id_for_deck, user: props.user },
            { detail: 1, includes_none: 1 },
            {},
            { column: "deck_order", mode: "DESC" },
          );
        }

        return {
          id_for_deck: Candidate.id_for_deck,
          item_count: Candidate.item_count,
          regulation_group: Candidate.regulation_group,
          regulation_group_name: Candidate.regulation_group_name,
          option_for_deck: Candidate.option_for_deck,
          ml_deck_order: Candidate.ml_deck_order,
          items,
        };
      });

      //kind_idなどの情報まで含めた詳細なデータはdetailDataに格納するようにする
      ResultData.detailData = await Promise.all(getItemInfoPromises);

      //並び替える
      ResultData.detailData = this.rearrangeDeckItems(ResultData.detailData);

      ResultData.items = ResultData.detailData.map((each) => {
        const thisItem = each.items.find(
          (item) => item.id_for_deck == each.id_for_deck,
        );
        thisItem.item_count = each.item_count;
        thisItem.option_for_deck = each.option_for_deck;
        thisItem.ml_deck_order = each.ml_deck_order;

        return thisItem;
      });

      return ResultData;
    } else {
      const allItems = result.items.length
        ? await this.getItem(
            {
              genre: result.items[0]?.genre,
              kind_id: result.items.map((e) => e.kind_id),
              user: props.user,
            },
            { detail: 1, includes_none: 1 },
            {},
            { column: "deck_order", mode: "DESC" },
          )
        : [];

      const getItemInfoPromises = result.items.map(async (Candidate) => {
        var items = [];

        const { kind_id, item } = Candidate;

        if (item == 0) {
          //IDが0だったら、からのものを返す

          return {
            id_for_deck: Candidate.item,
            item_count: Candidate.item_count,
            regulation_group: Candidate.regulation_group,
            regulation_group_name: Candidate.regulation_group_name,
            option_for_deck: Candidate.option_for_deck,
            ml_deck_order: Candidate.ml_deck_order,
            items: [{ id_for_deck: Candidate.item }],
          };
        }

        //kind_idがなかったらこのカードだけの情報を取得する
        if (kind_id) {
          items = allItems.filter((e) => e.kind_id == kind_id);
        } else {
          items = allItems.filter((e) => e.id == item);
        }

        //すでに登録されているid_for_deckをidで上書きする
        items = items.map((item) =>
          Object.assign(item, { id_for_deck: item.id }),
        );

        return {
          //Mycaデッキの方ではid_for_deckはitems.idに値する
          id_for_deck: Candidate.item,
          item_count: Candidate.item_count,
          regulation_group: Candidate.regulation_group,
          regulation_group_name: Candidate.regulation_group_name,
          option_for_deck: Candidate.option_for_deck,
          ml_deck_order: Candidate.ml_deck_order,
          items,
        };
      });

      //kind_idなどの情報まで含めた詳細なデータはdetailDataに格納するようにする
      ResultData.detailData = await Promise.all(getItemInfoPromises);

      //並び替える
      ResultData.detailData = this.rearrangeDeckItems(ResultData.detailData);

      ResultData.items = ResultData.detailData.map((each) => {
        const thisItem = each.items.find(
          (item) => item.id_for_deck == each.id_for_deck,
        );
        thisItem.id = thisItem.id || each.id_for_deck;
        thisItem.item_count = each.item_count;
        thisItem.regulation_group = each.regulation_group;
        thisItem.regulation_group_name = each.regulation_group_name;
        thisItem.option_for_deck = each.option_for_deck;
        thisItem.ml_deck_order = each.ml_deck_order;

        return thisItem;
      });

      return ResultData;
    }

    //返り値の形式
    const response = `
      {
        id:deck, //デッキのID
        items:[ //デッキのアイテムリスト
          {
            id:number, //アイテムID
            ...略
            number:number, //簡易登録登録数
            total_number:number, //合計登録数
            pricegap:number, //前日比の価格
          }
        ],
        detailData, //旧APIで提供してたような詳細情報（kind_idなどを加味しているやつ、今回最終的にこちらのデータを使うようにしたい）
      }
      `;
  }

  //デッキを登録したり登録解除するための関数 mode = register / delete, props = { user,deck }
  async registerDeck(mode, props) {
    const { data: res } = await this.send("POST", `/item/deck/register/`, {
      mode,
      props,
    });

    return res.ok;
  }

  //デッキを編集するための関数（表示/非表示切り替え用）
  async editDeck(props, values) {
    //propsではuserとdeckが必要

    const { data: res } = await this.send("POST", `/item/deck/edit/`, {
      props,
      values,
    });

    return res.ok;
  }

  //レギュレーショングループの情報を取得する
  async getRegulationGroups(regulation) {
    const { data } = await this.send("GET", `/item/deck/regulation-group/`, {
      regulation,
    });
    return data;
  }

  //デッキを作成する関数（下書きの保存もこれで行う）
  async createDeck(props, values) {
    //propsではmodeとuserを含める必要がある modeはdraft | final | checkpoint
    //valuesはgenre,name,public,package_color,thumbnail_item,regulation,items[]によって構成される

    props.version = "20240630";

    if (values.editMode) {
      //editModeを使うときは最後の最後であるため、妥当性は担保されている
      //編集モードだったら、まず最初にこのデッキを自分の所有物から削除する
      await this.registerDeck("delete", {
        deck: values.editMode,
        user: props.user,
      });
    }

    const { data: res } = await this.send("POST", `/item/deck/create/`, {
      props,
      values,
    });

    return res; //コレクションコードを返す。下書きの場合はnullがかえる。処理に失敗した場合はundefinedなどがかえるだろう
  }

  async deleteDeck(props) {
    const { data: res } = await this.send("POST", `/item/deck/delete/`, {
      props,
    });

    return res;
  }

  //詳細登録のレコードを取得する
  async getDetailRegisterRecord(props) {
    const { data } = await this.send(
      "GET",
      `/user/registration/detail/record/`,
      { props },
    );

    return data;
  }

  //詳細登録の情報を取得する
  async getDetailRegisterInfo(props) {
    //props = { user,...item_condition }

    const { data } = await this.send("GET", `/user/registration/detail/`, {
      props,
    });

    //変形していく
    let result = {};

    data.forEach((each) => {
      //このアイテムが存在するかを確認
      if (!(each.item in result)) result[each.item] = [];

      //同じステートのレコードが存在するか確認
      if (!result[each.item].find((e) => e.state == each.state))
        result[each.item].push({
          state: each.state,
          records: [],
        });

      //レコードを編集する
      let thisState;

      thisState = result[each.item].find((e) => e.state == each.state);

      thisState.records.push({
        id: each.id,
        price: each.price,
        current_count: each.current_count,
        flg: each.flg,
        store: each.store,
        memo: each.memo,
        updated: each.updated,
      });
    });

    //並び替える
    for (const item in result) {
      //itemは参照わたしされているためこれを直接編集する
      result[item] = result[item].sort((a, b) => b.state - a.state);
    }

    return result;

    const response = `
      {
        [item:number //itemのid]:[ //このアイテムIDにおける詳細登録されているレコードの状態別のリスト
          {
            state:number, //状態パーセンテージ
            records:[ //この状態の詳細登録のレコードリスト
              {
                id:number, //詳細登録の識別子（レコードID これを利用することでレコードを特定することができる）
                price:number, //購入価格
                current_count:number, //このレコードに登録されている現在の枚数
                flg:string, //登録したフラグ（例：PSA10）
                store:string, //登録した購入店舗
                memo:string, //登録されているメモ 改行コードは\n
                created:date(YYYY-MM-DD), //このレコードが登録された日 これが購入日ということになる
                updated:datetime(YYYY-MM-DD HH:mm:ss), //このレコードが更新された日時
              }
            ]
          }
        ]
      }
      `;
  }

  //取引履歴を取得する関数
  async getTransactions(props, pages = {}, order = {}) {
    //そのままAPIに送信する
    const data = { props, pages, order };

    const res = await this.send(
      "GET",
      `/user/registration/transactions/`,
      data,
    );

    if (!res?.data?.length) return [];

    //アイテム詳細情報を取得する
    const itemInfos = await this.getItem(
      {
        id: res.data.map((e) => e.item),
      },
      {
        detail: 1,
      },
      {},
    );

    res.data.forEach((record) => {
      record.item = itemInfos.find((item) => record.item == item.id);
    });

    return res.data;

    //返り値の形式
    const response = `
      [
        {
          id:number, //取引ID
          flg:string, //フラグ（PSA10など）
          item_count:number, //購入/売却個数
          item_registration_id:number, //紐づいている詳細登録ID（おそらく使わない）
          memo:string, //購入/売却メモ 改行コードは\n
          price:number, //購入/売却価格（一枚当たり）
          state:number, //状態%
          store:string, //購入/売却店舗名
          transaction_kind:'buy' | 'sell', //buyだと購入レコード、sellだと売却レコード
          created:datetime('YYYY-MM-DD HH:mm:ss'), //発生日時
          item:{ //このアイテムの詳細情報
            id:number, //アイテムID
            ...略
            pricegap:number, //前日比の価格
          }
        }
      ]
      `;
  }

  //取引を削除する関数
  async deleteTransaction(props) {
    //そのままAPIに送信する
    const data = { props };

    const res = await this.send(
      "POST",
      `/user/registration/transactions/delete/`,
      data,
    );

    return res.data;
  }

  //画像をアップロードする
  async uploadImageForCollection(imagePickerResult, user) {
    let filename = imagePickerResult.assets[0].uri.split("/");
    filename = filename[filename.length - 1];

    const formData = new FormData();
    formData.append("user", user);
    formData.append("userImg", {
      name: filename,
      uri: imagePickerResult.assets[0].uri,
      type: imagePickerResult.assets[0].type,
    });

    const res = await this.send(
      "POST",
      `/item/collection/image/upload/`,
      formData,
    );

    return res.data;
  }

  //アイテム閲覧ランキングを取得する関数
  async getItemRanking(props) {
    //今の所、取得データの日付は昨日以上・個数は50個というのは確定
    const res = await this.send("GET", "/item/ranking/", { props });

    //それぞれ詳細情報を取得

    let rankingData = [];

    if (res.data?.length) {
      rankingData = await this.getItem(
        { id: res.data.map((e) => e.item) },
        { detail: 1 },
      );
      rankingData = rankingData
        .map((e) => {
          const viewNum = res.data.find(
            (item) => item.item == e.id,
          ).view_count_sum;
          return {
            ...e,
            view_num: parseInt(viewNum),
          };
        })
        .sort((a, b) => b.view_num - a.view_num);
    }

    return rankingData;
  }

  //バナーを取得する関数
  async getBanners(props) {
    const res = await this.send("GET", "/ads/banner/", { props });

    return res.data;
  }

  //会員QRコードを取得する関数
  async getQr() {
    if (!this.user?.id) return null;

    const res = await this.send("GET", "/user/account/qr/", {
      user: this.user?.id,
    });

    return res.data;
  }

  //MycaPOS上での会員情報を取得する関数
  async getPosCustomerInfo() {
    if (!this.user?.id) return null;

    const res = await this.send("GET", `${posEndpoint}/store/all/customer/`, {
      myca_user: this.user?.id,
    });

    return res.data;
  }

  //MycaPOSの取引履歴を取得する関数
  async getPosTransactions(query = {}) {
    if (!this.user?.id) return null;

    const res = await this.send("GET", `${posEndpoint}/store/all/transaction`, {
      myca_user: this.user?.id,
      ...query,
    });

    return res.data;
  }

  //MycaPOSの取引の詳細を取得する関数
  async getPosTransactionDetail(id) {
    if (!this.user?.id) return null;

    const res = await this.send(
      "GET",
      `${posEndpoint}/store/all/transaction/${id}`,
      {
        myca_user: this.user?.id,
      },
    );

    return res.data;
  }

  //MycaPOSの取引の顧客カートを更新する関数
  async putPosTransactionCustomerCart(id, carts) {
    if (!this.user?.id) return null;

    const res = await this.send(
      "PUT",
      `${posEndpoint}/store/all/transaction/${id}/customer-cart`,
      {
        myca_user: this.user?.id,
        carts,
      },
    );

    return res.data;
  }

  //MycaPOSの取引情報に署名URLをつける
  async putPosTransaction(id, body) {
    if (!this.user?.id) return null;

    const res = await this.send(
      "PUT",
      `${posEndpoint}/store/all/transaction/${id}`,
      {
        myca_user: this.user?.id,
        ...body,
      },
    );

    return res.data;
  }

  //MycaPOSのストア情報を取得する
  async getPosStoreInfo(id) {
    if (!id) return false;

    const res = await this.send("GET", `${posEndpoint}/store/${id}/`);

    return res.data;
  }

  //画像をアップロードする
  async uploadImage(kind, dataUri, fileName) {
    if (!this.user?.id) return null;

    const formData = new FormData();
    formData.append("user", this.user?.id);
    formData.append("kind", kind);
    formData.append("file", {
      uri: dataUri,
      name: fileName,
      type: "image/png",
    });

    const res = await this.send("POST", `/user/image/`, formData);

    return res.data;
  }

  static async sha256(text) {
    //ハッシュ化関数

    return await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      text,
    );
  }
}

//スタイル関係
export const MycaStyle = {
  ThemeColor: "rgba(184,42,42,1)",
  BlackBoxShadow: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    shadowOpacity: 0.3,
    elevation: 4,
  },
  GrayBoxShadow: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    shadowOpacity: 0.13,
    elevation: 3,
  },
};
