import { useState, useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";

import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import { MycaAPI } from "../lib/function";
import { useRoute } from "@react-navigation/native";
import { useGlobalNotify } from "../contexts/GlobalNotify.jsx";

// const { GenreList,bottomMenus } = MycaAPI;

export function TopMenu(props) {
  const { GenreList, bottomMenus } = props.API.constant;

  const [MenuList, setMenuList] = useState([]);
  const [middleMenuList, setMiddleMenuList] = useState([]);

  useEffect(() => {
    if (!isNaN(props.CurrentGenre[0])) {
      let topMenus = [];

      if (props.CurrentMenu[0].bottom == "アイテム") {
        //bottomMenuが「アイテム」なら、トップメニューの選択肢は選ばれているmiddleMenuによる
        //ここではmiddleの情報も加味する

        //まずはこのジャンルのmiddleMenuを取得する
        const { middleMenu } = GenreList.find(
          (e) => e.genre == props.CurrentGenre[0],
        );
        setMiddleMenuList([...middleMenu.map((e) => e.label)]);

        //トップメニューを取得する
        const thisMiddleMenu = middleMenu.find(
          (e) => e.label == props.CurrentMenu[0].middle,
        );
        topMenus = thisMiddleMenu.topMenu.map((e) => e.label);
      } else {
        //トップメニューを取得していく
        const thisBottomMenu = bottomMenus.find(
          (menu) => menu.label == props.CurrentMenu[0].bottom,
        );

        if (thisBottomMenu.topMenu) {
          topMenus = thisBottomMenu.topMenu.map((e) => e.label);
        }
      }

      setMenuList([...topMenus]);
    }
  }, [props.CurrentMenu]); //トップメニューはボトムメニューによって決定されるため、トリガーはCurrentMenu全体にしておく

  const Navigate = (Menu) => {
    const CurrentbottomMenu = props.CurrentMenu[0].bottom;
    const currentMiddleMenu = props.CurrentMenu[0].middle;

    //メニューを切り替える
    props.API.NavigateMenu(
      props.CurrentGenre,
      props.CurrentMenu,
      CurrentbottomMenu,
      Menu,
      currentMiddleMenu,
    );
  };

  //ミドルメニューを選択された時のやつ
  const navigateWithMiddleMenu = (menuLabel) => {
    //メニューを切り替える
    props.API.NavigateMenu(
      props.CurrentGenre,
      props.CurrentMenu,
      props.CurrentMenu[0].bottom,
      null,
      menuLabel,
    );
  };

  return (
    <>
      <View style={styles.sortBtnDiv}>
        {/* ボトムメニューがアイテムだったらミドルメニューも表示する */}
        {props.CurrentMenu[0].bottom == "アイテム" &&
          middleMenuList.map((Menu, i) => (
            <TouchableOpacity
              key={i}
              onPress={() => navigateWithMiddleMenu(Menu)}
              style={[
                styles.sortBtn,
                Menu == props.CurrentMenu[0].middle && styles.targetSortBtn,
              ]}
            >
              <Text style={[styles.sortBtnText]}>{Menu}</Text>
            </TouchableOpacity>
          ))}
      </View>
      <View style={styles.cat_area}>
        {MenuList.map((Menu, i) => (
          <TouchableOpacity
            key={i}
            onPress={() => Navigate(Menu)}
            style={
              Menu == props.CurrentMenu[0].top
                ? styles.cat_box_active
                : styles.cat_box
            }
          >
            <Text
              style={
                Menu == props.CurrentMenu[0].top
                  ? styles.cat_active
                  : styles.cat
              }
            >
              {Menu.replace("/", "\n")}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </>
  );
}

export function BottomMenu(props) {
  const { IconDict, GenreList, bottomMenus } = props.API.constant;

  const [MenuList, setMenuList] = useState([]);
  const route = useRoute();

  //買取受付　※いったん定義　contextから取得する？

  const { notifyInfo } = useGlobalNotify();

  useEffect(() => {
    if (!isNaN(props.CurrentGenre[0])) {
      //ボトムメニューはジャンルによらず、固定になった
      setMenuList([...bottomMenus.map((e) => e.label)]);
    }
  }, [props.CurrentGenre[0]]); //ボトムメニューはジャンルによって決定されるため、トリガーはCurrentGenre

  const CheckMaintenanceAndNavigate = (Menu) => {
    // const res = await maintenance();
    // if (res.maintenance == 0) {
    //メニューを切り替える
    console.log(Menu);
    props.API.NavigateMenu(props.CurrentGenre, props.CurrentMenu, Menu);
    // } else {
    //   props.API.navigation.navigate("Init");
    // }
  };

  return (
    <View style={[styles.group7, { zIndex: 1 }]}>
      {/* ショップ査定ページでない場合のみ査定中を表示 */}
      {route.name !== "ショップ査定ページ" &&
        route.name !== "買取規約確認" &&
        Boolean(notifyInfo.purchaseReception?.id) && (
          <View style={[styles.sateiWrapper]}>
            <TouchableOpacity
              style={[
                !notifyInfo.purchaseReception.assessed
                  ? styles.sateiField
                  : styles.sateiFieldFinish,
              ]}
              onPress={() =>
                //とりあえず規約画面に遷移させる
                props.API.navigation.navigate("買取規約確認", {
                  receptionInfo: notifyInfo.purchaseReception,
                })
              }
            >
              {!notifyInfo.purchaseReception.assessed ? (
                <Text style={[styles.sateiText]}>
                  査定中　少々お待ちください（受付番号
                  {notifyInfo.purchaseReception.receptionNumber}）
                </Text>
              ) : (
                <Text style={[styles.sateiTextFinish]}>
                  査定完了　レジまでお越しください（受付番号
                  {notifyInfo.purchaseReception.receptionNumber}）
                </Text>
              )}
            </TouchableOpacity>
          </View>
        )}

      <View style={[styles.container, styles.cupertinoFooter1]}>
        {MenuList.map((Menu, i) => (
          <TouchableOpacity
            style={styles.btnWrapper1}
            onPress={() => CheckMaintenanceAndNavigate(Menu)}
            key={i}
          >
            {IconDict[Menu][0] == "AntDesign" && (
              <AntDesign
                name={IconDict[Menu][1]}
                style={[
                  styles.icon,
                  Menu == props.CurrentMenu[0].bottom && styles.active,
                ]}
              />
            )}
            {IconDict[Menu][0] == "MaterialCommunityIcons" && (
              <MaterialCommunityIcons
                name={IconDict[Menu][1]}
                style={[
                  styles.icon,
                  Menu == props.CurrentMenu[0].bottom && styles.active,
                ]}
              />
            )}
            {IconDict[Menu][0] == "FontAwesome" && (
              <FontAwesome
                name={IconDict[Menu][1]}
                style={[
                  styles.icon,
                  Menu == props.CurrentMenu[0].bottom && styles.active,
                ]}
              />
            )}
            <Text
              style={[
                styles.footer_text,
                Menu == props.CurrentMenu[0].bottom && styles.active,
              ]}
            >
              {Menu}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  //査定のボタン
  sateiWrapper: {
    position: "absolute",
    bottom: "110%",
    width: "100%",
  },
  sateiField: {
    backgroundColor: "white",
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    padding: 5,
    borderWidth: 2,
    borderColor: MycaAPI.style.ThemeColor,
  },
  sateiFieldFinish: {
    backgroundColor: MycaAPI.style.ThemeColor,
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    padding: 5,
  },
  sateiText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
    color: MycaAPI.style.ThemeColor,
  },
  sateiTextFinish: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
    color: "white",
  },
  //ボトムメニュー
  sortBtnDiv: {
    width: "100%",
    paddingLeft: 15,
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 7,
    alignItems: "center",
    marginTop: 10,
  },
  targetSortBtn: {
    backgroundColor: "rgb(184, 42, 42)",
  },
  sortBtn: {
    width: "19%",
    height: 23,
    padding: 4,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    backgroundColor: "rgb(157, 157, 157)",
  },
  sortBtnText: {
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
  },

  active: {
    opacity: 1,
    color: MycaAPI.style.ThemeColor,
  },
  cat_area: {
    width: "90%",
    display: "wrap",
    flexDirection: "row",
    flexWrap: "flex-wrap",
    height: 25,
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
  },
  cat_box: {
    flex: 1,
    borderBottomColor: "#e4e4e4",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    justifyContent: "center",
  },
  すべて1: {
    color: "rgba(132,132,132,1)",
    fontSize: 12,
  },
  cat_box_active: {
    flex: 1,
    borderBottomColor: "rgba(184,42,42,1)",
    borderBottomWidth: 3,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
  },
  cat: {
    textAlign: "center",
    color: "rgba(132,132,132,1)",
    fontSize: 11,
  },
  cat_active: {
    textAlign: "center",
    color: "rgba(184,42,42,1)",
    fontSize: 11,
  },
  container: {
    backgroundColor: "rgba(255,255,255,1)",
    flexDirection: "row",
    width: "100%",
  },
  btnWrapper1: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: "black",
    backgroundColor: "transparent",
    fontSize: 20,
    opacity: 0.5,
  },
  footer_text: {
    fontSize: 10,
    color: "#9E9E9E",
    backgroundColor: "transparent",
    paddingTop: 4,
    opacity: 1,
  },
  cupertinoFooter1: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 15,
  },
  group7: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "12%",
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 0,
    },
    elevation: 18,
    shadowOpacity: 0.28,
    shadowRadius: 6,
  },
});
