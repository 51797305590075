//詳細登録、売却のモーダルを定義するファイル

import { useState, useEffect, useCallback } from "react";
import {
  Alert,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { Image } from "expo-image";
import { AntDesign } from "@expo/vector-icons";
// import {Picker} from '@react-native-picker/picker';
// import {Picker} from '@react-native-community/picker';
// import PickerSelect from 'react-native-picker-select';
import RNPickerSelect from "react-native-picker-select";
import { useFocusEffect } from "@react-navigation/native";
import { MycaAPI, MycaStyle } from "../lib/function";
import ProgressiveImage from "./ProgressiveImage";
import { ColorPrice } from "./Common";
import DateTimePickerModal from "react-native-modal-datetime-picker";
const { style } = MycaAPI;

export function DetailRegisterModal({
  Info,
  setInfo,
  navigation,
  initValue,
  API,
}) {
  const [UserData, setUserData] = useState();
  const [Props, setProps] = useState({
    user: 0,
    item: 0,
  });
  const [InputValue, setInputValue] = useState({
    id: 0, //idに関しては既存の詳細登録を編集する時に指定するやつ
    is_detail: 1,
    number: 0,
    price: 0,
    state: 100,
    flg: "",
    flg_num: "",
    store: "",
    memo: "",
    created: MycaAPI.DateFormat(new Date()),
  });
  const [Registering, setRegistering] = useState("登録");

  const [isShowDatePicker, setIsShowDatePicker] = useState(false);

  //フラグの内容
  const flgOptions = {
    未開封: [],
    ARS: ["10+", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
    CGS: [],
    CSG: [],
    GMA: [],
    エラー: [],
    PSA: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1.5", "1"],
    BGS: [
      "B10",
      "G10",
      "9.5",
      "9",
      "8.5",
      "8",
      "7",
      "6",
      "5",
      "4",
      "3",
      "2",
      "1",
    ],
  };

  //フラグのランク
  const [flgSubOptions, setFlgSubOptions] = useState([]);

  //ユーザーデータが取得できたら
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (API.user?.id && Info.item) {
          setProps(
            Object.assign(Props, {
              user: API.user?.id,
              item: Info.item.id,
            }),
          );
        }
      })();
    }, [Info]),
  );

  const HandleChange = (prop, newValue) => {
    const NewInputValue = { ...InputValue };

    //バリデーション
    if (newValue) {
      switch (prop) {
        case "price":
        case "number":
          if (!/^\d+$/.test(newValue)) {
            Alert.alert("", "0以上の整数を入力してください");
            return false;
          }
      }
    }

    NewInputValue[prop] = newValue;

    if (prop == "flg") {
      setFlgSubOptions(flgOptions[newValue]);
    }

    setInputValue({
      ...NewInputValue,
    });
  };

  useEffect(() => {
    //フラグはflgとflg_numが結合してる状態になってしまっているため
    if (initValue) {
      let flg = "";
      let flg_num = "";
      let flgRaw = initValue.flg;

      if (flgRaw) {
        const thisFlg = Object.keys(flgOptions).find((candidate) =>
          flgRaw.includes(candidate),
        );

        if (thisFlg) {
          flg = thisFlg;
          flg_num = flgRaw.split(thisFlg)[1];
        }
      }

      setInputValue({
        id: initValue.id || 0, //詳細登録の編集用
        is_detail: 1, //ここは固定
        number: initValue.number ? String(initValue.number) : 0,
        price: initValue.price ? String(initValue.price) : 0,
        state: initValue.state || 100,
        flg,
        flg_num,
        store: initValue.store || "",
        memo: initValue.memo || "",
        created:
          MycaAPI.DateFormat(initValue.created) ||
          MycaAPI.DateFormat(new Date()),
      });
    }
  }, [initValue]);

  const Register = async () => {
    //登録を実行する関数

    if (!API.user?.id) {
      API.NavigateToNeedSignUp(navigation);
      return false;
    }

    if (Props.user && Props.item) {
      if (!InputValue.number) {
        Alert.alert("", "枚数は入力してください");
        return false;
      }
      if (!InputValue.state) {
        Alert.alert("", "状態を選択してください");
        return false;
      }

      setRegistering("登録中…");

      // 登録処理を行っていく
      // データベースの方も変える
      const response = await API.registerItem(
        {
          ...Props,
        },
        {
          ...InputValue,
        },
      );

      console.log("ここまで来てない");

      setInfo({
        isShow: true,
        item: {},
        ended: true,
      });
      setRegistering("登録");
    }
  };

  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "80%",
      maxHeight: "90%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
    },
    item_name: {
      fontSize: 17,
      margin: 2,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
      flex: 1,
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    input_field: {
      margin: 15,
      // gap:8,
      display: "flex",
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
      marginTop: 4,
      marginBottom: 4,
    },
    memo_input_field: {
      marginBottom: 80,
    },
    input_label: {
      width: "20%",
      backgroundColor: style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    picker_style_flg_num: {
      viewContainer: {
        width: 100,
        borderColor: "#0000006b",
        borderLeftWidth: 1,
        borderStyle: "solid",
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    register_button_field: {
      position: "absolute",
      paddingBottom: 15,
      paddingTop: 10,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 100,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
  });

  const Close = () => {
    setInfo({ isShow: false, item: {}, record: {} });
  };

  return (
    <>
      {Info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={Info.isShow} animationType={"fade"}>
        <KeyboardAvoidingView style={{ height: "100%" }} behavior="padding">
          <View style={styles.frame}>
            <View style={styles.modal}>
              <View style={styles.header}>
                <Text style={styles.header_title}>
                  {InputValue.id ? "詳細登録編集" : "詳細登録"}
                </Text>
                <TouchableOpacity
                  style={styles.close_icon}
                  onPress={() => Close()}
                >
                  <AntDesign name="close" size={30} color="white" />
                </TouchableOpacity>
              </View>

              {!Info.ended ? (
                <>
                  <View style={styles.item_info}>
                    <View style={styles.item_image_field}>
                      <ProgressiveImage
                        source={{
                          uri: API.getImageUrl(
                            Info.item.genre_id,
                            "item",
                            Info.item.cardimage,
                            true,
                          ),
                        }}
                        resizeMode="contain"
                        style={styles.item_image}
                      ></ProgressiveImage>
                    </View>
                    <View style={styles.item_info_string}>
                      <Text style={styles.item_name}>{Info.item.cardname}</Text>
                      <Text style={styles.item_pack}>{Info.item.pack}</Text>

                      <View style={styles.underLinePropField}>
                        <Text style={styles.underLinePropProp}>購入金額</Text>
                        <Text style={styles.underLinePropValue}>
                          ￥{(InputValue.price || 0).toLocaleString()}/1枚
                        </Text>
                      </View>
                      <View style={styles.underLinePropField}>
                        <Text style={styles.underLinePropProp}>
                          合計購入金額
                        </Text>
                        <Text style={styles.underLinePropValue}>
                          ￥
                          {(
                            (InputValue.price || 0) * InputValue.number
                          ).toLocaleString()}
                        </Text>
                      </View>
                      <View style={styles.underLinePropField}>
                        <Text style={styles.underLinePropProp}>現在価格</Text>
                        <Text style={styles.underLinePropValue}>
                          ￥{(Info.item.price || 0).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <ScrollView style={styles.input_field}>
                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>購入日</Text>
                      <TouchableOpacity
                        onPress={() => {
                          setIsShowDatePicker(true);
                        }}
                        style={styles.input_area}
                      >
                        <Text>{String(InputValue.created)}</Text>
                      </TouchableOpacity>
                      <DateTimePickerModal
                        isVisible={isShowDatePicker}
                        mode="date"
                        onConfirm={(date) => {
                          HandleChange("created", MycaAPI.DateFormat(date));
                          setIsShowDatePicker(false);
                        }}
                        onCancel={() => {
                          setIsShowDatePicker(false);
                        }}
                        locale="ja"
                        confirmTextIOS="確定"
                      />
                    </View>

                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>購入金額</Text>
                      <TextInput
                        placeholder="1枚当たり"
                        placeholderTextColor="#000000ba"
                        onChangeText={(value) => HandleChange("price", value)}
                        style={styles.input_area}
                        value={InputValue.price}
                      ></TextInput>
                    </View>

                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>枚数</Text>
                      <TextInput
                        placeholder="登録枚数"
                        placeholderTextColor="#000000ba"
                        onChangeText={(value) => HandleChange("number", value)}
                        style={styles.input_area}
                        value={InputValue.number}
                      ></TextInput>
                    </View>

                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>フラグ</Text>
                      <RNPickerSelect
                        items={Object.keys(flgOptions).map((option) => ({
                          label: option,
                          value: option,
                          color: "black",
                        }))}
                        placeholderTextColor="#000000ba"
                        placeholder={{
                          label: "フラグ",
                          value: null,
                        }}
                        onValueChange={(itemValue) => {
                          HandleChange("flg", itemValue);
                        }}
                        style={styles.picker_style}
                        value={InputValue.flg}
                        Icon={() => {
                          return Platform.OS === "ios" ? (
                            <AntDesign
                              name="caretdown"
                              size={15}
                              color="gray"
                              style={{
                                width: 90, //IOSでタッチに反応させるため
                                textAlign: "right",
                                marginRight: 7,
                                lineHeight: 25,
                                // opacity: 0,
                              }}
                            />
                          ) : null;
                        }}
                      />
                      <RNPickerSelect
                        items={(flgSubOptions || []).map((option) => ({
                          label: option,
                          value: option,
                          color: "black",
                        }))}
                        placeholderTextColor="#000000ba"
                        placeholder={{
                          label: "ランク",
                          value: null,
                        }}
                        onValueChange={(itemValue) => {
                          HandleChange("flg_num", itemValue);
                        }}
                        style={styles.picker_style_flg_num}
                        value={InputValue.flg_num}
                        Icon={() => {
                          return Platform.OS === "ios" ? (
                            <AntDesign
                              name="caretdown"
                              size={15}
                              color="gray"
                              style={{
                                width: 90, //IOSでタッチに反応させるため
                                textAlign: "right",
                                marginRight: 7,
                                lineHeight: 25,
                                // opacity: 0,
                              }}
                            />
                          ) : null;
                        }}
                      />
                    </View>

                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>状態</Text>
                      <RNPickerSelect
                        items={[...Array(20)]
                          .map((_, i) => (i + 1) * 10)
                          .map((percentage) => ({
                            label: `${percentage}%`,
                            value: percentage,
                            color: "black",
                          }))}
                        placeholderTextColor="#000000ba"
                        onValueChange={(itemValue) => {
                          HandleChange("state", parseInt(itemValue));
                        }}
                        style={styles.picker_style}
                        value={InputValue.state}
                        placeholder={{}}
                        Icon={() => {
                          return Platform.OS === "ios" ? (
                            <AntDesign
                              name="caretdown"
                              size={15}
                              color="gray"
                              style={{
                                width: 200, //IOSでタッチに反応させるため
                                textAlign: "right",
                                marginRight: 7,
                                lineHeight: 25,
                                // opacity: 0,
                              }}
                            />
                          ) : null;
                        }}
                      />
                    </View>

                    <View style={styles.each_input_field}>
                      <Text style={styles.input_label}>購入店舗</Text>
                      <TextInput
                        placeholder="100文字以内で入力"
                        placeholderTextColor="#000000ba"
                        onChangeText={(value) => HandleChange("store", value)}
                        value={InputValue.store}
                        style={styles.input_area}
                      ></TextInput>
                    </View>

                    <View
                      style={[styles.each_input_field, styles.memo_input_field]}
                    >
                      <Text style={[styles.input_label, styles.memo_label]}>
                        メモ
                      </Text>
                      <TextInput
                        placeholder="備考を入力"
                        placeholderTextColor="#000000ba"
                        multiline={true}
                        numberOfLines={3}
                        onChangeText={(value) => HandleChange("memo", value)}
                        value={InputValue.memo}
                        style={styles.input_area}
                      ></TextInput>
                    </View>
                  </ScrollView>

                  <TouchableOpacity
                    style={styles.register_button_field}
                    onPress={() => Register()}
                  >
                    <Text style={styles.register_button}>{Registering}</Text>
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <Image
                    style={styles.ended_check}
                    source={require("../assets/ended_check.png")}
                  />
                  <Text style={styles.ended_string}>登録が完了しました</Text>

                  <TouchableOpacity
                    style={styles.ended_button_field}
                    onPress={() => Close()}
                  >
                    <Text style={styles.register_button}>閉じる</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.ended_button_field}
                    onPress={() => {
                      Close();
                      navigation.navigate("取引履歴一覧");
                    }}
                  >
                    <Text style={[styles.register_button, styles.gray_button]}>
                      取引履歴を見る
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </>
  );
}

//売却モーダル
export function SellModal({ API, Info, setInfo, navigation, initValue }) {
  const [UserData, setUserData] = useState();
  const [Props, setProps] = useState({
    user: 0,
    item_registration_id: 0, //item_registrationのIDに相当
  });
  const [InputValue, setInputValue] = useState({
    item_count: 0, //売却枚数
    price: 0, //売却金額
    store: "", //売却店舗
    memo: "", //メモ
    created: MycaAPI.DateFormat(new Date()),
  });
  const [Registering, setRegistering] = useState("売却");

  const [isShowDatePicker, setIsShowDatePicker] = useState(false);

  //枚数の選択肢 ※上限はこのレコードの枚数
  const [numberOptions, setNumberOptions] = useState([]);

  //ユーザーデータが取得できたら
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (API.user?.id && Info.item) {
          setProps(
            Object.assign(Props, {
              user: API.user?.id,
              item_registration_id: Info.record.id,
            }),
          );
        }
      })();
    }, [Info]),
  );

  const HandleChange = (prop, newValue) => {
    const NewInputValue = { ...InputValue };

    //バリデーション
    if (newValue) {
      switch (prop) {
        case "price":
          if (!/^\d+$/.test(newValue)) {
            Alert.alert("", "0以上の整数を入力してください");
            return false;
          }
      }
    }

    NewInputValue[prop] = newValue;

    setInputValue({
      ...NewInputValue,
    });
  };

  useEffect(() => {
    //フラグはflgとflg_numが結合してる状態になってしまっているため
    if (initValue) {
      setNumberOptions(
        Info.record
          ? [
              ...Array(
                (Info.record.current_count || 0) +
                  (initValue.item_count ? parseInt(initValue.item_count) : 0),
              ),
            ].map((_, i) => i + 1)
          : [],
      );

      setInputValue({
        id: initValue.id || 0, //詳細登録の編集用
        item_count: parseInt(initValue.item_count) || 0,
        price: initValue.price ? String(initValue.price) : 0,
        store: initValue.store || "",
        memo: initValue.memo || "",
        created:
          MycaAPI.DateFormat(initValue.created) ||
          MycaAPI.DateFormat(new Date()),
      });
    }
  }, [initValue]);

  const Sell = () => {
    //売却を実行する関数

    if (!API.user?.id) {
      API.NavigateToNeedSignUp();
      return false;
    }

    if (Props.user && Props.item_registration_id) {
      if (!InputValue.item_count) {
        Alert.alert("", "枚数を入力してください");
        return false;
      }
      if (!InputValue.price) {
        Alert.alert("", "売却金額を入力してください");
        return false;
      }

      setRegistering("売却処理中…");

      API.sellItem(Props, InputValue).then((res) => {
        setInfo({
          isShow: true,
          item: {},
          record: {},
          ended: true,
        });
        setRegistering("売却");
      });
    }
  };

  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:Info.ended ? "45%" : "73%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      marginBottom: 10,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
      alignItems: "flex-start",
      gap: 1,
    },
    record_flg: {
      fontSize: 12,
      paddingTop: 0,
      paddinBottom: 0,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "#9d9d9d",
      lineHeight: 21,
    },
    item_name: {
      fontSize: 14,
      fontWeight: "bold",
      margin: 3,
      marginLeft: 0,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    price_field: {},
    price_field_title: {
      fontSize: 11,
    },
    price_field_value: {
      fontWeight: "bold",
      color: "#2e2e2e",
    },
    item_price: {
      fontSize: 15,
      margin: 4,
    },
    record_store: {
      marginLeft: 20,
      fontSize: 12,
      opacity: 0.6,
    },
    input_field: {
      margin: 15,
      marginTop: 10,
      gap: 8,
    },
    each_input_field: {
      flexDirection: "row",
      borderRadius: 6,
      overflow: "hidden",
      borderColor: "#0000006b",
      borderWidth: 1,
    },
    memo_input_field: {
      marginBottom: 0,
    },
    input_label: {
      width: "20%",
      backgroundColor: style.ThemeColor,
      color: "white",
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: 10,
      textAlign: "center",
      overflow: "hidden",
      fontWeight: "bold",
    },
    memo_label: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    input_area: {
      flex: 1,
      padding: 6,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6,
    },
    picker_style: {
      viewContainer: {
        flex: 1,
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    picker_style_flg_num: {
      viewContainer: {
        width: 120,
        borderColor: "#0000006b",
        borderLeftWidth: 1,
        borderStyle: "solid",
      },
      inputIOS: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputAndroid: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
      inputWeb: {
        fontSize: 13,
        width: "100%",
        padding: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      },
    },
    profit: {
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 6,
      marginBottom: 15,
    },
    profit_title: {
      fontSize: 13,
      opacity: 0.7,
    },
    profit_price: {
      fontSize: 18,
      fontWeight: "bold",
    },
    register_button_field: {
      // position:"absolute",
      paddingBottom: 15,
      paddingTop: 0,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    ended_check: {
      width: 100,
      height: 100,
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 30,
    },
    ended_string: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
      fontSize: 20,
      fontWeight: "bold",
      color: "#0000009e",
    },
    ended_button_field: {
      paddingBottom: 0,
      paddingTop: 10,
      backgroundColor: "white",
      borderRadius: 18,
    },
    gray_button: {
      backgroundColor: "#979797",
      marginBottom: 20,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
    },
  });

  const Close = () => {
    setInfo({ isShow: false, item: {}, record: {}, ended: false });
  };

  return (
    <>
      {Info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={Info.isShow} animationType={"fade"}>
        <KeyboardAvoidingView style={{ height: "100%" }} behavior="padding">
          {Info.record && (
            <View style={styles.frame}>
              <View style={styles.modal}>
                <View style={styles.header}>
                  <Text style={styles.header_title}>
                    {InputValue.id ? "売却編集" : "売却"}
                  </Text>
                  <TouchableOpacity
                    style={styles.close_icon}
                    onPress={() => Close()}
                  >
                    <AntDesign name="close" size={30} color="white" />
                  </TouchableOpacity>
                </View>

                {!Info.ended ? (
                  <>
                    <View style={styles.item_info}>
                      <View style={styles.item_image_field}>
                        <ProgressiveImage
                          source={{
                            uri: API.getImageUrl(
                              Info.item.genre_id,
                              "item",
                              Info.item.cardimage,
                              true,
                            ),
                          }}
                          resizeMode="contain"
                          style={styles.item_image}
                        ></ProgressiveImage>
                      </View>
                      <View style={styles.item_info_string}>
                        {Info.record.flg && (
                          <Text style={styles.record_flg}>
                            {Info.record.flg}
                          </Text>
                        )}

                        <Text style={styles.item_name}>
                          {Info.item.cardname}
                        </Text>
                        <Text style={styles.item_pack}>{Info.item.pack}</Text>
                        <View style={styles.underLinePropField}>
                          <Text style={styles.underLinePropProp}>購入日</Text>
                          <Text style={styles.underLinePropValue}>
                            {MycaAPI.DateFormat(Info.record.created)}
                          </Text>
                        </View>
                        <View style={styles.underLinePropField}>
                          <Text style={styles.underLinePropProp}>購入店舗</Text>
                          <Text style={styles.underLinePropValue}>
                            {Info.record.store}
                          </Text>
                        </View>
                        <View style={styles.underLinePropField}>
                          <Text style={styles.underLinePropProp}>購入金額</Text>
                          <Text style={styles.underLinePropValue}>
                            ￥{(Info.record.price || 0).toLocaleString()}/1枚
                          </Text>
                        </View>
                        <View style={styles.underLinePropField}>
                          <Text style={styles.underLinePropProp}>現在枚数</Text>
                          <Text style={styles.underLinePropValue}>
                            {Info.record.current_count || 0}枚
                          </Text>
                        </View>
                      </View>
                    </View>

                    <Text style={styles.record_store}>{Info.record.memo}</Text>
                    {/* <Text style={styles.record_store}>ここにごっつながいメモを入力すんねんほんならみるみるうちに狭くなっていくであああああああああああああああああああああああああああああああああああああああああああああああああああああっっっっっっっっっっっっっっっs</Text> */}

                    <View style={styles.input_field}>
                      <View style={styles.each_input_field}>
                        <Text style={styles.input_label}>売却日</Text>
                        <TouchableOpacity
                          onPress={() => {
                            setIsShowDatePicker(true);
                          }}
                          style={styles.input_area}
                        >
                          <Text>{String(InputValue.created)}</Text>
                        </TouchableOpacity>
                        <DateTimePickerModal
                          isVisible={isShowDatePicker}
                          mode="date"
                          onConfirm={(date) => {
                            HandleChange("created", MycaAPI.DateFormat(date));
                            setIsShowDatePicker(false);
                          }}
                          onCancel={() => {
                            setIsShowDatePicker(false);
                          }}
                          locale="ja"
                          confirmTextIOS="確定"
                        />
                      </View>

                      <View style={styles.each_input_field}>
                        <Text style={styles.input_label}>売却金額</Text>
                        <TextInput
                          placeholder="1枚当たり"
                          placeholderTextColor="#000000ba"
                          onChangeText={(value) => HandleChange("price", value)}
                          style={styles.input_area}
                          value={InputValue.price}
                        ></TextInput>
                      </View>

                      <View style={styles.each_input_field}>
                        <Text style={styles.input_label}>売却枚数</Text>
                        <RNPickerSelect
                          items={numberOptions.map((option) => ({
                            label: `${option}枚`,
                            value: option,
                            color: "black",
                          }))}
                          placeholderTextColor="#000000ba"
                          placeholder={{
                            label: "枚数を選択してください",
                            value: null,
                          }}
                          onValueChange={(itemValue) => {
                            HandleChange("item_count", itemValue);
                          }}
                          style={styles.picker_style}
                          value={parseInt(InputValue.item_count)}
                          Icon={() => {
                            return Platform.OS === "ios" ? (
                              <AntDesign
                                name="caretdown"
                                size={15}
                                color="gray"
                                style={{
                                  width: 200, //IOSでタッチに反応させるため
                                  textAlign: "right",
                                  marginRight: 7,
                                  lineHeight: 25,
                                  // opacity: 0,
                                }}
                              />
                            ) : null;
                          }}
                        />
                      </View>

                      <View style={styles.each_input_field}>
                        <Text style={styles.input_label}>売却店舗</Text>
                        <TextInput
                          placeholder="売却店舗"
                          placeholderTextColor="#000000ba"
                          onChangeText={(value) => HandleChange("store", value)}
                          style={styles.input_area}
                        ></TextInput>
                      </View>

                      <View
                        style={[
                          styles.each_input_field,
                          styles.memo_input_field,
                        ]}
                      >
                        <Text style={[styles.input_label, styles.memo_label]}>
                          メモ
                        </Text>
                        <TextInput
                          placeholder="備考を入力"
                          placeholderTextColor="#000000ba"
                          multiline={true}
                          numberOfLines={3}
                          onChangeText={(value) => HandleChange("memo", value)}
                          style={styles.input_area}
                        ></TextInput>
                      </View>
                    </View>

                    <View style={styles.profit}>
                      <Text style={styles.profit_title}>収益金額</Text>
                      <ColorPrice fontSize={20}>
                        {(InputValue.price - Info.record.price) *
                          (InputValue.item_count || 0)}
                      </ColorPrice>
                    </View>

                    <TouchableOpacity
                      style={styles.register_button_field}
                      onPress={() => Sell()}
                    >
                      <Text style={styles.register_button}>{Registering}</Text>
                    </TouchableOpacity>
                  </>
                ) : (
                  <>
                    <Image
                      style={styles.ended_check}
                      source={require("../assets/ended_check.png")}
                    />
                    <Text style={styles.ended_string}>売却が完了しました</Text>

                    <TouchableOpacity
                      style={styles.ended_button_field}
                      onPress={() => Close()}
                    >
                      <Text style={styles.register_button}>閉じる</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.ended_button_field}
                      onPress={() => {
                        Close();
                        navigation.navigate("取引履歴一覧");
                      }}
                    >
                      <Text
                        style={[styles.register_button, styles.gray_button]}
                      >
                        取引履歴を見る
                      </Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            </View>
          )}
        </KeyboardAvoidingView>
      </Modal>
    </>
  );
}

//詳細モーダル
export function DetailModal({
  API,
  Info,
  setInfo,
  navigation,
  showSellModal,
  showDetailRegisterModal,
  showSellEditModal,
}) {
  const [UserData, setUserData] = useState();
  const [Props, setProps] = useState({
    user: 0,
    item_registration_id: 0, //item_registrationのIDに相当
  });
  const [modeText, setModeText] = useState("購入");

  //ユーザーデータが取得できたら
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (Info.data.transaction_kind === "sell") {
          setModeText("売却");
        } else {
          setModeText("購入");
        }

        if (API.user?.id && Info.item) {
          setProps(
            Object.assign(Props, {
              user: API.user?.id,
              item_registration_id: Info.record.id,
            }),
          );
        }
      })();
    }, [Info]),
  );

  const styles = StyleSheet.create({
    frame: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      width: "80%",
      // height:  Info.data.transaction_kind === 'sell' ? "60%" : "50%",
      transition: ".3s",
      backgroundColor: "white",
      borderRadius: 18,
      overflow: "hidden",
    },
    header: {
      backgroundColor: style.ThemeColor,
      height: 45,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
    header_title: {
      lineHeight: 45,
      textAlign: "center",
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
    },
    close_icon: {
      position: "absolute",
      right: 10,
      top: 8,
    },
    item_info: {
      margin: 15,
      marginBottom: 10,
      flexDirection: "row",
      gap: 15,
    },
    item_image_field: {
      width: "35%",
      aspectRatio: 0.71,
    },
    item_image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    item_info_string: {
      flex: 1,
      alignItems: "flex-start",
      gap: 1,
    },
    kind_div: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 5,
      marginRight: 5,
    },
    record_flg: {
      fontSize: 12,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 10,
      overflow: "hidden",
      color: "white",
      backgroundColor: "#9d9d9d",
      lineHeight: 21,
      marginRight: 5,
    },
    item_name: {
      fontSize: 14,
      fontWeight: "bold",
      margin: 3,
      marginLeft: 0,
    },
    item_pack: {
      fontSize: 10,
      opacity: 0.7,
    },
    underLinePropField: {
      flexDirection: "row",
      alignItems: "flex-end",
      marginBottom: 4,
      marginTop: 4,
      borderColor: "#00000038",
      borderBottomWidth: 0.5,
      borderStyle: "solid",
      paddingBottom: 2,
      width: "100%",
    },
    underLinePropProp: {
      fontSize: 10,
      fontWeight: "bold",
      width: 60,
    },
    underLinePropValue: {
      fontSize: 12,
      flex: 1,
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
      zIndex: 100,
    },
    record_store: {
      marginLeft: 20,
      fontSize: 12,
      opacity: 0.6,
    },
    register_button_field: {
      position: "relative",
      paddingBottom: 0,
      paddingTop: 15,
      backgroundColor: "white",
      borderRadius: 18,
      // bottom:40,
      right: 0,
      left: 0,
    },
    register_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: style.ThemeColor,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    buttonField: {},
    close_button_field: {
      // position:"absolute",
      position: "relative",
      paddingBottom: 15,
      paddingTop: 10,
      backgroundColor: "white",
      // borderRadius:18,
      bottom: 0,
      right: 0,
      left: 0,
    },
    close_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "white",
      backgroundColor: "rgb(157, 157, 157)",
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    delete_button_field: {
      // position:"absolute",
      position: "relative",
      paddingBottom: 15,
      paddingTop: 0,
      backgroundColor: "white",
      marginTop: 10,
      // borderRadius:18,
    },
    delete_button: {
      width: "85%",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      lineHeight: 35,
      fontSize: 15,
      color: "rgb(157, 157, 157)",
      backgroundColor: "white",
      borderColor: "rgb(157, 157, 157)",
      borderWidth: 1,
      borderRadius: 7,
      overflow: "hidden",
      fontWeight: "bold",
    },
    alertText: {
      textAlign: "center",
      margin: 10,
      color: MycaStyle.ThemeColor,
    },
  });

  const Close = () => {
    setInfo({
      isShow: false,
      data: {},
      item: {},
      record: {},
    });
  };

  const changeToSellModal = (...args) => {
    Close();
    showSellModal(...args);
  };

  const deleteTransaction = (id) => {
    Alert.alert("", "本当に削除しますか？", [
      {
        text: "キャンセル",
        onPress: () => {
          console.log("キャンセルされました");
        },
        style: "cancel",
      },
      {
        text: "削除",
        onPress: async () => {
          console.log("削除ボタンが押されました");
          await API.deleteTransaction({ id, user: API.user?.id });
          console.log("削除できました");
          Alert.alert("", "削除できました");
          Close();
        },
        style: "destructive",
      },
    ]);
  };

  const changeToEditModal = (data, item, record) => {
    //取引種類によって場合わけ
    Close();

    switch (data.transaction_kind) {
      case "buy":
        showDetailRegisterModal(data, item, record);
        break;

      case "sell":
        showSellEditModal(data, item, record);
        break;
    }
  };

  return (
    <>
      {Info.isShow && <View style={styles.DarkBackground}></View>}
      <Modal transparent={true} visible={Info.isShow} animationType={"fade"}>
        <View style={styles.frame}>
          <View style={styles.modal}>
            <View style={styles.item_info}>
              <View style={styles.item_image_field}>
                <ProgressiveImage
                  source={{
                    uri: API.getImageUrl(
                      Info.item.genre_id,
                      "item",
                      Info.item.cardimage,
                      true,
                    ),
                  }}
                  resizeMode="contain"
                  style={styles.item_image}
                ></ProgressiveImage>
              </View>
              <View style={styles.item_info_string}>
                <View style={{ flexDirection: "row" }}>
                  {Info.data.transaction_kind === "sell" ? (
                    <Text
                      style={[
                        styles.record_flg,
                        { backgroundColor: "rgba(184, 42, 42, 1)" },
                      ]}
                    >
                      売却
                    </Text>
                  ) : Info.data.transaction_kind === "buy" ? (
                    <Text
                      style={[
                        styles.record_flg,
                        ,
                        { backgroundColor: "rgb(86, 173, 139)" },
                      ]}
                    >
                      購入
                    </Text>
                  ) : null}

                  {Info.data.flg ? (
                    <Text style={styles.record_flg}>{Info.data.flg}</Text>
                  ) : null}
                </View>

                {/* 売却時は購入日、購入店舗を追加 */}

                <Text style={styles.item_name}>{Info.item.cardname}</Text>
                <Text style={styles.item_pack}>{Info.item.pack}</Text>

                <View style={styles.underLinePropField}>
                  <Text style={styles.underLinePropProp}>購入日</Text>
                  <Text style={styles.underLinePropValue}>
                    {MycaAPI.DateFormat(Info.data.buy_created)}
                  </Text>
                </View>
                <View style={styles.underLinePropField}>
                  <Text style={styles.underLinePropProp}>購入店舗</Text>
                  <Text style={styles.underLinePropValue}>
                    {Info.data.buy_store}
                  </Text>
                  {/* <Text style={styles.underLinePropValue}>ここにゴッツ長い店舗名を入力すると</Text> */}
                </View>

                {Info.data.transaction_kind === "sell" ? (
                  <>
                    <View style={styles.underLinePropField}>
                      <Text style={styles.underLinePropProp}>売却日</Text>
                      <Text style={styles.underLinePropValue}>
                        {MycaAPI.DateFormat(Info.data.created)}
                      </Text>
                    </View>
                    <View style={styles.underLinePropField}>
                      <Text style={styles.underLinePropProp}>売却店舗</Text>
                      <Text style={styles.underLinePropValue}>
                        {Info.data.store}
                      </Text>
                    </View>
                  </>
                ) : null}

                <View style={styles.underLinePropField}>
                  <Text style={styles.underLinePropProp}>購入金額</Text>
                  <Text style={styles.underLinePropValue}>
                    ￥{(Info.data.buy_price || 0).toLocaleString()}/1枚
                  </Text>
                </View>
                <View style={styles.underLinePropField}>
                  <Text style={styles.underLinePropProp}>合計購入金額</Text>
                  <Text style={styles.underLinePropValue}>
                    ￥
                    {(
                      Info.data.buy_price * Info.data.buy_item_count || 0
                    ).toLocaleString()}
                    /{Info.data.buy_item_count || 0}枚
                  </Text>
                </View>

                <View style={styles.underLinePropField}>
                  {Info.data.transaction_kind === "sell" ? (
                    <>
                      <Text style={styles.underLinePropProp}>売却金額</Text>
                      <Text style={styles.underLinePropValue}>
                        ￥{(Info.data.price || 0).toLocaleString()}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={styles.underLinePropProp}>現在価格</Text>
                      <Text style={styles.underLinePropValue}>
                        ￥{(Info.item.price || 0).toLocaleString()}
                      </Text>
                    </>
                  )}
                </View>

                {Info.data.transaction_kind === "sell" && (
                  <View style={styles.underLinePropField}>
                    <Text style={styles.underLinePropProp}>合計売却金額</Text>
                    <Text style={styles.underLinePropValue}>
                      ￥
                      {(
                        Info.data.price * Info.data.item_count || 0
                      ).toLocaleString()}
                      /{Info.data.item_count || 0}枚
                    </Text>
                  </View>
                )}

                {Info.data.transaction_kind === "sell" ? (
                  <View style={styles.underLinePropField}>
                    <Text style={styles.underLinePropProp}>利益</Text>
                    <ColorPrice fontSize={12}>
                      {(Info.data.price - Info.data.buy_price) *
                        Info.data.item_count}
                    </ColorPrice>
                  </View>
                ) : null}
              </View>
            </View>

            <Text style={styles.record_store}>メモ</Text>
            <Text style={styles.record_store}>{Info.data.memo}</Text>

            <View style={styles.buttonField}>
              {Info.data.item_registration_id &&
                Info.data.transaction_kind === "buy" && (
                  <TouchableOpacity
                    style={styles.register_button_field}
                    onPress={() => changeToSellModal(Info.item, Info.record)}
                  >
                    <Text style={styles.register_button}>売却</Text>
                  </TouchableOpacity>
                )}

              <TouchableOpacity
                style={styles.close_button_field}
                onPress={() => Close()}
              >
                <Text style={styles.close_button}>閉じる</Text>
              </TouchableOpacity>

              {Info.record ? (
                <TouchableOpacity
                  style={[
                    styles.delete_button_field,
                    { paddingBottom: 0, paddingTop: 10 },
                  ]}
                  onPress={() =>
                    changeToEditModal(Info.data, Info.item, Info.record)
                  }
                >
                  <Text style={styles.delete_button}>編集</Text>
                </TouchableOpacity>
              ) : (
                <Text style={styles.alertText}>
                  この登録は編集出来ません。{"\n"}修正する場合は削除して{"\n"}
                  再度登録をお願い致します。
                </Text>
              )}

              <TouchableOpacity
                style={styles.delete_button_field}
                onPress={() => deleteTransaction(Info.data.id)}
              >
                <Text style={styles.delete_button}>削除</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}
