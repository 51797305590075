import { useState, useEffect, useCallback, useRef } from "react";
import {
  ActivityIndicator,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import { Image } from "expo-image";
import { useFocusEffect } from "@react-navigation/native";
import { I1_style as styles } from "../lib/style/I.style";
import { BottomMenu } from "../components/Menu";
import { ItemListComponent } from "../components/Item";
import { Graph } from "../components/Graph";
import { SortButton } from "../components/Sort";

export function MyFavoriteListScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  //リファクタVer
  const [Items, setItems] = useState([]); //表示されているアイテムの情報を格納する変数
  const [PageNo, setPageNo] = useState(1); //現在のページ数
  const [PriceGapData, setPriceGapData] = useState({}); //価格変動データ（最初の一回のみ取得するスタイルにする）
  const [UserData, setUserData] = useState(); //ユーザーデータ
  const [TypeInfo, setTypeInfo] = useState({}); //このタイプの情報
  const [IsLoading, setIsLoading] = useState(false); //ローディング中かどうか
  const [GraphData, setGraphData] = useState({}); //グラフのデータ
  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "pricegap", //お気に入りの場合は最初から価格変動モード
    mode: "DESC",
  });

  const FlatRef = useRef(null);

  const { ItemTypeList } = API.constant;

  //パラメータを確認する部分
  useEffect(() => {
    (async () => {
      const ThisLabel = route.params?.type || "一覧";
      setTypeInfo(ItemTypeList.find((item) => item.label == ThisLabel));

      //一回グラフデータをリセットする
      setGraphData({});

      setIsLoading(true);
    })();
  }, [route.params?.type]);

  //ユーザー情報の取得と価格変動データの取得は一度だけ行う
  useEffect(() => {
    (async () => {
      setPageNo(0);

      //最初に一度ユーザーデータを取得する
      const _UserData = { ...API.user };
      setUserData(_UserData);

      if (!_UserData) {
        API.NavigateToNeedSignUp(navigation);
      }
    })();
  }, [TypeInfo, OrderSetting]);

  //価格変動データと所有情報が更新されたらItemsとリンクさせる　あとグラフデータ
  useEffect(() => {
    if (Items.length) {
      setItems([
        ...Items.map((item) => {
          //グラフデータ
          item = Object.assign(item, { graph_data: GraphData[item.id] || [] });

          return item;
        }),
      ]);
    }
  }, [GraphData]);

  //ユーザー情報が変更されたときとフォーカスされたときに所有データを更新してMyItemsに登録する　また、資産系についても計算しなおす　また、お気に入りも計算する
  useFocusEffect(
    useCallback(() => {
      if (UserData) {
        //上のタブのため
        getAllMyItems();
      }
    }, [UserData]),
  );

  //ユーザー情報が更新されたときやページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    (async () => {
      if (UserData) {
        if (PageNo == 0) {
          setPageNo(1);
          return false;
        }

        const NewItems = await API.getItem(
          {
            ...TypeInfo.condition,
            user: UserData.id,
          },
          { detail: 1, favorite: 1 },
          {
            cur: PageNo,
            per: 18,
          },
          {
            column: OrderSetting.column,
            mode: OrderSetting.mode,
          },
        );

        //現在のItemsにそのまま結合していいのか判断
        if (PageNo == 1 && Items.length) {
          setItems([...NewItems]);

          FlatRef.current?.scrollToIndex({
            index: 0,
            animated: true,
          });
        } else {
          //ページ番号がかわったらカードデータを再取得する
          setItems([...Items.concat(NewItems)]);
        }

        setIsLoading(false);

        const term = 30;

        if (NewItems.length) {
          const graph_data =
            (await API.getItemGraph({ id: NewItems.map((e) => e.id), term })) ||
            {};

          //余分なレンダリングを防止
          if (
            !Object.keys(graph_data).length ||
            !Object.keys(graph_data).every((e) =>
              Object.keys(GraphData).includes(e),
            )
          ) {
            setGraphData({ ...Object.assign(GraphData, graph_data) });
          }
        }
      }
    })();
  }, [UserData, PageNo]);

  //すべての所有アイテムを取得し、お気に入り情報も取得する（ここのデータ通信量をゆくゆくはおさえたい）
  const getAllMyItems = async () => {
    if (UserData) {
      const AllItems = await API.getItem(
        { user: UserData.id },
        { detail: 1, favorite: 1 },
        {},
      );

      setEachNumber([
        ...EachNumber.map((Each) => {
          const { condition } = Each;
          let ThisSum = AllItems.reduce((sum, item) => {
            for (const prop in condition) {
              if (condition[prop] != item[prop]) return sum;
            }

            return sum + parseInt(item.is_favorite);
          }, 0);

          Each.sum = ThisSum;

          return Each;
        }),
      ]);
    }
  };

  //個別の枚数計算関数を作っていく
  const [EachNumber, setEachNumber] = useState(
    JSON.parse(JSON.stringify(API.constant.ItemTypeList)),
  );

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) => (
      <View key={index} style={styles.imagebox}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate({
              name: "アイテム詳細",
              params: { item },
            })
          }
          style={styles.card_box}
        >
          <Image
            source={{
              uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
            }}
            resizeMode="contain"
            style={styles.image4}
          ></Image>
          <View style={styles.card_text_box}>
            <ItemListComponent item={item} mode="favorite" />
          </View>
          <View style={styles.rect16}>
            <Text style={styles.mycard_price}>
              ￥
              {item.price
                .toLocaleString("en-US", { maximumFractionDigits: 0 })
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Text>

            <Text
              style={
                item.pricegap === 0
                  ? styles.zero
                  : item.pricegap < 0
                    ? styles.minus
                    : styles.plus
              }
            >
              {item.pricegap !== 0 &&
                (item.pricegap < 0 ? <>&darr;</> : <>&uarr;</>)}
              ￥{Math.abs(item.pricegap).toLocaleString()}
            </Text>
          </View>
          <View style={styles.graph_div}>
            <Graph data={item.graph_data} type={route.name} API={API} />
          </View>
        </TouchableOpacity>
      </View>
    ),
    [Items, GraphData],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  return (
    <View style={styles.container}>
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <SortButton {...{ OrderSetting, setOrderSetting }} page="MyFavorite" />

      <View
        style={{ height: 35, justifyContent: "center", alignItems: "center" }}
      >
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={styles.scrollView}
        >
          {EachNumber.map((Each, i) => (
            <>
              {Each.sum > 0 && (
                <TouchableOpacity
                  key={i}
                  onPress={() =>
                    API.navigation.navigate({
                      name: "お気に入り一覧",
                      params: { type: Each.label },
                    })
                  }
                  style={
                    TypeInfo.label == Each.label
                      ? styles.cat_box_active
                      : styles.cat_box
                  }
                >
                  <Text
                    style={
                      TypeInfo.label == Each.label
                        ? styles.cat_active
                        : styles.cat
                    }
                  >
                    {Each.label}
                  </Text>
                </TouchableOpacity>
              )}
            </>
          ))}
        </ScrollView>
      </View>

      <View style={styles.image4ColumnRow}>
        {IsLoading && (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#b82a2a" />
          </View>
        )}

        {!Items.length && !IsLoading && (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text>お気に入りがまだありません。カードを登録してください。</Text>
          </View>
        )}

        {Items.length > 0 && (
          <FlatList
            numColumns={1}
            data={Items}
            ref={FlatRef}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReached={() => setPageNo(PageNo + 1)}
            onEndReachedThreshold={1}
          />
        )}
      </View>
    </View>
  );
}
