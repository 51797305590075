import { useState, useEffect, useCallback, useRef, Fragment } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  FlatList,
  Modal,
  ScrollView,
  Alert,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
} from "react-native";
import { Image } from "expo-image";

import { useFocusEffect } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { MycaAPI, MycaStyle } from "../lib/function";
import { TopMenu, BottomMenu } from "../components/Menu";
import { DetailRegisterModal } from "../components/Register";
import { B1_style as styles } from "../lib/style/B.style"; //SCSSで記述したいが、現在オブジェクト形式であるため一旦それを活用
import { MycaButton } from "../components/Common";
import { ItemListComponent, DetailModal } from "../components/Item";

import * as Clipboard from "expo-clipboard";

import { SortButton } from "../components/Sort";
import { DisplayModeButton, DraftConfirmModal } from "../components/Collection";
import { ItemSearchOptionList } from "../components/Search";

import * as ImagePicker from "expo-image-picker";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { UserNameInputModal } from "../components/Account";

export function CollectionListScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数

  const [genreList, setGenreList] = useState([]); //ジャンルリスト

  //存在しない時のやつ
  const [waitForMoment, setWaitForMoment] = useState(false);

  const [collectionCondition, setCollectionCondition] = useState({}); //コレクションの条件（ここではgenreしか使わない　何も指定していないと条件なし、つまり全てのコレクションを取得する）

  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      setWaitForMoment(false);
      setTimeout(() => setWaitForMoment(true), 2000);

      //先にからにしておく
      setItems([]);

      if (route.params?.condition) {
        setCollectionCondition({
          ...route.params.condition,
        });
      } else {
        setCollectionCondition({});
      }

      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;

      if (CurrentMenu[0].top == "一覧" && !_UserData)
        API.NavigateToNeedSignUp();

      setUserData(_UserData);

      //ジャンルデータも取得する
      //なお、持っていないジャンルは含めないようにする
      let genreList = await API.getGenres();

      if (CurrentMenu[0].top == "一覧") {
        const Collections = await API.getCollections("owned", {
          user: _UserData?.id,
        });

        genreList = genreList.filter((genre) =>
          Collections.find((collection) => collection.genre == genre.id),
        );
      }

      setGenreList([...genreList]);
    })();
  }, [route.params?.condition]);

  // ユーザー情報が変更されたときとフォーカスされたときにコレクションリストを取得しなおす
  useFocusEffect(
    useCallback(() => {
      (async () => {
        //一覧に関してはログインしていないと取得できないが、探すに関してはログインしていなくても取得できる

        const topMenu = CurrentMenu[0].top;

        const mode = topMenu == "一覧" ? "owned" : "search";

        //ここで検索ワードもパラメターにのせる
        const _UserData = API.user;

        const Collections = await API.getCollections(mode, {
          user: _UserData?.id,
          ...collectionCondition,
        });

        setItems([...Collections]);
      })();
    }, [collectionCondition]),
  );

  const uniqueStyles = StyleSheet.create({
    searchBox: {
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: "black",
      marginBottom: 15,
      padding: 5,
      overflow: "hidden",
    },
    img_wrapper: {
      overflow: "hidden",
      aspectRatio: 1,
      width: "100%",
      marginBottom: 5,
      borderRadius: 15,
    },
    thumbnail: {
      aspectRatio: 1,
      resizeMode: "cover",
      transform: [{ scale: 1.8 }, { translateY: 18 }],
    },
    item_wrapper: {
      marginRight: "4%",
      marginLeft: "4%",
      maxWidth: "25.5%",
    },
    scrollView: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexGrow: 0,
      marginBottom: 10,
      flexShrink: 0,
    },
    cat_box: {
      height: 25,
      borderColor: "#e4e4e4",
      borderWidth: 1,
      borderRadius: 20,
      marginRight: 6,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 5,
    },
    cat_box_active: {
      height: 25,
      backgroundColor: "rgba(184,42,42,1)",
      borderColor: "rgba(184,42,42,1)",
      borderWidth: 1,
      borderRadius: 20,
      borderWidth: 1,
      borderRadius: 20,
      marginRight: 6,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 5,
    },
    cat: {
      textAlign: "center",
      color: "#848484",
      fontSize: 14,
      lineHeight: 24,
    },
    cat_active: {
      textAlign: "center",
      color: "#ffffff",
      fontSize: 14,
      lineHeight: 24,
    },
    noRegisterText: {
      fontWeight: "bold",
      fontSize: 15,
    },
    collectionName: {
      fontSize: 13,
      fontWeight: "bold",
      marginBottom: 2,
    },
    complete: {
      fontSize: 11,
      textAlign: "center",
      color: MycaAPI.style.ThemeColor,
      fontWeight: "bold",
    },
    ownedRatio: {
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
    },
    version: {
      textAlign: "center",
      opacity: 0.4,
      fontSize: 11,
      marginTop: 2,
    },
    officialMarkWrapper: {
      backgroundColor: "white",
      top: 3,
      right: 3,
      height: 22,
      width: 22,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
    },
    officialMark: {
      width: 13,
      height: 13,
    },
  });

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={[styles.imagebox, uniqueStyles.item_wrapper]}>
        <View style={styles.image4Column}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate({
                name: "コレクション詳細",
                params: { item },
              })
            }
          >
            <View style={uniqueStyles.img_wrapper}>
              <Image
                source={{
                  uri: item.image_url.includes("https")
                    ? item.image_url
                    : API.getImageUrl(item.genre, "item", item.image_url, true),
                }}
                style={[styles.image4, uniqueStyles.thumbnail]}
              ></Image>
              {item.user == 1 && (
                <View style={uniqueStyles.officialMarkWrapper}>
                  <Image
                    source={require("../assets/myca_icon.png")}
                    style={uniqueStyles.officialMark}
                  ></Image>
                </View>
              )}
            </View>

            <Text style={uniqueStyles.collectionName}>{item.name}</Text>

            {item.owned_sum != 0 && item.owned_sum == item.collection_sum ? (
              <Text style={uniqueStyles.complete}>Complete!</Text>
            ) : (
              <Text style={uniqueStyles.ownedRatio}>
                {item.owned_sum}/{item.collection_sum}
              </Text>
            )}

            {/* {item.user_name &&
          <Text style={uniqueStyles.version}>{item.user_name}</Text>
          } */}
          </TouchableOpacity>
        </View>
      </View>
    ),
    [Items],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <View style={styles.image4ColumnRow}>
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={uniqueStyles.scrollView}
        >
          <TouchableOpacity
            onPress={() =>
              API.navigation.navigate({ name: "コレクション一覧", params: {} })
            }
            style={
              !collectionCondition.genre
                ? uniqueStyles.cat_box_active
                : uniqueStyles.cat_box
            }
          >
            <Text
              style={
                !collectionCondition.genre
                  ? uniqueStyles.cat_active
                  : uniqueStyles.cat
              }
            >
              一覧
            </Text>
          </TouchableOpacity>
          {genreList.map((genre, i) => (
            <TouchableOpacity
              key={i}
              onPress={() =>
                API.navigation.navigate({
                  name: "コレクション一覧",
                  params: { condition: { genre: genre.id } },
                })
              }
              style={
                collectionCondition.genre == genre.id
                  ? uniqueStyles.cat_box_active
                  : uniqueStyles.cat_box
              }
            >
              <Text
                style={
                  collectionCondition.genre == genre.id
                    ? uniqueStyles.cat_active
                    : uniqueStyles.cat
                }
              >
                {genre.display_name}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>

        {CurrentMenu[0].top == "探す" && (
          <TextInput
            placeholder="カード名またはコレクションコードで検索"
            style={uniqueStyles.searchBox}
            value={collectionCondition.name}
            onChangeText={(newValue) => {
              const _collectionCondition = { ...collectionCondition };
              _collectionCondition.name = newValue;
              setCollectionCondition({ ..._collectionCondition });
            }}
          />
        )}

        {Boolean(Items.length) && (
          <FlatList
            numColumns={3}
            data={Items}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onEndReachedThreshold={1}
            style={{ flex: 1 }}
          />
        )}

        {!Items.length && CurrentMenu[0].top == "一覧" && waitForMoment && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Text style={uniqueStyles.noRegisterText}>
              コレクションがまだ登録されていません
            </Text>
            <MycaButton
              color="red"
              onPress={() =>
                API.NavigateMenu(
                  CurrentGenre,
                  CurrentMenu,
                  "コレクション",
                  "作る",
                )
              }
            >
              コレクションを作る
            </MycaButton>
            <MycaButton
              color="red"
              onPress={() =>
                API.NavigateMenu(
                  CurrentGenre,
                  CurrentMenu,
                  "コレクション",
                  "探す",
                )
              }
            >
              コレクションを探す
            </MycaButton>
          </View>
        )}
      </View>
    </View>
  );
}

export function CollectionDetailScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  const [MyItems, setMyItems] = useState([]); //アイテムの所有情報を格納する変数
  const [Items, setItems] = useState([]); //アイテムの情報を格納する変数
  const [UserData, setUserData] = useState({}); //ユーザーデータを格納する変数

  const [DetailRegisterModalInfo, setDetailRegisterModalInfo] = useState({
    isShow: false,
    item: {},
  }); //詳細登録用のモーダル用の情報

  const [settingModalInfo, setSettingModalInfo] = useState({
    isShow: false,
  });

  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  const [displaySetting, setDisplaySetting] = useState(
    //表示方法
    "全表示",
  );

  const [thisCollection, setThisCollection] = useState({}); //このコレクションの情報

  //このスクリーンで扱っているディスプレイタイプ

  //所有数を変える関数
  const setNumber = (id, number) => {
    if (UserData) {
      let NewItems = [...Items];
      NewItems.find((item) => item.id == id).number =
        parseInt(NewItems.find((item) => item.id == id).number) + number;
      NewItems.find((item) => item.id == id).total_number =
        parseInt(NewItems.find((item) => item.id == id).total_number) + number;

      setItems([...NewItems]);

      //データベースの方も変える
      API.registerItem(
        {
          user: UserData.id,
          item: id,
        },
        {
          is_detail: 0, //簡易登録であるため
          number,
        },
      ).then((response) => {
        //念のため個数を反映させる
        NewItems.find((item) => item.id == id).total_number =
          response.total_count;
        NewItems.find((item) => item.id == id).number = response.easy_count;

        setItems([...NewItems]);
      });
    } else {
      API.NavigateToNeedSignUp();
    }
  };

  //パラメータを確認する部分
  //最初に一度ユーザーデータを取得
  useEffect(() => {
    (async () => {
      //最初に一度ユーザーデータを取得する
      const _UserData = API.user;
      setUserData(_UserData);

      //情報を取得する
      const { id: collection, user: creator } = route.params?.item;

      const response = await API.getCollectionItems({
        collection,
        user: _UserData.id,
      });

      //情報を合体させる
      setThisCollection({
        ...Object.assign(route.params?.item, {
          latestVersion: response.latest_version,
          this_version: response.this_version,
        }),
      });

      //アイテムをセットする
      setItems([...response.items]);
    })();
  }, [route.params?.item]);

  // ユーザー情報が変更されたときとフォーカスされたときに所有データ、お気に入りデータを更新してItemsに登録する
  useFocusEffect(
    useCallback(() => {
      if (!UserData) return false;

      //所有データを取得
      API.getItem(
        { user: UserData.id },
        {
          count: 1, //ジャンル別に取得するようにしたい
          item: 1,
        },
      ).then((data) => setMyItems(data));
    }, [UserData, DetailRegisterModalInfo]),
  );

  //お気に入り情報と所有情報が変更されたときにリンクする
  useEffect(() => {
    if (Items.length) {
      setItems([
        ...Items.map((item) => {
          //所有情報
          const ThisItem = MyItems.find((e) => e.id == item.id);
          item = Object.assign(item, {
            number: parseInt(ThisItem ? ThisItem.number || 0 : 0),
            total_number: parseInt(ThisItem ? ThisItem.total_number || 0 : 0),
          });

          return item;
        }),
      ]);
    }
  }, [MyItems, Items.length, Items[0]]);

  //ソート設定が変わった時に並び替える
  useEffect(() => {
    setItems([
      ...Items.sort((a, b) => b.id - a.id).sort((a, b) =>
        OrderSetting.mode == "DESC"
          ? b[OrderSetting.column] - a[OrderSetting.column]
          : a[OrderSetting.column] - b[OrderSetting.column],
      ),
    ]);
  }, [OrderSetting]);

  const ShowDetailRegisterModal = (item) => {
    setDetailRegisterModalInfo({
      isShow: true,
      item,
    });
  };

  const showSettingModal = (mode) => {
    setSettingModalInfo({
      isShow: mode,
    });
  };

  const uniqueStyles = StyleSheet.create({
    collectionMetaNameField: {
      position: "relative",
    },
    collectionSettingButton: {
      fontSize: 40,
      opacity: 0.3,
    },
    collectionSettingButtonField: {
      position: "absolute",
      right: 30,
      top: -15,
    },
    collection_meta_name: {
      marginTop: 10,
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "center",
    },
    collection_meta_version: {
      textAlign: "center",
      opacity: 0.4,
      fontSize: 12,
      marginTop: 2,
    },
    collection_meta_complete: {
      fontSize: 13,
      textAlign: "center",
      color: MycaAPI.style.ThemeColor,
      fontWeight: "bold",
    },
    collection_meta_ownedRatio: {
      fontSize: 13,
      textAlign: "center",
      fontWeight: "bold",
    },
    collection_meta_code: {
      marginTop: 8,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      opacity: 0.8,
    },
    versionConfirmField: {
      position: "absolute",
      bottom: "12%",
      backgroundColor: MycaAPI.style.ThemeColor,
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2.5%",
      zIndex: 10,
    },
    addCollectionField: {
      justifyContent: "center",
      padding: "1.5%",
    },
    versionConfirmMsg: {
      color: "white",
      fontSize: 14,
      fontWeight: "bold",
      opacity: 0.9,
    },
    versionConfirmBtn: {
      color: MycaAPI.style.ThemeColor,
      backgroundColor: "white",
      borderRadius: 10,
      overflow: "hidden",
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 10,
      paddingLeft: 10,
      fontSize: 12,
      fontWeight: "bold",
    },
    addCollectionBtn: {
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 10,
      overflow: "hidden",
    },
    itemListWrapper: {
      marginTop: 13,
    },
    item_image: {
      opacity: 1,
    },
    DarkBackground: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: "black",
      top: 0,
      left: 0,
      zIndex: 10,
      opacity: 0.5,
    },
    frame: {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
    },
    modal: {
      width: "100%",
      padding: 20,
      paddingBottom: 30,
      transition: ".3s",
      backgroundColor: "white",
      overflow: "hidden",
    },
    settingButton: {
      width: "100%",
      margin: "auto",
    },
    settingButtonText: {
      textAlign: "center",
      fontSize: 17,
      lineHeight: 40,
    },
  });

  //所有率を算出する
  const OwnedRatio = () => {
    const sum = Items.length;
    const ownedCount = Items.filter((item) => item.total_number).length;

    return (
      <>
        {ownedCount != 0 && ownedCount == sum ? (
          <Text style={uniqueStyles.collection_meta_complete}>Complete!</Text>
        ) : (
          <Text style={uniqueStyles.collection_meta_ownedRatio}>
            {ownedCount}/{sum}
          </Text>
        )}
      </>
    );
  };

  //コレクション追加ボタン
  const addCollection = async () => {
    const res = await API.registerCollections("register", {
      user: UserData.id,
      collection: thisCollection.id,
    });

    if (res) {
      Alert.alert("", "コレクションが追加できました");

      //コレクション一覧画面に遷移
      API.NavigateMenu(CurrentGenre, CurrentMenu, "コレクション", "一覧");
    } else {
      Alert.alert("", "エラーが発生しました");
    }
  };

  //FlatListのレンダリング関数を別で定義することで不要な再レンダリングを阻止
  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={styles.imagebox}>
        <View style={styles.image4Column}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate({
                name: "アイテム詳細",
                params: { item },
              })
            }
          >
            <Fragment>
              <Image
                source={{
                  uri: API.getImageUrl(
                    item.genre_id,
                    "item",
                    item.cardimage,
                    false,
                    !item.total_number,
                  ),
                }}
                resizeMode="contain"
                style={[
                  styles.image4,
                  !item.total_number && uniqueStyles.item_image,
                ]}
              ></Image>
            </Fragment>

            <ItemListComponent item={item} />

            <Text style={styles.カード名8}>
              ￥
              {item.price
                .toLocaleString("en-US", { maximumFractionDigits: 0 })
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Text>
          </TouchableOpacity>

          <View style={styles.rect16}>
            <TouchableOpacity
              onPress={() => setNumber(item.id, -1)}
              style={styles.del_box}
            >
              <Text style={styles.del_box_text}>-</Text>
            </TouchableOpacity>

            <View style={styles.pos_box}>
              <Text style={styles.pos_box_text}>
                {item.number == item.total_number
                  ? item.total_number
                  : `${item.number}(${parseInt(item.total_number) - parseInt(item.number)})`}
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => setNumber(item.id, 1)}
              style={styles.add_box}
            >
              <Text style={styles.add_box_text}>+</Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            styles={styles.DetailRegisterButtonField}
            onPress={() => ShowDetailRegisterModal(item)}
          >
            <Text style={styles.DetailRegisterButtonText}>詳細登録</Text>
          </TouchableOpacity>
        </View>
      </View>
    ),
    [Items],
  );

  const keyExtractor = useCallback((Item, i) => i, [Items]);

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <SortButton
        {...{ OrderSetting, setOrderSetting }}
        page={`${CurrentMenu[0].top == "一覧" ? "My" : ""}CollectionDetail`}
      />
      {CurrentMenu[0].top == "一覧" && (
        <DisplayModeButton
          displaySetting={displaySetting}
          setDisplaySetting={setDisplaySetting}
        />
      )}

      <DetailRegisterModal
        Info={DetailRegisterModalInfo}
        setInfo={setDetailRegisterModalInfo}
        navigation={navigation}
        API={API}
      />

      {settingModalInfo.isShow && (
        <TouchableOpacity
          style={uniqueStyles.DarkBackground}
        ></TouchableOpacity>
      )}

      <Modal
        transparent={true}
        visible={settingModalInfo.isShow}
        animationType={"fade"}
      >
        <TouchableOpacity
          style={uniqueStyles.frame}
          onPress={() => showSettingModal(false)}
        >
          <View style={uniqueStyles.modal}>
            <TouchableWithoutFeedback>
              <>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    //このコレクションのアイテム情報を取得して作成画面へ遷移
                    const { items } = await API.getCollectionItems({
                      collection: thisCollection.id,
                    });

                    const blueprint = {
                      genre: thisCollection.genre,
                      name: thisCollection.name,
                      items,
                      editMode: thisCollection.id,
                    };

                    showSettingModal(false);

                    API.navigation.navigate("コレクション作成", { blueprint });
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>編集</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    Alert.alert("", "本当に削除しますか？", [
                      {
                        text: "キャンセル",
                        onPress: () => {
                          console.log("キャンセルされました");
                        },
                        style: "cancel",
                      },
                      {
                        text: "削除",
                        onPress: async () => {
                          await API.registerCollections("delete", {
                            user: UserData.id,
                            collection: thisCollection.id,
                          });

                          Alert.alert("", "削除できました");

                          API.navigation.navigate({
                            name: "コレクション一覧",
                            params: {},
                          });
                        },
                        style: "destructive",
                      },
                    ]);
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>削除</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={uniqueStyles.settingButton}
                  onPress={async () => {
                    await Clipboard.setStringAsync(thisCollection.code);
                    Alert.alert("", "コピーできました");
                  }}
                >
                  <Text style={uniqueStyles.settingButtonText}>
                    コードをコピー
                  </Text>
                </TouchableOpacity>
                {thisCollection.user == UserData.id && (
                  <TouchableOpacity
                    style={uniqueStyles.settingButton}
                    onPress={async () => {
                      await API.editCollections(
                        {
                          user: UserData.id,
                          id: thisCollection.id,
                        },
                        {
                          public: !thisCollection.public,
                        },
                      );
                      setThisCollection((prev) => {
                        prev.public = !prev.public;
                        return { ...prev };
                      });
                    }}
                  >
                    <Text style={uniqueStyles.settingButtonText}>
                      {thisCollection.public ? "非公開にする" : "公開にする"}
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>

      {
        //このコレクションを追加するボタン
        CurrentMenu[0].top == "探す" && !thisCollection.registered ? (
          <View
            style={[
              uniqueStyles.versionConfirmField,
              uniqueStyles.addCollectionField,
            ]}
          >
            <TouchableOpacity onPress={() => addCollection()}>
              <Text
                style={[
                  uniqueStyles.versionConfirmBtn,
                  uniqueStyles.addCollectionBtn,
                ]}
              >
                このコレクションを追加
              </Text>
            </TouchableOpacity>
          </View>
        ) : null
      }

      <View style={uniqueStyles.collectionMetaNameField}>
        {thisCollection.name && (
          <Text style={uniqueStyles.collection_meta_name}>
            {thisCollection.name}
          </Text>
        )}
        {CurrentMenu[0].top == "一覧" && (
          <TouchableOpacity
            style={uniqueStyles.collectionSettingButtonField}
            onPress={() => showSettingModal(true)}
          >
            <Text style={uniqueStyles.collectionSettingButton}>…</Text>
          </TouchableOpacity>
        )}
      </View>

      {/* ここに作成者の名前を入れる */}
      {/* {thisCollection.user_name &&
      <Text style={uniqueStyles.collection_meta_version}>{thisCollection.user_name}</Text>
      } */}

      <OwnedRatio />

      <Text style={uniqueStyles.collection_meta_code}>
        コレクションコード：{thisCollection.code}
      </Text>

      <View style={[styles.image4ColumnRow, uniqueStyles.itemListWrapper]}>
        <FlatList
          numColumns={3}
          data={Items.filter((item) => {
            switch (displaySetting) {
              case "所持済み":
                if (item.total_number) {
                  return true;
                } else {
                  return false;
                }

              case "未所持":
                if (!item.total_number) {
                  return true;
                } else {
                  return false;
                }

              default:
                return true;
            }
          })}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
        {!Items.length && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text>カードがありません</Text>
          </View>
        )}
      </View>
    </View>
  );
}

export function CollectionGenreScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [GenreData, setGenreData] = useState([]);

  const [drafts, setDrafts] = useState([]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        API.getGenres().then((response) => {
          setGenreData([...response]);
        });

        //ここで下書きのものがあるかどうかを確認する
        const _UserData = API.user;

        if (!_UserData) API.NavigateToNeedSignUp();

        const existDrafts = await API.getCollections("draft", {
          user: _UserData.id,
        });

        setDrafts([...existDrafts]);
      })();
    }, []),
  );

  const navigateToEditScreen = async (blueprint = {}, useDraft) => {
    //選択したジャンルを保持して作成画面に遷移

    if (drafts.length && useDraft) {
      //下書きを載せた状態で遷移する場合
      const draft = drafts[0];

      //アイテムを取得する
      const items = await API.getCollectionItems({ collection: draft.id });

      //blueprintにセット
      blueprint = {
        genre: draft.genre,
        items: items.items,
      };
    }

    setDrafts([]);

    API.navigation.navigate("コレクション作成", { blueprint });
  };

  const navigateToListScreen = () => {
    setDrafts([]);

    setTimeout(() => {
      API.NavigateMenu(CurrentGenre, CurrentMenu, "コレクション", "一覧");
    }, 10);
  };

  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={[thisStyles.poke_link, thisStyles.active]}>
        <TouchableOpacity
          onPress={() => navigateToEditScreen({ genre: item.id })}
        >
          <Image
            source={{ uri: item.single_genre_image }}
            resizeMode="contain"
            style={thisStyles.image_poke2}
          ></Image>
          <Text style={thisStyles.genreName}>{item.display_name}</Text>
        </TouchableOpacity>
      </View>
    ),
    [GenreData],
  );

  const keyExtractor = useCallback((Item, i) => i, [GenreData]);

  const thisStyles = StyleSheet.create({
    genreName: {
      textAlign: "center",
      // fontWeight: 'bold',
    },
    textBox: {
      alignItems: "center",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: 20,
      marginTop: 30,
      marginBottom: 10,
    },
    text: {
      fontWeight: "bold",
      fontSize: 12,
    },
    flexbox: {
      width: "95%",
      height: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      marginTop: 10,
      paddingBottom: 20,
    },
    poke_link: {
      width: "18%",
      marginLeft: "1%",
      marginRight: "1%",
      marginBottom: 30,
      opacity: 0.5,
    },
    image_poke2: {
      width: "100%",
      aspectRatio: 1,
      marginLeft: "auto",
      marginRight: "auto",
      opacity: 1,
    },
    active: {
      opacity: 1,
    },
  });

  return (
    <View style={styles.container}>
      <TopMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <DraftConfirmModal
        drafts={drafts}
        setDrafts={setDrafts}
        navigateToEditScreen={navigateToEditScreen}
        navigateToListScreen={navigateToListScreen}
      />

      <View style={thisStyles.textBox}>
        <Text style={thisStyles.boldText}>コレクション作成</Text>
        <Text style={thisStyles.text}>作成するジャンルを選んでください。</Text>
      </View>

      <View style={thisStyles.flexbox}>
        <FlatList
          numColumns={5}
          data={GenreData}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          style={{ height: "100%" }}
        />
      </View>
    </View>
  );
}
export function CollectionMakeScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  const [UserData, setUserData] = useState(null);
  const [OrderSetting, setOrderSetting] = useState({
    //並び替え
    column: "id",
    mode: "DESC",
  });

  const [Items, setItems] = useState([]);
  const [collection, setCollection] = useState([]);
  const dropAreaLayout = useRef(null);
  const [scrollEnabled, setScrollEnabled] = useState(true); //ScrollViewを制御

  const [PageNo, setPageNo] = useState(1); //現在のページ数

  const [InputValue, setInputValue] = useState({}); //絞り込みの入力内容

  const FlatRef = useRef(null);

  const screenWidth = Dimensions.get("window").width; //画面サイズ
  const screenHeight = Dimensions.get("window").height;

  const [modalVisible, setModalVisible] = useState(false); //詳細モーダル表示
  const [modalData, setModalData] = useState(null);

  const [condition, setCondition] = useState({});

  //絞り込みボタンの表示/非表示
  const [searchOptionButtonShow, setSearchOptionButtonShow] = useState(false);

  //ソートボタンの表示/非表示
  const [sortButtonShow, setSortButtonShow] = useState(false);

  //アイテムの種類
  const [itemKind, setItemKind] = useState("card"); //card or box

  const prevPageNo = useRef(0);

  //並び替えと絞り込みが共存しないように

  const showSortButton = () => {
    if (!sortButtonShow && searchOptionButtonShow) {
      setSearchOptionButtonShow(false);
    }

    setSortButtonShow(!sortButtonShow);
  };

  const showSearchOptionButton = () => {
    if (!searchOptionButtonShow && sortButtonShow) {
      setSortButtonShow(false);
    }

    setSearchOptionButtonShow(!searchOptionButtonShow);
  };

  //利き手の件
  const [rightleft, setRightleft] = useState("右");

  useEffect(() => {
    //ここでデータベースと下書きを同期する処理を書く
    (async () => {
      if (condition.genre && collection.length && UserData) {
        const blueprint = {
          genre: condition.genre,
          name: route.params?.blueprint?.name, //あれば名前も入れておく
          items: collection.map((item) => item.id),
        };

        //データベースに送信
        const res = await API.createCollections(
          {
            mode: "draft",
            user: UserData.id,
          },
          {
            ...blueprint,
          },
        );
      }
    })();
  }, [collection]);

  useEffect(() => {
    (async () => {
      if (route.params?.blueprint) {
        const _condition = {
          genre: route.params?.blueprint?.genre,
        };
        const { init } = API.constant.GenreList.find(
          (e) => e.genre == route.params?.blueprint?.genre,
        );
        if (route.params?.blueprint?.genre != 6) {
          _condition.displaytype1 =
            itemKind == "card" ? `${init}カード` : `${init}ボックス`;
        }

        setCondition({
          ..._condition,
        });

        if (route.params?.blueprint?.items) {
          setCollection([...route.params?.blueprint?.items]);
        }

        //ユーザーデータも取得しておく
        const _UserData = API.user;
        setUserData(_UserData);
      }
    })();
  }, [route.params?.blueprint, itemKind]);

  useEffect(() => {
    setPageNo(0);
  }, [condition, OrderSetting]);

  //ページ番号が変わった時に新しいカードを取得する
  useEffect(() => {
    if (condition.genre) {
      (async () => {
        if (PageNo == 0) {
          setPageNo(1);
          return false;
        }

        if (condition) {
          let pageSetting = {};

          if (prevPageNo.current && PageNo == 1) {
            pageSetting = {
              cur: 1,
              per: 18 * prevPageNo.current,
            };
            setPageNo(prevPageNo.current);
          } else if (prevPageNo.current && PageNo != 1) {
            prevPageNo.current = 0;
            return false;
          } else {
            pageSetting = {
              cur: PageNo,
              per: 18,
            };
          }

          let NewItems = await API.getItem(
            condition,
            { detail: 1 },
            pageSetting,
            {
              column: OrderSetting.column,
              mode: OrderSetting.mode,
            },
          );

          if (!NewItems.length) {
            if (PageNo == 1) {
              setItems([]);
            }
            return false;
          }

          //すでにコレクションフィールドに追加されているものは除外
          NewItems = NewItems.filter(
            (item) => !collection.some((e) => e.id == item.id),
          );

          console.log("追加されたのは", NewItems.length);

          //全部コレクションに追加されていたら次のページを参照する
          if (!NewItems.length) {
            setPageNo((cur) => cur + 1);
            return false;
          }

          //現在のItemsにそのまま結合していいのか判断
          if (PageNo == 1 && Items.length) {
            setItems([...NewItems]);

            // FlatRef.current?.scrollToIndex({
            //   index:0,
            //   animated:true,
            // })
          } else {
            //ページ番号がかわったらカードデータを再取得する
            setItems([
              ...Items.concat(NewItems).filter(
                (v, i, a) => i == a.findIndex((item) => item.id == v.id),
              ),
            ]);
          }
        }
      })();
    }
  }, [PageNo]);

  const platformStyles = Platform.select({
    ios: { height: "57%" },
    android: { height: "53%" },
    default: { height: "56%" },
  });

  const collectionMakeStyles = StyleSheet.create({
    scrollView: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexGrow: 0,
      marginBottom: 10,
      justifyContent: "space-around",
    },

    //dropエリア
    box: {
      flexDirection: "row",
      width: "96%",
      marginLeft: "2%",
      flexWrap: "wrap",
      alignContent: "flex-start",
      paddingLeft: "auto",
      paddingRight: "auto",
      marginBottom: 7,
      // ...platformStyles,
      flex: 1,
      padding: 5,
      // borderColor: 'rgb(132, 132, 132)',
      // borderWidth: 1,
    },

    //検索欄
    searchBox: {
      flexDirection: "row",
      backgroundColor: "#acacac",
      alignItems: "center",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
    input_box: {
      // width:'65%',
      flex: 1,
      borderColor: "rgba(182,182,182,1)",
      backgroundColor: "rgba(255,255,255,1)",
      borderRadius: 8,
      marginRight: "auto",
      paddingLeft: 10,
      paddingTop: 6,
      paddingBottom: 6,
      marginRight: 5,
      height: 30,
    },
    searchBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 8,
      marginRight: 5,
      width: 60,
      height: 30,
    },
    rightleft: {
      width: 30,
    },
    searchText: {
      fontSize: 12,
      color: "rgb(157, 157, 157)",
      fontWeight: "bold",
    },

    //検索カード表示エリア
    scrollViewBox: {
      flexDirection: "row",
      marginLeft: "2%",
      marginBottom: 50,
      height: 150,
    },
    cardContainer: {
      // justifyContent: 'flex-start',
      // alignItems: 'flex-start',
    },

    collectionCard: {
      width: "22%",
      marginRight: "3%",
      justifyContent: "center",
      // elevation: 5,
    },
    card: {
      marginRight: 10,
      justifyContent: "center",
      paddingTop: 5,
    },
    image: {
      width: "100%",
      aspectRatio: 0.71,
    },
    text: {
      fontSize: 10,
      color: "rgb(132, 132, 132)",
    },
    rarity: {
      fontSize: 8,
      color: "rgb(132, 132, 132)",
    },

    //追加
    addBtn: {
      backgroundColor: "rgb(184, 42, 42)",
      position: "absolute",
      width: 30,
      height: 30,
      borderRadius: 40,
      top: 2,
      zIndex: 10000,
    },
    addText: {
      color: "white",
      lineHeight: 27,
      textAlign: "center",
      fontSize: 23,
      fontWeight: "bold",
    },

    //削除ボタン
    deleteBtn: {
      backgroundColor: "rgb(184, 42, 42)",
      position: "absolute",
      width: 30,
      height: 30,
      borderRadius: 40,
      top: -5,
      zIndex: 10000,
    },
    deleteText: {
      color: "white",
      lineHeight: 27,
      textAlign: "center",
      fontSize: 23,
      fontWeight: "bold",
    },

    //登録
    registerBox: {
      position: "absolute",
      width: "96%",
      marginLeft: "2%",
      bottom: "13%",
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: MycaStyle.ThemeColor,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 10,
      borderRadius: 10,
      justifyContent: "space-between",
    },
    registerText: {
      fontSize: 15,
      color: "rgb(255, 255, 255)",
    },
    registerBtn: {
      backgroundColor: "rgb(255, 255, 255)",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 25,
      paddingRight: 25,
      borderRadius: 15,
      marginRight: 8,
    },
    registerBtnText: {
      fontSize: 12,
      fontWeight: "bold",
      color: "rgb(184, 42, 42)",
    },
  });

  //カード選択フィールドの大きさ調整
  const [isExpanded, setIsExpanded] = useState(false);

  const DragAndDropCard = useCallback(
    ({ item }) => {
      // const position = useRef(new Animated.ValueXY()).current;

      // const resetPosition = () => { //ポジションを戻す
      //   Animated.spring(position, {
      //     toValue: { x: 0, y: 0 },
      //     useNativeDriver: false
      //   }).start();
      // };

      // const panResponder = useRef(
      //   PanResponder.create({
      //     onStartShouldSetPanResponder: () => {
      //       // if (Platform.OS === 'android') {　※いったんドラックを無効
      //       //   return false;
      //       // }
      //       // setScrollEnabled(false); // ScrollViewのスクロールを無効化
      //       // return true;
      //       return false;
      //     },

      //     onMoveShouldSetPanResponder: (evt, gestureState) => {
      //       // if (Platform.OS === 'android') { ※いったんドラックを無効
      //       //   return false;
      //       // }
      //       // const dragThreshold = 5; // 5px動かしたらドラッグとみなす
      //       // const isDrag = Math.abs(gestureState.dx) > dragThreshold || Math.abs(gestureState.dy) > dragThreshold;
      //       // if (isDrag) {
      //       //   setScrollEnabled(false);
      //       // }
      //       // return isDrag;
      //       return false;
      //     },

      //     onPanResponderMove: Animated.event(
      //       [
      //         null,
      //         {
      //           dx: position.x,
      //           dy: position.y,
      //         },
      //       ],
      //       { useNativeDriver: false }
      //     ),
      //     onPanResponderRelease: (e, gestureState) => {
      //       if (isDropArea(gestureState)) {
      //         const isExistingInCollection = collection.some(data => data.id === item.id);

      //         if (!isExistingInCollection) { // コレクションに追加し、Items から削除
      //           setCollection(prev => [...prev, item]);
      //           setItems(currentItems => currentItems.filter(data => data.id !== item.id));
      //         }else{
      //           resetPosition();
      //         }
      //       } else {
      //         deleteCollection(item.id);
      //       }
      //       setScrollEnabled(true);
      //     },
      //   })
      // ).current;

      // const isDropArea = (gestureState) => {
      //   const { pageY, moveY } = gestureState;
      //   const { y, height } = dropAreaLayout.current;

      //   return (pageY >= y && pageY <= y + height) || (moveY >= y && moveY <= y + height);
      // };

      return (
        // <Animated.View
        //   style={[
        //     collectionMakeStyles.card,
        //     {
        //       transform: position.getTranslateTransform(),
        //       width: screenWidth * 0.22,
        //     },
        //   ]}
        //   {...panResponder.panHandlers}
        // >
        <View
          style={[
            collectionMakeStyles.card,
            { width: Dimensions.get("window").width < 390 ? "22.3%" : "17.3%" },
          ]}
        >
          <TouchableOpacity
            onPress={() => addCollection(item.id)}
            style={[
              collectionMakeStyles.addBtn,
              rightleft == "右" ? { right: -3 } : { left: -3 },
            ]}
          >
            <Text style={collectionMakeStyles.addText}>+</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => showModal(item)}>
            <Image
              source={{
                uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
              }}
              resizeMode="contain"
              style={collectionMakeStyles.image}
            />
          </TouchableOpacity>
        </View>
      );
    },
    [Items, rightleft],
  );

  const showModal = (item) => {
    setModalData(item);
    setModalVisible(!modalVisible);
  };

  //一気にカードを追加できないようにする
  const addingNow = useRef(false);

  const deleteCollection = (id) => {
    //ここでデータベースとも同期する
    // const itemToRemove = collection.find(item => item.id === id);

    setCollection((currentCollection) =>
      currentCollection.filter((item) => item.id !== id),
    );

    //ここでリロードをかけ、prevPageNoをセットする
    setPageNo(0);
    prevPageNo.current = PageNo;
  };

  const addCollection = (id) => {
    //ここでデータベースとも同期する
    const itemToAdd = Items.find((item) => item.id === id);

    if (addingNow.current) return false;
    addingNow.current = true;

    //同じアイテムを追加しようとしてたら処理を中断する
    setItems([...Items.filter((item) => item.id != id)]);
    setCollection((currentCollection) =>
      [...currentCollection, itemToAdd].filter((v, i, a) => i == a.indexOf(v)),
    );

    setTimeout(() => (addingNow.current = false), 500);
  };

  return (
    <View style={styles.container}>
      {/* <KeyboardAvoidingView style={{height:"100%"}} behavior="padding"> */}

      {/* <TopMenu {...{navigation, CurrentGenre, CurrentMenu}} /> */}
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <DetailModal
        modalData={modalData}
        visible={modalVisible}
        setModalVisible={setModalVisible}
        API={API}
      />

      <View
        style={[collectionMakeStyles.box, { paddingBottom: 50, paddingTop: 0 }]}
        ref={dropAreaLayout}
        onLayout={(event) => {
          dropAreaLayout.current = event.nativeEvent.layout;
        }}
      >
        <ScrollView
          contentContainerStyle={[
            collectionMakeStyles.cardContainer,
            {
              flexDirection: "row",
              flexWrap: "wrap",
              paddingTop: 5,
              paddingBottom: 50,
            },
          ]}
          style={{ height: "100%" }}
        >
          {collection.map((item) => (
            <View
              style={[
                collectionMakeStyles.collectionCard,
                { justifyContent: "flex-start" },
              ]}
            >
              <TouchableOpacity
                onPress={() => deleteCollection(item.id)}
                style={[
                  collectionMakeStyles.deleteBtn,
                  rightleft == "右" ? { right: -3 } : { left: -3 },
                ]}
              >
                <Text style={collectionMakeStyles.deleteText}>-</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => showModal(item)}>
                <Image
                  source={{
                    uri: API.getImageUrl(item.genre_id, "item", item.cardimage),
                  }}
                  resizeMode="contain"
                  style={collectionMakeStyles.image}
                ></Image>
              </TouchableOpacity>
              <Text style={collectionMakeStyles.text}>
                {item.pack || item.expansion}
              </Text>
              {/* <Text style={collectionMakeStyles.text}>{item.cardname}</Text> */}
              <Text style={collectionMakeStyles.rarity}>{item.rarity}</Text>
            </View>
          ))}
        </ScrollView>
      </View>

      <KeyboardAvoidingView
        behavior="padding"
        style={{ width: "100%", bottom: 70 }}
      >
        <View style={collectionMakeStyles.searchBox}>
          <TextInput
            placeholder={itemKind == "card" ? `カード名` : `ボックス名`}
            onChangeText={(newValue) => {
              const newInputValue = { ...InputValue };

              const label = itemKind == "card" ? `カード名` : `ボックス名`;

              newInputValue[label] = {
                value: newValue,
                prop: "name",
              };

              setInputValue({
                ...newInputValue,
              });
            }}
            style={collectionMakeStyles.input_box}
            value={
              InputValue[itemKind == "card" ? `カード名` : `ボックス名`]?.value
            }
          ></TextInput>
          <SortButton
            page="Collection"
            OrderSetting={OrderSetting}
            setOrderSetting={setOrderSetting}
            isShow={sortButtonShow}
            switchIsShow={showSortButton}
          />
          <TouchableOpacity
            style={collectionMakeStyles.searchBtn}
            onPress={() => {
              if (condition.genre != 6) {
                showSearchOptionButton();
              } else {
                Alert.alert("", "このジャンルでは絞り込み機能は使えません");
              }
            }}
          >
            <Text style={collectionMakeStyles.searchText}>
              {searchOptionButtonShow ? "閉じる" : "絞り込み"}
            </Text>
          </TouchableOpacity>
          <ItemSearchOptionList
            API={API}
            itemKind={itemKind}
            setItemKind={setItemKind}
            InputValue={InputValue}
            setInputValue={setInputValue}
            condition={condition}
            setCondition={setCondition}
            isShow={searchOptionButtonShow}
            setIsShow={setSearchOptionButtonShow}
          />

          <TouchableOpacity
            style={[
              collectionMakeStyles.searchBtn,
              collectionMakeStyles.rightleft,
            ]}
            onPress={() => setRightleft(rightleft == "右" ? "左" : "右")}
          >
            <MaterialCommunityIcons
              style={styles.searchText}
              size={20}
              name="arrow-left-right-bold"
              color="rgb(157, 157, 157)"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: 50,
              height: 25,
              backgroundColor: "#acacac",
              position: "absolute",
              right: 0,
              top: -25,
              zIndex: 10,
              overflow: "hidden",
              borderRadius: 10,
              borderBottomLeftRadius: isExpanded ? 10 : 0,
              borderBottomRightRadius: isExpanded ? 10 : 0,
              borderTopLeftRadius: isExpanded ? 0 : 10,
              borderTopRightRadius: isExpanded ? 0 : 10,
              transform: [
                {
                  rotate: isExpanded ? "180deg" : "0deg",
                },
              ],
            }}
            onPress={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <Image
              resizeMode="contain"
              style={{ width: "100%", height: "80%" }}
              source={require("../assets/grayArrow2.png")}
            ></Image>
          </TouchableOpacity>
        </View>

        <View
          style={[
            collectionMakeStyles.scrollViewBox,
            {
              marginBottom: 60,
              backgroundColor: "white",
              height: isExpanded ? Dimensions.get("window").height - 390 : 120,
            },
          ]}
        >
          <FlatList
            contentContainerStyle={collectionMakeStyles.cardContainer}
            keyboardShouldPersistTaps="handled"
            horizontal={false}
            showsHorizontalScrollIndicator={false}
            numColumns={Dimensions.get("window").width < 390 ? 4 : 5}
            // numColumns={5}
            data={Items}
            ref={FlatRef}
            onEndReached={() => {
              setPageNo(PageNo + 1);
            }}
            onEndReachedThreshold={1}
            renderItem={({ item }) => (
              <DragAndDropCard key={item.id} item={item} />
            )}
          />
        </View>
      </KeyboardAvoidingView>

      <View style={collectionMakeStyles.registerBox}>
        <Text style={collectionMakeStyles.registerText}>
          {String(collection.length).padStart(3, "0")}枚
        </Text>
        <TouchableOpacity
          style={collectionMakeStyles.registerBtn}
          onPress={() => {
            //設定画面へ遷移する
            //blueprintを作っていく

            if (collection.length < 2) {
              Alert.alert("", "最低でも2枚のカードが必要です");
            } else {
              const blueprint = {
                genre: route.params?.blueprint?.genre,
                name: route.params?.blueprint?.name,
                editMode: route.params?.blueprint?.editMode,
                items: collection.map((item) => item.id),
              };

              API.navigation.navigate("コレクション設定", { blueprint });
            }
          }}
        >
          <Text style={collectionMakeStyles.registerBtnText}>登録</Text>
        </TouchableOpacity>
      </View>

      {/* </KeyboardAvoidingView> */}
    </View>
  );
}

//コレクションの詳細設定画面（画像など）
export function CollectionSettingScreen({
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
  API,
}) {
  const [blueprint, setBlueprint] = useState({});
  const [UserData, setUserData] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [userInfo, setUserInfo] = useState({});

  //ユーザー名を登録するモーダルの表示/非表示
  const [userNameModalInfo, setUserNameModalInfo] = useState({
    isShow: false,
    ended: false,
  });

  const getUserInfo = async (_UserData) => {
    const _userInfo = await API.getUserInfo();
    setUserInfo(_userInfo);
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setBlueprint({
          ...route.params?.blueprint,
          public: 0,
        });

        const _UserData = API.user;

        if (!_UserData) {
          API.NavigateToNeedSignUp();
        } else {
          setUserData(_UserData);
          await getUserInfo(_UserData);
        }
      })();
    }, [route.params?.blueprint]),
  );

  const uploadImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setImageUrl("loading");

      const { fileUrl } = await API.uploadImageForCollection(
        result,
        UserData.id,
      );

      if (fileUrl) {
        setImageUrl(fileUrl);
      }
    }
  };

  const createCollection = async () => {
    //データを整理する
    if (!blueprint.name) {
      Alert.alert("", "コレクション名を入力してください");
    } else {
      //ユーザー名を登録していなかったら登録させる（公開モードの時）
      if (!userInfo.display_name && blueprint.public) {
        setUserNameModalInfo({
          isShow: true,
          ended: false,
        });

        return false;
      }

      const code = await API.createCollections(
        {
          user: UserData.id,
          mode: "final",
        },
        {
          ...blueprint,
        },
      );

      API.navigation.reset({
        index: 0,
        routes: [
          {
            name: "コレクション作成完了",
            params: {
              code,
            },
          },
        ],
      });
    }
  };

  const uniqueStyles = StyleSheet.create({
    wrapper: {
      alignItems: "center",
    },
    h4: {
      fontSize: 17,
      fontWeight: "bold",
      marginTop: 25,
      marginBottom: 5,
    },
    nameInputField: {
      height: 35,
      padding: 5,
      width: "70%",
      backgroundColor: "white",
      borderColor: "black",
      borderWidth: 0.2,
      borderRadius: 5,
      overflow: "hidden",
      marginBottom: 10,
    },
    imageWrapper: {
      width: 200,
      height: 200,
      borderColor: "black",
      borderWidth: 0.5,
      justifyContent: "center",
    },
    image: {
      width: "100%",
      height: "100%",
    },

    publicRadioContainer: {
      gap: 30,
      flexDirection: "row",
      marginTop: 8,
      marginBottom: 20,
    },
  });

  return (
    <View style={styles.container}>
      {/* <TopMenu {...{navigation, CurrentGenre, CurrentMenu}} /> */}
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <UserNameInputModal
        getUserInfo={getUserInfo}
        Info={userNameModalInfo}
        setInfo={setUserNameModalInfo}
        API={API}
      />

      <View style={uniqueStyles.wrapper}>
        <Text style={uniqueStyles.h4}>コレクション名</Text>
        <TextInput
          placeholder="コレクション名を入力してください。"
          placeholderTextColor="rgb(132, 132, 132)"
          value={blueprint.name}
          style={uniqueStyles.nameInputField}
          onChangeText={(newValue) => {
            const _blueprint = { ...blueprint };
            _blueprint.name = newValue;

            setBlueprint({ ..._blueprint });
          }}
        />

        {/* 荒らし対策のため、一旦画像アップロードは利用しない */}
        {/* <Text style={uniqueStyles.h4}>コレクション画像</Text>
        <TouchableOpacity
          onPress={() => uploadImage()}
          style={uniqueStyles.imageWrapper}
        >
          {imageUrl == 'loading' ?
          <ActivityIndicator size="large" color="#b82a2a" /> :
          <Image
            source={imageUrl ? { uri:imageUrl } : require('../assets/noimage2.jpg')}
            style={uniqueStyles.image}
          />
          }
        </TouchableOpacity>

        <Text style={{ textAlign:'center',marginTop:8 }}>※画像をアップしない場合は{"\n"}自動的にコレクションのカードの画像になります。</Text> */}
        <Text style={[uniqueStyles.h4, { textAlign: "center" }]}>
          作成したコレクションを一般公開するか{"\n"}お選びください。
        </Text>

        <RadioButtonGroup
          containerStyle={uniqueStyles.publicRadioContainer}
          radioBackground={MycaAPI.style.ThemeColor}
          selected={blueprint.public}
          size={20}
          onSelected={(newValue) => {
            const _blueprint = { ...blueprint };
            _blueprint.public = newValue;

            setBlueprint({ ..._blueprint });
          }}
        >
          <RadioButtonItem value={1} label="公開" />
          <RadioButtonItem value={0} label="非公開" />
        </RadioButtonGroup>

        <MycaButton color="red" onPress={() => createCollection()}>
          {" "}
          コレクションを登録{" "}
        </MycaButton>
        <MycaButton color="gray" onPress={() => API.navigation.goBack()}>
          コレクション編集に戻る
        </MycaButton>
      </View>
    </View>
  );
}

//コレクション作成完了画面
export function CollectionFinishScreen({
  API,
  navigation,
  route,
  CurrentGenre,
  CurrentMenu,
}) {
  useFocusEffect(
    useCallback(() => {
      (async () => {})();
    }, []),
  );

  const uniqueStyles = StyleSheet.create({
    wrapper: {
      alignItems: "center",
    },
    h4: {
      fontSize: 17,
      fontWeight: "bold",
      marginTop: 50,
      marginBottom: 30,
      textAlign: "center",
    },
    nameInputField: {
      height: 35,
      padding: 5,
      width: "70%",
      backgroundColor: "white",
      borderColor: "black",
      borderWidth: 0.2,
      borderRadius: 5,
      overflow: "hidden",
      marginBottom: 10,
    },
    imageWrapper: {
      width: 200,
      height: 200,
      borderColor: "black",
      borderWidth: 0.5,
      justifyContent: "center",
    },
    image: {
      width: "100%",
      height: "100%",
    },

    publicRadioContainer: {
      gap: 30,
      flexDirection: "row",
      marginTop: 8,
      marginBottom: 20,
    },

    codeField: {
      backgroundColor: "white",
      padding: 8,
      borderWidth: 0.5,
      borderColor: "black",
      width: 250,
      textAlign: "center",
      marginTop: 5,
      marginBottom: 20,
    },
  });

  return (
    <View style={styles.container}>
      {/* <TopMenu {...{navigation, CurrentGenre, CurrentMenu}} /> */}
      <BottomMenu {...{ navigation, CurrentGenre, CurrentMenu, API }} />

      <View style={uniqueStyles.wrapper}>
        <Text style={[uniqueStyles.h4]}>
          コレクションの登録が{"\n"}完了しました！
        </Text>

        <Text style={{ fontSize: 15 }}>コレクションコード</Text>
        <Text style={uniqueStyles.codeField}>{route.params?.code}</Text>

        <MycaButton
          color="red"
          onPress={async () => {
            await Clipboard.setStringAsync(route.params?.code);
            Alert.alert("", "コピーできました");
          }}
        >
          コードをコピーする
        </MycaButton>
        <MycaButton
          color="gray"
          onPress={() =>
            API.NavigateMenu(CurrentGenre, CurrentMenu, "コレクション", "一覧")
          }
        >
          コレクション一覧に戻る
        </MycaButton>
      </View>
    </View>
  );
}
